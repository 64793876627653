/* eslint-disable array-callback-return */
import "./CreateAppointment.css";
import "rc-time-picker/assets/index.css";

import React, { useEffect, useRef, useState } from "react";
import {
  createFullAppointment,
  createFullVideoConference,
} from "Config/API/action";
import {
  fileExplorerSearch,
  getSpecificFolder,
  listOfAppointOrg,
  listOfAppointUsers,
  listOfServiceTicketUsers,
} from "Config/API/get";

import { Button } from "@mui/material";
import { ChangeDateToIosString } from "Constants/Functions";
import DeleteIcon from "@mui/icons-material/Delete";
import ExternalIcon from "Media/icons/External_icon.svg";
import { IconButton } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PageviewIcon from "@mui/icons-material/Pageview";
import PersonIcon from "@mui/icons-material/Person";
import TimePicker from "rc-time-picker";
import addCircle from "Media/icons/icon-material-add-circle.svg";
import awesomeList from "Media/icons/icon-awesome-list.svg";
import blank from "Media/blank-profile-picture.png";
import clockIcon from "Media/icons/icon-awesome-clock.svg";
import fileIcon from "Media/icons/icon-open-file.svg";
import folderIcon from "Media/icons/Folder-Close.png";
import { isMobile } from "react-device-detect";
import moment from "moment-timezone";
import normalAppointment from "Media/icons/normalAppointment.png";
import plusIcon from "Media/icons/icon-feather-plus.svg";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useSelector } from "react-redux";
import userIcon from "Media/icons/icon-awesome-user-friends.svg";
import videoAppointment from "Media/icons/videoAppointment.png";

const CreateAppointment = ({
  tasklist_arr,
  org_arr,
  org_name_arr,
  emp_arr,
  ticketId,
  details,
  videoChatDetails,
  setShowVideoChatRatePopup,
}) => {
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  let org_id = useSelector((state) => state.renderGeneralInfo?.id);
  let id = useSelector((state) => state.authenticationReducer?.id);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );

  // testing ref for outside click      **IMPORTANT**
  const wrapperRef = useRef(null);
  const userRef = useRef(null);
  const folderRef = useRef(null);

  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(userRef);
  useOutsideAlerter(folderRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [selectedTab, setSelectedTab] = useState("videoConference");
  // organization listing
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [orgList, setOrgList] = useState("");

  // user listing
  const [userList, setUserList] = useState([]);

  // task list
  const [listItem, setListItem] = useState(["text"]);

  // folder list
  const [folder, setFolder] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [folderUnique, setFolderUnique] = useState("");
  const [folderBookmark, setFolderBookmark] = useState("");

  // form items
  const [title, setTitle] = useState(ticketId ? ticketId : "");
  const [desc, setDesc] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [address, setAddress] = useState("");
  const [isBillable, setIsBillable] = useState(false);
  const [billableConnId, setBillableConnId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [requestorName, setRequestorName] = useState(details ? details : "");
  let appoi_status = "";

  // filler items
  const [organization, setOrganization] = useState("");
  const [users, setUsers] = useState("");

  let doneTypingInterval = 1500;
  let typingTimer;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!startTime) return toast("Start Time in required", { type: "info" });
    if (!endTime) return toast("End Time in required", { type: "info" });
    const startDateTime = ChangeDateToIosString(startDate, startTime);
    const endDateTime = ChangeDateToIosString(startDate, endTime);
    const timeDifference = moment(endDateTime).diff(startDateTime, "seconds");
    if (timeDifference <= 0) return toast("Invalid End Time", { type: "info" });
    if (
      selectedTab === "videoConference" &&
      videoChatDetails[0]?.rate === "" &&
      isBillable
    ) {
      if (loggedInUserDetails?.credit_account_manager === "manager")
        return setShowVideoChatRatePopup(true);
      return toast(
        "Video calling rates not available to make it a billable call. You don't have permission to set rates",
        { type: "info" }
      );
    }
    // if (startDate > endDate) {
    //   // if dates are wrong
    //   return toast("End Date is Wrong", { type: "info" });
    setLoader();
    // pushing our id here
    id = id.toString();
    if (!emp_arr?.includes(id)) emp_arr.push(id);
    let appointment_status;
    if (selectedTab === "appointment")
      appointment_status = createFullAppointment(
        token,
        ticketId ? org_arr : selectedOrg,
        title,
        desc,
        startDateTime,
        endDateTime,
        address,
        appoi_status,
        tasklist_arr,
        emp_arr,
        folderName,
        folderUnique,
        folderBookmark,
        ticketId ? ticketId : ""
      );
    else if (selectedTab === "videoConference")
      appointment_status = createFullVideoConference(
        token,
        org_arr,
        title,
        desc,
        startDateTime,
        endDateTime,
        address,
        appoi_status,
        tasklist_arr,
        emp_arr,
        folderName,
        folderUnique,
        folderBookmark,
        ticketId ? ticketId : "",
        isBillable ? "true" : "false",
        billableConnId,
        videoChatDetails[0]?.rate
      );
    appointment_status
      .then((res) => {
        resetLoader();
        if (res?.status) {
          toast(res?.message, {
            type: "success",
          });
          window.location.reload();
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  const handleUser = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      emp_arr.push(name);
    } else {
      const index = emp_arr.indexOf(name);
      if (index > -1) {
        // only splice array when item is found
        emp_arr.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setUsers(`${emp_arr.length} users selected`);
  };

  const handleVideoConferenceClick = () => {
    if (selectedTab === "videoConference") return;
    setSelectedTab("videoConference");
    setSelectedOrg("");
    setOrganization("");
    setAddress("");
    setFolder([]);
    setUsers("");
    setUserList([]);
    setFolderName("");
    setFolderUnique("");
    setFolderBookmark("");
    emp_arr.splice(0, emp_arr?.length);
  };

  const handleNormalAppointmentClick = () => {
    if (selectedTab === "appointment") return;
    setSelectedTab("appointment");
    setOrganization("");
    setFolder([]);
    setUsers("");
    setUserList([]);
    setIsBillable(false);
    setBillableConnId("");
    if (!ticketId) org_arr.splice(0, org_arr?.length);
    if (!ticketId) org_name_arr.splice(0, org_name_arr?.length);
    emp_arr.splice(0, emp_arr?.length);
    setFolderName("");
    setFolderUnique("");
    setFolderBookmark("");
  };

  return (
    <>
      <div
        className="create-appointment-container"
        style={{ paddingTop: ticketId && "12px" }}
      >
        <div
          className="create-appointment-upper"
          style={{ flexDirection: isMobile && "row" }}
        >
          <div className="create-appointment-head">Appointment</div>
          <div className="create-appointment-head-button">
            {isMobile ? (
              <>
                <button
                  onClick={() => handleVideoConferenceClick()}
                  style={{
                    height: "33px",
                    width: "33px",
                    borderRadius: "5px",
                    background:
                      selectedTab === "videoConference"
                        ? "rgb(92, 161, 39)"
                        : "#446FA2",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "unset",
                  }}
                >
                  <img
                    src={videoAppointment}
                    alt="video"
                    style={{ height: "31px", width: "32px" }}
                  />
                </button>
                <button
                  onClick={() => handleNormalAppointmentClick()}
                  style={{
                    height: "33px",
                    width: "33px",
                    borderRadius: "5px",
                    background:
                      selectedTab === "appointment"
                        ? "rgb(92, 161, 39)"
                        : "#446FA2",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "unset",
                  }}
                >
                  <img
                    src={normalAppointment}
                    alt="appointment"
                    style={{ height: "21px", width: "21px" }}
                  />
                </button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    background:
                      selectedTab === "videoConference"
                        ? "rgb(92, 161, 39)"
                        : "#446FA2",
                  }}
                  variant="contained"
                  onClick={() => handleVideoConferenceClick()}
                >
                  VIDEO CONFERENCE
                </Button>
                <Button
                  style={{
                    background:
                      selectedTab === "appointment"
                        ? "rgb(92, 161, 39)"
                        : "#446FA2",
                  }}
                  variant="contained"
                  onClick={() => handleNormalAppointmentClick()}
                >
                  APPOINTMENT
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="create-appointment-lower">
          <form
            className="appointment-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="appointment-form-details">
              <div className="icon">
                <img src={plusIcon} alt="plus" />
              </div>
              <div className="data">
                {ticketId ? (
                  <input
                    required
                    type="text"
                    name="title"
                 maxLength= {30}
                    value={"#" + ticketId}
                    placeholder="Add Title eg. Call with Bloom Softech"
                    readOnly
                  />
                ) : (
                  <input
                    required
                    type="text"
                    name="title"
                    maxLength= {30}
                    placeholder="Add Title eg. Call with Bloom Softech"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                )}

                <textarea
                  cols="30"
                  rows="5"
                  name="description"
         
                  placeholder="More Details..."
                  onChange={(e) => setDesc(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="appointment-form-date">
              <div className="icon">
                <img src={clockIcon} alt="plus" />
              </div>
              <div className="data">
                <input
                  required
                  type="date"

                  min={moment().startOf('year').format('YYYY-MM-DD')}          //added for year validation
                  max={moment().add(100, 'years').endOf('year').format('YYYY-MM-DD')}         // added for year validation
                  onChange={(e) =>
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }


                />
        <div className="rc-time-picker-input-custom-container">
  <TimePicker
    use12Hours
    showSecond={false}
    minuteStep={15}
    placeholder="Start Time"
    className="rc-time-picker-input-first"
    onChange={(e) => {
      if (e) {
        setStartTime(e.format("hh:mm A"));
        setEndTime(null); // Reset end time when start time changes
      } else {
        setStartTime(null);
      }
    }}
    allowClear={false}
    value={startTime ? moment(startTime, "hh:mm A") : null}
  />
  -

  <TimePicker
    use12Hours
    showSecond={false}
    minuteStep={15}
    placeholder="End Time"
    className="rc-time-picker-input-last"
    onChange={(e) => {
      if (e) {
        const time = e.format("hh:mm A");

        if (
          startTime &&
          moment(time, "hh:mm A").isSameOrBefore(moment(startTime, "hh:mm A"))
        ) {
          console.error("End time must be greater than start time.");
        } else {
          setEndTime(time);
        }
      } else {
        setEndTime(null);
      }
    }}
    allowClear={false}
    value={endTime ? moment(endTime, "hh:mm A") : null} // ✅ Use endTime, not startTime
    disabled={!startTime}
  />
</div>

                
              </div>
            </div>

            {ticketId ? (
              <div className="appointment-form-organization">
                <div className="icon">
                  <PersonIcon />
                </div>
                <div className="data" ref={wrapperRef}>
                  <textarea
                    cols="30"
                    rows="5"
                    name="description"
                  
                    value={requestorName}
                    placeholder="Requestor Details"
                    readOnly
                  ></textarea>
                </div>
              </div>
            ) : (
              <div className="appointment-form-organization">
                <div className="icon">
                  <PageviewIcon />
                </div>
                <div className="data" ref={wrapperRef}>
                  <input
                    required
                    type="text"
                    maxLength= {20}
                    value={organization}
                    placeholder="Search Organization..."
                    // this work for intital clicks
                    onClick={() => {
                      const organization_list = listOfAppointOrg(token, "");
                      organization_list.then((res) => {
                        if (res?.status) {
                          setOrgList(res?.organizationData);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    // this work when user types
                    onChange={(e) => {
                      setOrganization(e.target.value);
                      const organization_list = listOfAppointOrg(
                        token,
                        e.target.value
                      );
                      organization_list.then((res) => {
                        if (res?.status) {
                          setOrgList(res?.organizationData);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                  />

                  <div className="organization-list">
                    {/*****************search result*********************/}
                    {orgList?.length?.toString() !== "0" ? (
                      !orgList?.message ? (
                        orgList?.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <div>
                                
                                    <img
                                  src={ curr?.logo  || blank}
                                  alt="Logo"
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop
                                    e.target.src = blank; // Fallback to demo image
                                  }}
                                />
                                <span>
                                  {curr?.organization_name
                                    ? curr?.organization_name
                                    : "No Name"}
                                </span>
                              </div>
                              {selectedTab === "videoConference" &&
                                org_arr?.includes(curr?.id) ? (
                                <Button
                                  type="button"
                                  variant="contained"
                                  style={{ background: "rgb(92, 161, 39)" }}
                                  onClick={() => {
                                    org_arr?.splice(
                                      org_arr?.indexOf(curr?.id),
                                      1
                                    );
                                    const selectedOrgIds = org_name_arr?.map(
                                      (item) => item?.id
                                    );
                                    org_name_arr?.splice(
                                      selectedOrgIds?.indexOf(curr?.id),
                                      1
                                    );
                                    setIsBillable(false);
                                    setBillableConnId("");
                                    setUsers("");
                                    setUserList([]);
                                    emp_arr.splice(0, emp_arr?.length);
                                    setFolder([]);
                                    setFolderName("");
                                    setFolderUnique("");
                                    setFolderBookmark("");
                                    if (org_arr?.length === 0)
                                      return setOrganization("");
                                    setOrganization(
                                      `${org_arr?.length} Org Selected`
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    if (selectedTab === "videoConference") {
                                      org_arr?.push(curr?.id);
                                      org_name_arr?.push({
                                        id: curr?.id,
                                        orgName: curr?.organization_name,
                                      });
                                      setOrganization(
                                        `${org_arr?.length} Org Selected`
                                      );
                                    } else {
                                      setSelectedOrg([curr?.id]);
                                      setOrgList("");
                                      setOrganization(curr?.organization_name);
                                      setUsers("");
                                      setUserList([]);
                                      emp_arr.splice(0, emp_arr?.length);
                                      setFolder([]);
                                      setFolderName("");
                                      setFolderUnique("");
                                      setFolderBookmark("");
                                    }
                                  }}
                                >
                                  Select
                                </Button>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div className="orgListAlert">
                          No Such Connected Organizaton Found
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            )}

            {ticketId ? (
              <div className="appointment-form-user">
                <div className="icon">
                  <img src={userIcon} alt="User" />
                </div>
                <div className="data" ref={userRef}>
                  <input
                    type="text"
                    value={users}
                    maxLength= {30}
                    required
                    placeholder="Select users..."
                    onClick={() => {
                      let user_list;
                      if (selectedTab === "videoConference")
                        user_list = listOfAppointUsers(token, org_arr, "");
                      else
                        user_list = listOfServiceTicketUsers(token, org_id, "");
                      user_list.then((res) => {
                        if (res?.status) {
                          setUserList(res?.data);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setUsers(e.target.value);
                      let user_list;
                      if (selectedTab === "videoConference")
                        user_list = listOfAppointUsers(
                          token,
                          org_arr,
                          e.target.value
                        );
                      else
                        user_list = listOfServiceTicketUsers(
                          token,
                          org_id,
                          e.target.value
                        );
                      user_list.then((res) => {
                        if (res?.status) {
                          setUserList(res?.data);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 8) {
                        emp_arr = [];
                      }
                    }}
                  />

                  {/****************  User List ***************/}
                  <div className="organization-list">
                    {userList.length?.toString() !== "0" ? (
                      !userList.message ? (
                        userList?.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <label htmlFor={curr?.id} onChange={handleUser}>
                                <input
                                  type="checkbox"
                                  name={curr?.id}
                                  id={curr?.id}
                                  value={curr?.id}
                                  checked={emp_arr?.includes(
                                    curr?.id.toString()
                                  )}
                                />
                                <div>
                                  
                                     <img
                                  src={ curr?.image || blank}
                                  alt="Logo"
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop
                                    e.target.src = blank; // Fallback to demo image
                                  }}
                                />
                                  <div>
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <span style={{ fontWeight: "normal" }}>
                                      Org: {curr?.organization_name}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          );
                        })
                      ) : (
                        <div className="orgListAlert">No Such User Found</div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className="appointment-form-user">
                <div className="icon">
                  <img src={userIcon} alt="User" />
                </div>
                <div className="data" ref={userRef}>
                  <input
                    required
                    type="text"
                    maxLength= {30}
                    value={users}
                    placeholder="Select Users..."
                    onClick={() => {
                      if (
                        (selectedOrg?.toString() === "" &&
                          selectedTab === "appointment") ||
                        (org_arr?.length === 0 &&
                          selectedTab === "videoConference")
                      ) {
                        toast("Select an Organization First!", {
                          type: "info",
                        });
                      } else {
                        const user_list = listOfAppointUsers(
                          token,
                          selectedTab === "videoConference"
                            ? org_arr
                            : selectedOrg,
                          ""
                        );
                        user_list.then((res) => {
                          if (res?.status) {
                            setUserList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    onChange={(e) => {
                      setUsers(e.target.value);
                      if (
                        (selectedOrg?.toString() === "" &&
                          selectedTab === "appointment") ||
                        (org_arr?.length === 0 &&
                          selectedTab === "videoConference")
                      ) {
                        toast("Select an Organization First!", {
                          type: "info",
                        });
                      } else {
                        const user_list = listOfAppointUsers(
                          token,
                          selectedOrg,
                          e.target.value
                        );
                        user_list.then((res) => {
                          if (res?.status) {
                            setUserList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 8) {
                        emp_arr = [];
                      }
                    }}
                  />

                  {/****************  User List ***************/}
                  <div className="organization-list">
                    {userList.length?.toString() !== "0" ? (
                      !userList.message ? (
                        userList?.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <label htmlFor={curr?.id} onChange={handleUser}>
                                <input
                                  type="checkbox"
                                  name={curr?.id}
                                  id={curr?.id}
                                  value={curr?.id}
                                  checked={emp_arr?.includes(
                                    curr?.id.toString()
                                  )}
                                />
                                <div>
                                  
                                   <img
                                  src={ curr?.image || blank}
                                  alt="Logo"
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop
                                    e.target.src = blank; // Fallback to demo image
                                  }}
                                />
                                  <div>
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <span style={{ fontWeight: "normal" }}>
                                      Org: {curr?.organization_name}
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          );
                        })
                      ) : (
                        <div className="orgListAlert">No Such User Found</div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "videoConference" && (
              <div
                className="appointment-form-location"
                style={{ alignItems: isBillable ? "flex-start" : "center" }}
              >
                <div className="icon" style={{ padding: "0px 15px" }}>
                  <MyLocationIcon />
                </div>
                <div
                  className="data"
                  style={{
                    display: "flex",
                    gap: "6px",
                    marginTop: isBillable && "5px",
                  }}
                >
                  <input
                    style={{ height: "15px", width: "15px" }}
                    type="checkbox"
                    checked={isBillable}
                    onChange={(e) => {
                      if (org_arr?.length === 0)
                        return toast("Select an Organization First!", {
                          type: "info",
                        });
                      if (!e.target.checked) setBillableConnId("");
                      setIsBillable(e.target.checked);
                    }}
                  />
                  <div>
                    <span style={{ fontSize: "13px", color: "#51595e" }}>
                      Check for Billable
                    </span>
                    {isBillable &&
                      org_name_arr?.map((item) => (
                        <div
                          className="data"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                            marginTop: "7px",
                          }}
                        >
                          <input
                            style={{ height: "15px", width: "15px" }}
                            type="checkbox"
                            checked={billableConnId === item?.id}
                            onChange={() => setBillableConnId(item?.id)}
                          />
                          <span style={{ fontSize: "13px", color: "#51595e" }}>
                            {item?.orgName}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "appointment" && (
              <div className="appointment-form-location">
                <div className="icon">
                  <MyLocationIcon />
                </div>
                <div className="data">
                  <input
                    required
                    type="text"
                    maxLength= {50}
                    placeholder="Add address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            {ticketId ? (
              <div className="appointment-form-upload stcreate-fields">
                <div className="icon">
                  <img src={fileIcon} alt="File" />
                </div>
                <div className="data" ref={folderRef}>
                  <input
                    type="text"
                    placeholder="Attach folder name"
                    maxLength= {30}
                    value={folderName}
                    onClick={(e) => {
                      const folder_status = fileExplorerSearch(token, "", "");
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolder(res?.result);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      clearTimeout(typingTimer);
                      setFolderName(e.target.value);
                      typingTimer = setTimeout(() => {
                        const folder_status = fileExplorerSearch(
                          token,
                          e.target.value,
                          ""
                        );
                        folder_status.then((res) => {
                          if (res?.status) {
                            setFolder(res?.result);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }, [doneTypingInterval]);
                    }}
                  />
                  {/****************  Folder List ***************/}
                  <div className="organization-list folder-list">
                    {folder?.length?.toString() !== "0" ? (
                      !folder?.message ? (
                        folder?.map((curr, index) => {
                          if (curr?.type?.toString() === "folder") {
                            return (
                              <div
                                className="data-list"
                                onClick={() => {
                                  setFolderName(curr?.name);
                                  setFolderUnique(curr?.unique_id);
                                  setFolderBookmark(curr?.bookmark);
                                  setFolder("");
                                }}
                                key={index}
                              >
                                <div>
                                  <div className="organization-img-parent">
                                    <img src={folderIcon} alt="Folder" />
                                    {curr?.scope === "external" ? (
                                      <img
                                        src={ExternalIcon}
                                        alt="External"
                                        style={{
                                          position: "absolute",
                                          right: "0",
                                          bottom: "-10px",
                                          width: "17.5px",
                                        }}
                                      />
                                    ) : null}
                                  </div>

                                  <span className="multiple-line-text">
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <i>
                                      ( {curr?.bookmark ? curr?.bookmark : null}{" "}
                                      )
                                    </i>
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="orgListAlert">No Such Folder Found</div>
                      )
                    ) : null}
                  </div>

                  {/* <label htmlFor="select-file">Select File/Folder</label>
                <input type="file" id="select-file" hidden /> */}
                </div>
              </div>
            ) : (
              <div className="appointment-form-upload">
                <div className="icon">
                  <img src={fileIcon} alt="File" />
                </div>
                <div className="data" ref={folderRef}>
                  <input
                    type="text"
                    maxLength= {30}
                    value={folderName}
                    placeholder="Attach folder name"
                    onClick={(e) => {
                      if (
                        (selectedOrg?.toString() === "" &&
                          selectedTab === "appointment") ||
                        (org_arr?.length === 0 &&
                          selectedTab === "videoConference")
                      ) {
                        toast("Select an Organization First!", {
                          type: "info",
                        });
                      } else {
                        const folder_status = getSpecificFolder(
                          token,
                          selectedTab === "videoConference"
                            ? org_arr
                            : selectedOrg,
                          e.target.value
                        );
                        folder_status.then((res) => {
                          if (res?.status) {
                            setFolder(res?.result);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    onChange={(e) => {
                      if (
                        (selectedOrg?.toString() === "" &&
                          selectedTab === "appointment") ||
                        (org_arr?.length === 0 &&
                          selectedTab === "videoConference")
                      ) {
                        toast("Select an Organization First!", {
                          type: "info",
                        });
                      } else {
                        setFolderName(e.target.value);
                        clearTimeout(typingTimer);
                        typingTimer = setTimeout(() => {
                          const folder_status = getSpecificFolder(
                            token,
                            selectedTab === "videoConference"
                              ? org_arr
                              : selectedOrg,
                            e.target.value
                          );
                          folder_status.then((res) => {
                            if (res?.status) {
                              setFolder(res?.result);
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                            }
                          });
                        }, [doneTypingInterval]);
                      }
                    }}
                  />

                  {/****************  Folder List ***************/}
                  <div className="organization-list">
                    {folder?.length?.toString() !== "0" ? (
                      !folder?.message ? (
                        folder?.map((curr, index) => {
                          if (curr?.type?.toString() === "folder") {
                            return (
                              <label
                                htmlFor={curr?.unique_id}
                                onChange={() => {
                                  setFolderName(curr?.name);
                                  setFolderUnique(curr?.unique_id);
                                  setFolderBookmark(curr?.bookmark);
                                }}
                                className="organization-folder"
                                key={index}
                              >
                                <input
                                  type="radio"
                                  name="folder"
                                  id={curr?.unique_id}
                                  value={curr?.unique_id}
                                />
                                <div>
                                  <img src={folderIcon} alt="Folder" />
                                  <span className="multiple-line-text">
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <i>
                                      ( {curr?.bookmark ? curr?.bookmark : null}{" "}
                                      )
                                    </i>
                                  </span>
                                </div>
                              </label>
                            );
                          }
                        })
                      ) : (
                        <div className="orgListAlert">No Such Folder Found</div>
                      )
                    ) : null}
                  </div>

                  {/* <label htmlFor="select-file">Select File/Folder</label>
                <input type="file" id="select-file" hidden /> */}
                </div>
              </div>
            )}

            <div className="appointment-form-taskitem">
              <div className="icon">
                <img src={awesomeList} alt="File" />
              </div>
              <div className="data">
                <>
                  <div className="input-holder">
                    {listItem?.map((curr, index) => {
                      return (
                        <input
                          key={index}
                          type={curr}
                          maxLength= {30}
                          placeholder={`Task list item ${index + 1}`}
                          onChange={(e) => {
                            tasklist_arr[index] = e.target.value;
                          }}
                        />
                      );
                    })}
                  </div>

                  <button
                    type="button"
                    className="add-button"
                    onClick={() => {
                      if (tasklist_arr[listItem?.length - 1]?.trim()?.length > 0) {
                        setListItem([...listItem, "text"]);
                      }

                    }}
                  >
                    <img src={addCircle} alt="add" />
                  </button>

                  {listItem.length > 1 ? (
                    <IconButton
                      type="button"
                      onClick={() => {
                        listItem.splice(0, 1);
                        tasklist_arr.splice(tasklist_arr.length - 1, 1);
                        setListItem([...listItem]);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </>
              </div>
            </div>

            <div className="appointment-form-submit">
              <Button variant="contained" type="submit">
                Create Appointment
              </Button>
            </div>
          </form>
        </div>
      </div>
      {loader}
    </>
  );
};

export default CreateAppointment;
