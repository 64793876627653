import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import logo from "Media/blank-profile-picture.png";
import { requestConnection } from "Config/API/action";
import { search } from "Config/API/get";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SearchBar = ({ device }) => {
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [searchData, setSearchData] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = useSelector((state) => state.authenticationReducer?.role);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  let doneTypingInterval = 1500;
  let typingTimer;

  const sendRequest = (conn_id) => {
    setLoader();
    const request_status = requestConnection(conn_id, token);
    request_status
      .then((response) => {
        if (response?.status) {
          resetLoader();
          toast(response?.message, {
            type: "success",
          });
          const search_status = search(searchedKeyword, token);
          search_status.then((res) => {
            setSearchData(res?.organizationData);
          });
        } else {
          resetLoader();
          toast(response?.message, { type: "error" });
        }
      })
      .catch(() => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  const handleChange = (e) => {
    clearTimeout(typingTimer);
    if (e.target.value) {
      typingTimer = setTimeout(() => {
        setSearchedKeyword(e.target.value);
        const search_status = search(e.target.value, token);
        search_status.then((res) => {
          if (res?.status) {
            setSearchData(res?.organizationData);
          } else {
            toast(res?.message, {
              type: "error",
            });
          }
        });
      }, doneTypingInterval);
    } else {
      setSearchData("");
    }
  };

  // render desktop search bar
  const desktopBar = () => {
    return (
      <>
        <IconButton>
          <SearchIcon />
        </IconButton>
        <TextField
          label="Search & Connect Organizations"
          variant="filled"
          type="search"
          style={{ borderRadius: "5px" }}
          fullWidth
          inputProps={{
            autoComplete: "off",
            form: {
              autoComplete: "off",
            },
          }}
          onChange={handleChange}
        />
      </>
    );
  };

  // render mobile search bar
  const mobileBar = () => {
    return (
      <TextField
        label="Search..."
        variant="filled"
        type="search"
        style={{ borderRadius: "5px" }}
        fullWidth
        onClick={() => {
          const search_status = search("", token);
          search_status.then((res) => {
            if (res?.status) {
              setSearchData(res?.organizationData);
            } else {
              toast(res?.message, {
                type: "error",
              });
            }
          });
        }}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  // render search results
  const searchResult = (organizationData) => {
    if (
      organizationData === undefined ||
      organizationData === null ||
      organizationData === "" ||
      organizationData?.length === 0
    ) {
      organizationData = {};
    }

    return (
      <>
        {Object.keys(organizationData).length?.toString() !== "0" ? (
          organizationData?.map((curr) => {
            return (
              <div
                className="result-wrapper"
                key={curr?.id}
                onClick={() => {
                  navigate("/organization", {
                    state: {
                      data:
                        curr?.super_org_id?.toString() === "-1"
                          ? "-1"
                          : curr?.id,
                    },
                  });
                }}
              >
                <div>
                  <div className="result-logo">
                  
                               <img
            src={curr?.logo ||logo}
            alt="Profile"
            width="54px"
            height="54px"
            style={{ borderRadius: "50% ", objectFit: "contain" }}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = logo; // Fallback to demo image
            }}
          />
                  </div>
                  <div className="result-text">
                    <h3 style={{ margin: "unset" }}>
                      {curr?.organization_name
                        ? curr?.organization_name
                        : "No Organization name to Show"}
                    </h3>
                    <span>
                      {curr?.address ? curr?.address : "No Address to Show"}
                    </span>
                  </div>
                </div>
                <div className="result-button">
                  {(role?.toString() === "admin" ||
                    role?.toString() === "super_admin") &&
                  curr?.connection_status?.toString() === "not-connected" ? (
                    <>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{ background: "#5CA127" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          sendRequest(curr?.id);
                        }}
                      >
                        SEND REQUEST
                      </Button>
                    </>
                  ) : (role?.toString() === "admin" ||
                      role?.toString() === "super_admin") &&
                    (curr?.connection_status?.toString() === "pending" ||
                      curr?.connection_status?.toString() === "requested") ? (
                    <>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{ background: "#C4A21C" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        REQUEST PENDING
                      </Button>
                    </>
                  ) : curr?.connection_status?.toString() === "connected" ? (
                    <>
                      <Button
                        fullWidth
                        variant="text"
                        style={{ color: "#15633b", fontWeight: "bold" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        CONNECTED
                      </Button>
                    </>
                  ) : null}
                </div>
              </div>
            );
          })
        ) : (
          <p className="no-data">No Data Found</p>
        )}
      </>
    );
  };

  return (
    <>
      <div className="search-bar-container">
        {device?.toString() === "desktop" ? desktopBar() : mobileBar()}
      </div>

      {/* for overlay  */}
      <div
        className="result-container"
        style={{
          border: searchData?.length === 0 ? "1px solid #a3c1f5" : "unset",
        }}
      >
        {searchData ? searchResult(searchData) : null}
      </div>
      {loader}
    </>
  );
};

export default SearchBar;
