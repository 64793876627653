import React, { useEffect, useState } from "react";
import {
  internalFolderDataAction,
  selectedFileDataAction,
  selectedFolderDataAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

/* eslint-disable array-callback-return */
import { Button } from "@mui/material";
import CurrentOrganizationVisitButton from "../CurrentOrganizationVisitButton/CurrentOrganizationVisitButton";
import RenderSTInternalRaiseRequestForm from "Components/Popup/STRaiseInternalRequestForm/Container/RenderSTRaiseRequestForm";
import RenderSTRaiseRequestForm from "Components/Popup/STRaiseRequestForm/Container/RenderSTRaiseRequestForm";
import { getAllSubscriptions } from "Config/API/get";
import { toast } from "react-toastify";

const Bookmark = ({ internalFolder, currentOrg, setLoader, resetLoader }) => {
  const token = localStorage.getItem("token");
  const addFolderData = useSelector((state) => state.selectedFolderDataReducer);
  const rootFolderData = useSelector(
    (state) => state.selectedFolderRootDataReducer
  );
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const dispatch = useDispatch();
  const bookmark_array = addFolderData?.bookmark?.split(" > ");
  const [internalRequestPopup, setInternalRequestPopup] = useState(false);
  const [requestPopup, setRequestPopup] = useState(false);
  const [serviceTicketSubscription, setServiceTicketSubscription] = useState(
    []
  );
  useEffect(() => {
    getAllAvailableSubscriptions();
  }, []);

  const getAllAvailableSubscriptions = () => {
    const response = getAllSubscriptions(token);
    response.then((res) => {
      if (res.data.status) {
        const filteredSubscription = res.data.data?.filter(
          (item) => item?.subscription_type === "service_ticket"
        );
        setServiceTicketSubscription(filteredSubscription[0]);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };
  // poping last blank array element
  if (
    bookmark_array &&
    bookmark_array[bookmark_array?.length - 1]?.toString() === ""
  ) {
    bookmark_array?.pop();
  }

  const handleBookMarkClick = (array, index, bookmarkName) => {
    array?.map((item) => {
      if (item?.index === index && item?.name === bookmarkName) {
        dispatch(selectedFolderDataAction(item));
        if (internalFolder === "")
          return dispatch(selectedFileDataAction(item?.items));
        dispatch(internalFolderDataAction(item?.items));
      } else handleBookMarkClick(item?.items, index, bookmarkName);
    });
  };

  const handleRootBookMarkClick = (index, bookmarkName) => {
    const folderData =
      internalFolder === "" ? rootFolderData : rootFolderData[0];
    if (folderData?.index === index) {
      dispatch(selectedFolderDataAction(folderData));
      if (internalFolder === "")
        return dispatch(selectedFileDataAction(folderData?.items));
      dispatch(internalFolderDataAction(folderData?.items));
    }
    handleBookMarkClick(folderData?.items, index, bookmarkName);
  };

  return (
    <>
      <div
        className={`file_explorer_upper ${
          bookmark_array?.length > 1 &&
          internalFolder === "" &&
          loggedInUser?.scope !== "internal" &&
          currentOrg?.isServiceTicketSubscription &&
          "file_explorer_upper_bookmark"
        }`}
      >
        <div className="file_explorer_head">Folder Viewer</div>
        <div className="file_explorer_head_right_container">
          {bookmark_array?.length > 1 &&
            (internalFolder === "" ? (
              // currentOrg?.isServiceTicketSubscription &&
              loggedInUser?.scope !== "internal" && (
                <Button
                  className="file_explorer_head_right_container_button"
                  variant="contained"
                  style={{ background: "rgb(92, 161, 39)" }}
                  onClick={() => {
                    if (loggedInUser?.ticket_external_role === "manager") {
                      setRequestPopup(true);
                    } else
                      toast("You do not have access", {
                        type: "info",
                      });
                  }}
                >
                  SERVICE REQUEST
                </Button>
              )
            ) : (
              <Button
                className="file_explorer_head_right_container_button"
                variant="contained"
                style={{ background: "rgb(92, 161, 39)" }}
                onClick={() => {
                  if (
                    serviceTicketSubscription?.subscriptionStatus?.toString() ===
                    "true"
                  )
                    setInternalRequestPopup(true);
                  else
                    toast("You are not subscribed to service ticketing", {
                      type: "info",
                    });
                }}
              >
                SERVICE REQUEST
              </Button>
            ))}
          {internalFolder === "" && (
            <CurrentOrganizationVisitButton
              setLoader={setLoader}
              resetLoader={resetLoader}
            />
          )}
        </div>

        {/* <BackButton /> */}
      </div>
      <div className="bookmark-container">
        {bookmark_array ? (
          <>
            {bookmark_array?.map((curr, index) => {
              return (
                <span
                  key={index}
                  onClick={() => handleRootBookMarkClick(++index, curr)}
                >
                  {curr + " > "}
                </span>
              );
            })}
          </>
        ) : null}
      </div>
      <div style={{ position: "absolute", left: 0 }}>
        {requestPopup && (
          <RenderSTRaiseRequestForm
            hide={setRequestPopup}
            service_org_id={currentOrg?.conn_id}
            ticketData={{
              folderNameState: addFolderData?.name,
              folderLinkState: addFolderData?.sharePath,
              folderIdState: addFolderData?.unique_id,
            }}
          />
        )}
        {internalRequestPopup ? (
          <RenderSTInternalRaiseRequestForm
            hide={setInternalRequestPopup}
            service_org_id={general_info?.id}
            ticketData={{
              folderLinkState: addFolderData?.sharePath,
              folderIdState: addFolderData?.unique_id,
              folderNameState: addFolderData?.name,
              type: "new ticket",
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default Bookmark;
