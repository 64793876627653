import "./RenderEditAppointmentPopup.css";

import React, { useEffect, useRef, useState } from "react";
import {
  fileExplorerSearch,
  getRespectiveRates,
  listOfAppointOrg,
  listOfAppointUsers,
  listOfServiceTicketUsers,
} from "Config/API/get";

import { Button } from "@mui/material";
import { ChangeDateToIosString } from "Constants/Functions";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PageviewIcon from "@mui/icons-material/Pageview";
import PersonIcon from "@mui/icons-material/Person";
import TimePicker from "rc-time-picker";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import addCircle from "Media/icons/icon-material-add-circle.svg";
import awesomeList from "Media/icons/icon-awesome-list.svg";
import blank from "Media/blank-profile-picture.png";
import clockIcon from "Media/icons/icon-awesome-clock.svg";
import fileIcon from "Media/icons/icon-open-file.svg";
import folderIcon from "Media/icons/Folder-Close.png";
import moment from "moment-timezone";
import plusIcon from "Media/icons/icon-feather-plus.svg";
import { toast } from "react-toastify";
import { updateAppointment } from "Config/API/action";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useSelector } from "react-redux";
import userIcon from "Media/icons/icon-awesome-user-friends.svg";

const RenderEditAppointmentPopup = ({
  hide,
  event,
  videoChatDetails,
  setVideoChatDetails,
}) => {
  const token = localStorage.getItem("token");
  let doneTypingInterval = 1500;
  let typingTimer;
  let appoi_status = "";
  let org_id = useSelector((state) => state.renderGeneralInfo?.id);
  let id = useSelector((state) => state.authenticationReducer?.id);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  // testing ref for outside click **IMPORTANT**
  const wrapperRef = useRef(null);
  const userRef = useRef(null);
  const folderRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(userRef);
  useOutsideAlerter(folderRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [title, setTitle] = useState(event?.title ? event?.title : "");
  const [desc, setDesc] = useState(event?.desc ? event?.desc : "None");
  const [startDate, setStartDate] = useState(
    moment(event?.start).format("YYYY-MM-DD")
  );
  const [startTime, setStartTime] = useState(moment(event?.start));
  const [endTime, setEndTime] = useState(moment(event?.end));
  // user listing
  const [users, setUsers] = useState(event?.emp?.map((curr) => curr?.name));
  const [orgList, setOrgList] = useState("");
  const [organization, setOrganization] = useState(
    event?.conn?.map((curr) => curr?.org_name)
  );
  const [address, setAddress] = useState(event?.address);
  const [isBillable, setIsBillable] = useState(
    event?.videoChatDetail?.is_billable === "true" ? true : false
  );
  const [listItem, setListItem] = useState(event?.task);
  // user listing
  const [userList, setUserList] = useState([]);
  // folder list
  const [folder, setFolder] = useState([]);
  const [folderName, setFolderName] = useState(event?.folder[0]?.folder_name);
  const [folderUnique, setFolderUnique] = useState("");
  const [folderBookmark, setFolderBookmark] = useState("");
  // organization listing
  const [selectedOrg, setSelectedOrg] = useState(
    event?.conn?.map((curr) => curr?.id)
  );
  const [billableConnId, setBillableConnId] = useState(
    event?.videoChatDetail?.conn_id
  );
  const [requestorName, setRequestorName] = useState(
    event?.requestor?.organization_name
      ? event?.requestor?.organization_name
      : ""
  );
  const [org_arr, setOrg_arr] = useState(event?.conn?.map((curr) => curr?.id));
  const [emp_arr, setEmp_arr] = useState(
    event?.emp?.map((curr) => curr?.id?.toString())
  );
  const [showVideoChatRatePopup, setShowVideoChatRatePopup] = useState(false);
  const [org_name_arr, setorg_name_arr] = useState([
    Object.keys(event?.requestor).length > 0
      ? {
          id: event?.requestor?.id,
          orgName: event?.requestor?.organization_name,
        }
      : {
          id: event?.videoChatDetail?.conn_id,
          orgName: event?.videoChatDetail?.connOrgName,
        },
  ]);

  const getVideoChatRate = () => {
    const response = getRespectiveRates(token);
    response.then((res) => {
      const videoRate = res.data.data?.filter(
        (item) => item?.rate_type === "video_chat"
      );
      setVideoChatDetails(videoRate);
    });
  };

  const handleUser = (id) => {
    if (!emp_arr?.includes(id)) {
      emp_arr?.push(id);
    } else {
      const index = emp_arr?.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        emp_arr?.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setUsers(`${emp_arr?.length} users selected`);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!startTime) return toast("Start Time in required", { type: "info" });
    if (!endTime) return toast("End Time in required", { type: "info" });
    const startDateTime = ChangeDateToIosString(
      startDate,
      `${startTime?.format("HH:mm")}:00`
    );
    const endDateTime = ChangeDateToIosString(
      startDate,
      `${endTime?.format("HH:mm")}:00`
    );
    const timeDifference = moment(endDateTime).diff(startDateTime, "seconds");
    if (timeDifference <= 0) return toast("Invalid End Time", { type: "info" });
    if (
      event?.type === "conference" &&
      videoChatDetails[0]?.rate === "" &&
      isBillable
    ) {
      if (loggedInUserDetails?.credit_account_manager === "manager")
        return setShowVideoChatRatePopup(true);
      return toast(
        "Video calling rates not available to make it a billable call. You don't have permission to set rates",
        { type: "info" }
      );
    }
    setLoader();
    // pushing our id here
    id = id.toString();
    if (!emp_arr?.includes(id)) emp_arr?.push(id);
    const appointment_status = updateAppointment(
      token,
      event?.ticket_id || event?.type === "conference" ? org_arr : selectedOrg,
      title,
      desc,
      startDateTime,
      endDateTime,
      address,
      appoi_status,
      listItem,
      emp_arr,
      folderName,
      folderUnique,
      folderBookmark,
      event?.ticket_id ? event?.ticket_id : "",
      isBillable ? "true" : "false",
      billableConnId,
      event?.event_id,
      event?.type,
      videoChatDetails[0]?.rate ? videoChatDetails[0]?.rate : ""
    );
    appointment_status
      .then((res) => {
        resetLoader();
        if (res?.status) {
          toast(res?.message, {
            type: "success",
          });
          window.location.reload();
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  return (
    <>
      <div className="editAppointment-parent" style={{ left: 0 }}>
        <div className="editAppointment-main">
          <form
            className="editAppointment-form-body"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="editAppointment-heading">
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            </div>
            <div
              className="create-appointment-upper"
              style={{ flexDirection: "row" }}
            >
              <div className="create-appointment-head">
                {event?.type === "conference"
                  ? "Video Conference"
                  : "Appointment"}
              </div>
            </div>
            <div className="create-appointment-lower">
              <div className="appointment-form-details">
                <div className="icon">
                  <img src={plusIcon} alt="plus" />
                </div>
                <div className="data">
                  {event?.ticket_id ? (
                    <input
                      required
                      type="text"
                      maxLength={30}
                      name="title"
                      value={"#" + event?.ticket_id}
                      placeholder="Add Title eg. Call with Bloom Softech"
                      readOnly
                    />
                  ) : (
                    <input
                      required
                      type="text"
                      name="title"
                      maxLength={30}
                      placeholder="Add Title eg. Call with Bloom Softech"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  )}
                  <textarea
                    cols="30"
                    rows="5"
                    name="description"
                    placeholder="More Details..."
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="appointment-form-date">
                <div className="icon">
                  <img src={clockIcon} alt="plus" />
                </div>

                <div className="data">
                  <input
                    required
                    type="date"
                    value={startDate}
                    min={moment().startOf("year").format("YYYY-MM-DD")} //added for year validation
                    max={moment()
                      .add(100, "years")
                      .endOf("year")
                      .format("YYYY-MM-DD")} // added for year validation
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                  <div className="rc-time-picker-input-custom-container">
                    <TimePicker
                      use12Hours
                      showSecond={false}
                      minuteStep={15}
                      placeholder="Start Time"
                      className="rc-time-picker-input-first"
                      onChange={(e) => {
                        if (e) {
                          setStartTime(e.format("hh:mm A"));
                          setEndTime(null); // Reset end time when start time changes
                        } else {
                          setStartTime(null);
                        }
                      }}
                      allowClear={false}
                      value={startTime ? moment(startTime, "hh:mm A") : null}
                    />
                    -
                    <TimePicker
                      use12Hours
                      showSecond={false}
                      minuteStep={15}
                      placeholder="End Time"
                      className="rc-time-picker-input-last"
                      onChange={(e) => {
                        if (e) {
                          const time = e.format("hh:mm A");

                          if (
                            startTime &&
                            moment(time, "hh:mm A").isSameOrBefore(
                              moment(startTime, "hh:mm A")
                            )
                          ) {
                            console.error(
                              "End time must be greater than start time."
                            );
                          } else {
                            setEndTime(time);
                          }
                        } else {
                          setEndTime(null);
                        }
                      }}
                      allowClear={false}
                      value={endTime ? moment(endTime, "hh:mm A") : null} // ✅ Use endTime, not startTime
                      disabled={!startTime}
                    />
                  </div>
                </div>
              </div>

              {event?.ticket_id ? (
                <div className="appointment-form-organization">
                  <div className="icon">
                    <PersonIcon />
                  </div>
                  <div className="data" ref={wrapperRef}>
                    <textarea
                      cols="30"
                      rows="5"
                      name="description"
                      value={requestorName}
                      placeholder="Requestor Details"
                      readOnly
                    ></textarea>
                  </div>
                </div>
              ) : (
                <div className="appointment-form-organization">
                  <div className="icon">
                    <PageviewIcon />
                  </div>
                  <div className="data" ref={wrapperRef}>
                    <input
                      required
                      type="text"
                      value={organization}
                      maxLength={30}
                      placeholder="Search Organization..."
                      // this work for intital clicks
                      onClick={() => {
                        const organization_list = listOfAppointOrg(token, "");
                        organization_list.then((res) => {
                          if (res?.status) {
                            setOrgList(res?.organizationData);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                        const organization_list = listOfAppointOrg(
                          token,
                          e.target.value
                        );
                        organization_list.then((res) => {
                          if (res?.status) {
                            setOrgList(res?.organizationData);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                    />
                    <div className="organization-list">
                      {/*****************search result*********************/}
                      {orgList?.length?.toString() !== "0" ? (
                        !orgList?.message ? (
                          orgList?.map((curr, index) => {
                            return (
                              <div className="data-list" key={index}>
                                <div>
                                  <img
                                    src={curr?.logo || blank}
                                    alt="logo"
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop
                                      e.target.src = blank; // Fallback to demo image
                                    }}
                                  />
                                  <span>
                                    {curr?.organization_name
                                      ? curr?.organization_name
                                      : "No Name"}
                                  </span>
                                </div>
                                {org_arr?.includes(curr?.id) ? (
                                  <Button
                                    type="button"
                                    variant="contained"
                                    style={{ background: "rgb(92, 161, 39)" }}
                                    onClick={() => {
                                      org_arr?.splice(
                                        org_arr?.indexOf(curr?.id),
                                        1
                                      );
                                      const selectedOrgIds = org_name_arr?.map(
                                        (item) => item?.id
                                      );
                                      org_name_arr?.splice(
                                        selectedOrgIds?.indexOf(curr?.id),
                                        1
                                      );
                                      setIsBillable(false);
                                      setBillableConnId("");
                                      setUsers("");
                                      setUserList([]);
                                      emp_arr.splice(0, emp_arr?.length);
                                      setFolder([]);
                                      setFolderName("");
                                      setFolderUnique("");
                                      setFolderBookmark("");
                                      if (org_arr?.length === 0)
                                        return setOrganization("");
                                      setOrganization(
                                        `${org_arr?.length} Org Selected`
                                      );
                                    }}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      if (event?.type === "conference") {
                                        setOrg_arr((oldArray) => [
                                          ...oldArray,
                                          curr?.id,
                                        ]);
                                        setorg_name_arr((oldArray) => [
                                          ...oldArray,
                                          {
                                            id: curr?.id,
                                            orgName: curr?.organization_name,
                                          },
                                        ]);
                                        setOrganization(
                                          `${org_arr?.length + 1} Org Selected`
                                        );
                                      } else {
                                        setSelectedOrg([curr?.id]);
                                        setOrgList("");
                                        setOrganization(
                                          curr?.organization_name
                                        );
                                        setUsers("");
                                        setUserList([]);
                                        emp_arr?.splice(0, emp_arr?.length);
                                        setFolder([]);
                                        setFolderName("");
                                        setFolderUnique("");
                                        setFolderBookmark("");
                                      }
                                    }}
                                  >
                                    Select
                                  </Button>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="orgListAlert">
                            No Such Connected Organizaton Found
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              )}

              {event?.ticket_id ? (
                <div className="appointment-form-user">
                  <div className="icon">
                    <img src={userIcon} alt="User" />
                  </div>
                  <div className="data" ref={userRef}>
                    <input
                      type="text"
                      value={users}
                      maxLength={30}
                      required
                      placeholder="Select users..."
                      onClick={() => {
                        let user_list;
                        if (event?.type === "conference")
                          user_list = listOfAppointUsers(token, org_arr, "");
                        else
                          user_list = listOfServiceTicketUsers(
                            token,
                            org_id,
                            ""
                          );
                        user_list.then((res) => {
                          if (res?.status) {
                            setUserList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                      onChange={(e) => {
                        setUsers(e.target.value);
                        let user_list;
                        if (event?.type === "conference")
                          user_list = listOfAppointUsers(
                            token,
                            org_arr,
                            e.target.value
                          );
                        else
                          user_list = listOfServiceTicketUsers(
                            token,
                            org_id,
                            e.target.value
                          );
                        user_list.then((res) => {
                          if (res?.status) {
                            setUserList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          emp_arr = [];
                        }
                      }}
                    />

                    {/****************  User List ***************/}
                    <div className="organization-list">
                      {userList.length?.toString() !== "0" ? (
                        !userList.message ? (
                          userList?.map((curr, index) => {
                            return (
                              <div className="data-list" key={index}>
                                <label
                                  htmlFor={curr?.id}
                                  onChange={() =>
                                    handleUser(curr?.id?.toString())
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name={curr?.id}
                                    id={curr?.id}
                                    value={curr?.id}
                                    checked={emp_arr?.includes(
                                      curr?.id?.toString()
                                    )}
                                  />
                                  <div>
                                    <img
                                      src={curr?.image || blank}
                                      alt="logo"
                                      onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop
                                        e.target.src = blank; // Fallback to demo image
                                      }}
                                    />
                                    <div>
                                      <span>
                                        {curr?.name ? curr?.name : "No Name"}
                                      </span>
                                      <span style={{ fontWeight: "normal" }}>
                                        Org: {curr?.organization_name}
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <div className="orgListAlert">No Such User Found</div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="appointment-form-user">
                  <div className="icon">
                    <img src={userIcon} alt="User" />
                  </div>
                  <div className="data" ref={userRef}>
                    <input
                      required
                      type="text"
                      maxLength={30}
                      value={users}
                      placeholder="Select Users..."
                      onClick={() => {
                        if (
                          (selectedOrg?.toString() === "" &&
                            event?.type === "appointment") ||
                          (org_arr?.length === 0 &&
                            event?.type === "conference")
                        ) {
                          toast("Select an Organization First!", {
                            type: "info",
                          });
                        } else {
                          const user_list = listOfAppointUsers(
                            token,
                            event?.type === "conference"
                              ? org_arr
                              : selectedOrg,
                            ""
                          );
                          user_list.then((res) => {
                            if (res?.status) {
                              setUserList(res?.data);
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                            }
                          });
                        }
                      }}
                      onChange={(e) => {
                        setUsers(e.target.value);
                        if (
                          (selectedOrg?.toString() === "" &&
                            event?.type === "appointment") ||
                          (org_arr?.length === 0 &&
                            event?.type === "conference")
                        ) {
                          toast("Select an Organization First!", {
                            type: "info",
                          });
                        } else {
                          const user_list = listOfAppointUsers(
                            token,
                            selectedOrg,
                            e.target.value
                          );
                          user_list.then((res) => {
                            if (res?.status) {
                              setUserList(res?.data);
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                            }
                          });
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          emp_arr = [];
                        }
                      }}
                    />

                    {/****************  User List ***************/}
                    <div className="organization-list">
                      {userList.length?.toString() !== "0" ? (
                        !userList.message ? (
                          userList?.map((curr, index) => {
                            return (
                              <div className="data-list" key={index}>
                                <label
                                  htmlFor={curr?.id}
                                  onChange={() =>
                                    handleUser(curr?.id?.toString())
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    name={curr?.id}
                                    id={curr?.id}
                                    value={curr?.id}
                                    checked={emp_arr?.includes(
                                      curr?.id?.toString()
                                    )}
                                  />
                                  <div>
                                    <img
                                      src={curr?.image || blank}
                                      alt="logo"
                                      onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop
                                        e.target.src = blank; // Fallback to demo image
                                      }}
                                    />
                                    <div>
                                      <span>
                                        {curr?.name ? curr?.name : "No Name"}
                                      </span>
                                      <span style={{ fontWeight: "normal" }}>
                                        Org: {curr?.organization_name}
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <div className="orgListAlert">No Such User Found</div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {event?.type === "conference" ? (
                <div
                  className="appointment-form-location"
                  style={{ alignItems: isBillable ? "flex-start" : "center" }}
                >
                  <div className="icon" style={{ padding: "0px 15px" }}>
                    <MyLocationIcon />
                  </div>
                  <div
                    className="data"
                    style={{
                      display: "flex",
                      gap: "6px",
                      marginTop: isBillable && "5px",
                    }}
                  >
                    <input
                      style={{ height: "15px", width: "15px" }}
                      type="checkbox"
                      checked={isBillable}
                      onChange={(e) => {
                        if (org_arr?.length === 0)
                          return toast("Select an Organization First!", {
                            type: "info",
                          });
                        if (!e.target.checked) setBillableConnId("");
                        setIsBillable(e.target.checked);
                      }}
                    />
                    <div>
                      <span style={{ fontSize: "13px", color: "#51595e" }}>
                        Check for Billable
                      </span>
                      {isBillable &&
                        org_name_arr?.map((item) => (
                          <div
                            className="data"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                              marginTop: "7px",
                            }}
                          >
                            <input
                              style={{ height: "15px", width: "15px" }}
                              type="checkbox"
                              checked={billableConnId === item?.id?.toString()}
                              onChange={() =>
                                setBillableConnId(item?.id?.toString())
                              }
                            />
                            <span
                              style={{ fontSize: "13px", color: "#51595e" }}
                            >
                              {item?.orgName}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="appointment-form-location">
                  <div className="icon">
                    <MyLocationIcon />
                  </div>
                  <div className="data">
                    <input
                      required
                      type="text"
                      placeholder="Add address"
                      maxLength={50}
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="appointment-form-upload">
                <div className="icon">
                  <img src={fileIcon} alt="File" />
                </div>
                <div className="data" ref={folderRef}>
                  <input
                    type="text"
                    value={folderName}
                    maxLength={30}
                    placeholder="Attach folder name"
                    onClick={(e) => {
                      const folder_status = fileExplorerSearch(token, "", "");
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolder(res?.result);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      clearTimeout(typingTimer);
                      setFolderName(e.target.value);
                      typingTimer = setTimeout(() => {
                        const folder_status = fileExplorerSearch(
                          token,
                          e.target.value,
                          ""
                        );
                        folder_status.then((res) => {
                          if (res?.status) {
                            setFolder(res?.result);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }, [doneTypingInterval]);
                    }}
                  />

                  {/****************  Folder List ***************/}
                  <div className="organization-list">
                    {folder?.length?.toString() !== "0" ? (
                      !folder?.message ? (
                        folder?.map((curr, index) => {
                          if (curr?.type?.toString() === "folder") {
                            return (
                              <label
                                htmlFor={curr?.unique_id}
                                onChange={() => {
                                  setFolderName(curr?.name);
                                  setFolderUnique(curr?.unique_id);
                                  setFolderBookmark(curr?.bookmark);
                                }}
                                className="organization-folder"
                                key={index}
                              >
                                <input
                                  type="radio"
                                  name="folder"
                                  id={curr?.unique_id}
                                  value={curr?.unique_id}
                                />
                                <div>
                                  <img src={folderIcon} alt="Folder" />
                                  <span className="multiple-line-text">
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <i>
                                      ( {curr?.bookmark ? curr?.bookmark : null}{" "}
                                      )
                                    </i>
                                  </span>
                                </div>
                              </label>
                            );
                          }
                        })
                      ) : (
                        <div className="orgListAlert">No Such Folder Found</div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="appointment-form-taskitem">
                <div className="icon">
                  <img src={awesomeList} alt="File" />
                </div>
                <div className="data">
                  <>
                    <div className="input-holder">
                      {listItem?.map((curr, index) => {
                        return (
                          <>
                            <input
                              key={index}
                              type={curr}
                              maxLength={30}
                              placeholder={`Task list item ${index + 1}`}
                              value={listItem[index]}
                              onChange={(e) => {
                                setListItem((prevArr) => {
                                  const result = [...prevArr];
                                  result[index] = e.target.value;
                                  return result;
                                });
                              }}
                            />
                          </>
                        );
                      })}
                    </div>

                    <button
                      type="button"
                      className="add-button"
                      onClick={() => {
                        const lastItem = listItem[listItem.length - 1];
                        if (lastItem && lastItem.trim().length > 0) {
                          setListItem([...listItem, ""]);
                        }
                      }}
                    >
                      <img src={addCircle} alt="add" />
                    </button>

                    {listItem.length > 1 ? (
                      <IconButton
                        type="button"
                        onClick={() => {
                          listItem?.splice(listItem?.length - 1, 1);
                          setListItem([...listItem]);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                  </>
                </div>
              </div>

              <div className="appointment-form-submit">
                <Button variant="contained" type="submit">
                  Update Appointment
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showVideoChatRatePopup && (
        <VideoCallingRates
          hide={setShowVideoChatRatePopup}
          ratesData={videoChatDetails}
          message="You are Setting Video Chat Rates"
          rateType="video_chat"
          getAllSubscriptionsRates={getVideoChatRate}
        />
      )}
      {loader}
    </>
  );
};

export default RenderEditAppointmentPopup;
