import "./ChatOrganization.css";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";
import RenderSearchBar from "Components/SearchBar/Container/RenderSearchBar";
import { getOrganizationConnection } from "Config/API/get";
import logo from "Media/blank-profile-picture.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChatOrganization = () => {
  const [searchData, setSearchData] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // calling api of organization list
  useEffect(() => {
    const organization_list = getOrganizationConnection(token);
    organization_list
      .then((res) => {
        if (res?.status) {
          setSearchData(res?.organizationData);
        }
      })
      .catch((e) => {});
  }, []);

  const chatRef = useRef(null);
  useOutsideAlerter(chatRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // organization list
  const searchResult = (organizationData) => {
    if (
      organizationData === undefined ||
      organizationData === null ||
      organizationData === "" ||
      organizationData === []
    ) {
      organizationData = {};
    }

    return (
      <>
        {Object.keys(organizationData).length !== 0
          ? organizationData?.map((curr) => {
              return (
                <div
                  className="result-wrapper"
                  key={curr?.id}
                  onClick={() => {
                    navigate("/organization", {
                      state: { data: curr?.id },
                    });
                    dispatch(renderOrganizationInfo({}));
                  }}
                >
                  <div>
                    <div className="result-logo">
                      <img
                        src={curr?.logo || logo}
                        alt="Profile"
                        width="54px"
                        style={{ borderRadius: "50% " }}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src = logo; // Fallback to demo image
                        }}
                      />
                    </div>
                    <div className="result-text">
                      <h3 style={{ margin: "unset" }}>
                        {curr?.organization_name
                          ? curr?.organization_name
                          : "No Organization name to Show"}
                      </h3>
                      <span>
                        {curr?.address ? curr?.address : "No Address to Show"}
                      </span>
                    </div>
                  </div>
                  <div className="result-button">
                    <Button
                      fullWidth
                      variant="text"
                      style={{ color: "#5CA127" }}
                    >
                      CONNECTED
                    </Button>
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  };

  return (
    <>
      <div className="chatorganization-container">
        <div className="chatorganization-upper">
          <h3>Connections</h3>
        </div>
        <div className="chatorganization-lower">
          <div className="chatorganization-search" ref={chatRef}>
            <RenderSearchBar device="desktop" />
          </div>
          <div className="chatorganization-wrapper">
            <div className="result-container">
              {searchData ? (
                searchResult(searchData)
              ) : (
                <p style={{ textAlign: "center" }}>
                  No Connected Organization Found
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatOrganization;
