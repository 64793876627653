import "./RatesAndSubscription.css";

import React, { useEffect, useState } from "react";
import {
  deletePurchasedSubscription,
  purchaseSubscription,
} from "Config/API/action";
import {
  getAllSubscribers,
  getAllSubscriptions,
  getRespectiveRates,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Headers from "Components/Header/Headers";
import { IconButton } from "@mui/material";
import Navigation from "Components/SideNavBar/Navigation";
import PurchaseSubscription from "Components/Popup/PurchaseSubscription/PurchaseSubscription";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import RenderInformationPopup from "Components/Popup/InformationPopup/PureComponents/InformationPopup";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import blank from "Media/blank-profile-picture.png";
import editRates from "Media/icons/editRates.png";
import logo from "Media/Amnius_connect_noText.png";
import mySubscribers from "Media/icons/mySubscribers.png";
import mySubscribersLogo from "Media/icons/mySubscribersLogo.png";
import mySubscriptions from "Media/icons/mySubscriptions.png";
import portfolioFiles from "Media/icons/portfolioFiles.png";
import { purchaseSubscriptionMessages } from "Constants/Values";
import rateSetting from "Media/icons/rateSetting.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import serviceTicketIcon from "Media/Group 353@2x.png";
import { toast } from "react-toastify";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import videoChatRate from "Media/icons/videoChatRate.png";

const RatesAndSubscription = () => {
  const { state } = useLocation();
  const isOrgAmnius = useIsAmniusOrganization();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(
    state?.tab ? state?.tab : "rateSetting"
  );
  const [ratesData, setRatesData] = useState([]);
  const [selectedRatesData, setSelectedRatesData] = useState([]);
  const [serviceTicketingData, setServiceTicketingData] = useState([]);
  const [siteSubscriptions, setSiteSubscriptions] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [allSubscribers, setAllSubscribers] = useState([]);
  const [rateLoading, setRateLoading] = useState(false);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);
  const [subscribersLoading, setSubscribersLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showEditRatesPopup, setShowEditRatesPopup] = useState(false);
  const [showEditServiceTicketRatesPopup, setShowEditServiceTicketRatesPopup] =
    useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchaseSubscription, setShowPurchaseSubscription] =
    useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState([]);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState("");
  const subscriptionsHeader = {
    service_ticket: "SERVICE TICKETING MANAGEMENT",
    video_chat: "VIDEO CHAT RATE",
    portfolio: "PORTFOLIO RATE",
  };
  const subscriptionsPopUpHeader = {
    service_ticket: "Service Ticketing",
    video_chat: "Video Chat",
    portfolio: "Portfolio",
  };
  const loadingArray = ["", ""];

  useEffect(() => {
    getAllSubscriptionsRates();
    getAllAvailableSubscriptions();
    getAllSubscribersData();
    // eslint-disable-next-line
  }, []);

  const getAllSubscriptionsRates = () => {
    if (!rateLoading) setRateLoading(true);
    const response = getRespectiveRates(token);
    response.then((res) => {
      setRateLoading(false);
      if (res.data.status) {
        setSelectedRatesData([]);
        setRatesData(res.data.data);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const getAllAvailableSubscriptions = () => {
    if (!subscriptionsLoading) setSubscriptionsLoading(true);
    const response = getAllSubscriptions(token);
    response.then((res) => {
      setSubscriptionsLoading(false);
      if (res.data.status) {
        setSiteSubscriptions(
          res.data.data?.filter((item) => item?.rate_type === "service_ticket")
        );
        setAllSubscriptions(
          res.data.data?.filter((item) => item?.rate_type !== "service_ticket")
        );
        setServiceTicketingData(
          res.data.data.filter((item) => {
            return item?.rate_type?.toString() === "service_ticket";
          })
        );
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const getAllSubscribersData = () => {
    if (!subscribersLoading) setSubscribersLoading(true);
    const response = getAllSubscribers(token);
    response.then((res) => {
      setSubscribersLoading(false);
      if (res.data.status) {
        setAllSubscribers(res.data.data);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const handelDeleteSubscription = (subscriptionId) => {
    setRateLoading(true);
    setSubscriptionsLoading(true);
    const response = deletePurchasedSubscription(token, subscriptionId);
    response.then((res) => {
      if (res.status) {
        setSiteSubscriptions([]);
        setAllSubscriptions([]);
        setSelectedSubscription([]);
        getAllSubscriptionsRates();
        getAllAvailableSubscriptions();
        toast(res.message, {
          type: "success",
        });
      } else {
        setRateLoading(false);
        setSubscriptionsLoading(false);
        toast("Failed! Try again later", {
          type: "error",
        });
      }
    });
  };

  const subscribeService = () => {
    const response = purchaseSubscription(
      token,
      selectedSubscription?.subscription_type,
      selectedSubscription?.rate,
      selectedSubscription?.subscription_type !== "service_ticket"
        ? selectedSubscription?.subscriptionFrom
        : ""
    );
    response.then((res) => {
      setPurchaseLoading(false);
      if (res.status) {
        if (
          purchaseSubscriptionMessages?.includes(res?.message?.toLowerCase())
        ) {
          toast(res.message, {
            type: "info",
          });
        } else {
          setShowPurchaseSubscription(false);
          getAllAvailableSubscriptions();
          toast(res.message, {
            type: "success",
          });
        }
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const renderSubscriptions = (arr) => {
    return arr?.length > 0 ? (
      <div className="rates-and-subscription-lower-tile-content-container">
        {arr.map((item) => {
          return (
            <div
              className="rates-and-subscription-lower-tile"
              style={{ justifyContent: "space-between", position: "relative" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    item?.rate_type === "service_ticket"
                      ? serviceTicketIcon
                      : portfolioFiles
                  }
                  alt="videoChatRate"
                  style={{ width: "76px", height: "76px" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>{subscriptionsHeader[item?.subscription_type]}</label>
                  {item?.subscription_type !== "service_ticket" && (
                    <label style={{ fontSize: "13px" }}>
                      Org Name : {item?.organization_name}
                    </label>
                  )}
                  <span>Monthly Fee: {item?.rate} Credits</span>
                  {item?.subscriptionStatus?.toString() === "true" &&
                    !isOrgAmnius && (
                      <label
                        style={{
                          fontSize: "13px",
                          color: "#C50000",
                        }}
                      >
                        {item?.isSubscribed?.toString() === "true"
                          ? "Renews on: "
                          : "Ends on: "}
                        {item?.subscriptionEndDate}
                      </label>
                    )}
                </div>
              </div>

              {!isOrgAmnius &&
                ((item?.rate_type === "service_ticket" &&
                  loggedInUserDetails?.ticket_subscription === "manager") ||
                  (item?.rate_type === "portfolio" &&
                    loggedInUserDetails?.portfolio_subscription ===
                      "manager")) &&
                (item?.isSubscribed?.toString() === "true" ? (
                  <IconButton
                    onClick={() => {
                      setSelectedSubscription(item);
                      setShowConfirmPopup(true);
                    }}
                  >
                    <DeleteIcon
                      style={{ color: "#4671A4" }}
                      fontSize="medium"
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setSelectedSubscription(item);
                      setShowPurchaseSubscription(true);
                    }}
                  >
                    <AddCircleOutlineIcon
                      style={{ color: "#4671A4" }}
                      fontSize="medium"
                    />
                  </IconButton>
                ))}
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText(`${subscriptionsHeader[item?.rate_type]}`);
                }}
              />
            </div>
          );
        })}
      </div>
    ) : (
      <span
        style={{
          color: "#4671a4",
          marginTop: "10px",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        No Subscriptions Found
      </span>
    );
  };

  return (
    <>
      <div className="rates-and-subscription-main">
        <div className="rates-and-subscription-header">
          <Headers />
        </div>

        <div className="rates-and-subscription-navigation">
          <Navigation />
        </div>

        <div className="rates-and-subscription-wrapper">
          <div className="rates-and-subscription-heading">
            Rates & Subscription Management
          </div>
          <div className="rates-and-subscription-content">
            <div className="rates-and-subscription-infoText">
              <div
                className={`rates-and-subscription-tile ${
                  selectedTab === "rateSetting" && "active"
                }`}
                onClick={() => setSelectedTab("rateSetting")}
              >
                <img
                  src={rateSetting}
                  alt="rateSetting"
                  style={{ width: "50px", height: "50px" }}
                />

                <span>Rate Setting</span>
              </div>
              {selectedTab === "rateSetting" && (
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("Rate Setting");
                  }}
                />
              )}
            </div>
            <div className="rates-and-subscription-infoText">
              <div
                className={`rates-and-subscription-tile ${
                  selectedTab === "mySubscriptions" && "active"
                }`}
                onClick={() => setSelectedTab("mySubscriptions")}
              >
                <img
                  src={mySubscriptions}
                  alt="mySubscriptions"
                  style={{ width: "50px", height: "50px" }}
                />
                <span>My Subscriptions</span>
              </div>
              {selectedTab === "mySubscriptions" && (
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("My Subscriptions");
                  }}
                />
              )}
            </div>
            <div className="rates-and-subscription-infoText">
              <div
                className={`rates-and-subscription-tile ${
                  selectedTab === "mySubscribers" && "active"
                }`}
                onClick={() => setSelectedTab("mySubscribers")}
              >
                <img
                  src={mySubscribers}
                  alt="mySubscribers"
                  style={{ width: "43px", height: "43px", marginBottom: "7px" }}
                />
                <span>My Subscribers</span>
              </div>
              {selectedTab === "mySubscribers" && (
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("My Subscribers");
                  }}
                />
              )}
            </div>
          </div>
          <div className="rates-and-subscription-lower-tile-container">
            {selectedTab === "rateSetting" &&
              (!rateLoading ? (
                ratesData?.length > 0 ? (
                  <div className="rates-and-subscription-lower-tile-content-container">
                    {ratesData.map((item) => {
                      return (
                        <div
                          className="rates-and-subscription-lower-tile"
                          style={{
                            justifyContent: "space-between",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                item.rate_type === "video_chat"
                                  ? videoChatRate
                                  : portfolioFiles
                              }
                              alt="videoChatRate"
                              style={{ width: "76px", height: "76px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label>
                                {subscriptionsHeader[item?.rate_type]}
                              </label>
                              {!rateLoading &&
                                (item?.rate ? (
                                  <span>
                                    ${item.rate} Credits Per{" "}
                                    {item?.rate_type === "video_chat"
                                      ? "Minute"
                                      : "Month"}
                                  </span>
                                ) : (
                                  <span style={{ color: "#C50000" }}>
                                    Please set rate per{" "}
                                    {item?.rate_type === "video_chat"
                                      ? "minute"
                                      : "month"}
                                  </span>
                                ))}
                            </div>
                          </div>
                          {loggedInUserDetails?.credit_account_manager ===
                            "manager" && (
                            <img
                              src={editRates}
                              alt="editRates"
                              style={{ width: "21px", height: "22px" }}
                              onClick={() => {
                                setSelectedRatesData([item]);
                                setShowEditRatesPopup(true);
                              }}
                            />
                          )}
                          <QuestionMarkIcon
                            className="moneyCreditManagement-info"
                            onClick={() => {
                              setInfoPopup(true);
                              setTabsText(
                                `${subscriptionsHeader[item?.rate_type]}`
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span
                    style={{
                      color: "#4671a4",
                      marginTop: "10px",
                      fontSize: "20px",
                      fontWeight: "bolder",
                    }}
                  >
                    No Rates Found
                  </span>
                )
              ) : (
                <div className="rates-and-subscription-lower-tile-content-container">
                  {loadingArray.map((item) => {
                    return (
                      <div
                        className="rates-and-subscription-lower-tile"
                        style={{
                          justifyContent: "space-between",
                          borderColor: "#ececec",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{ width: "70px", height: "70px" }}
                            className="animation"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "7px",
                              marginLeft: "22px",
                            }}
                          >
                            <label className="rates-and-subscription-rate-loading-label animation" />
                            <span className="rates-and-subscription-rate-loading-span  animation" />
                          </div>
                        </div>
                        {loggedInUserDetails?.credit_account_manager ===
                          "manager" && (
                          <div
                            style={{ width: "22px", height: "22px" }}
                            className="animation"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            {selectedTab === "mySubscriptions" && (
              <>
                <div className="rates-and-subscription-lower-tile-header-container">
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: 50,
                    }}
                  />
                  <span>Site Subscriptions</span>
                </div>
                {!subscriptionsLoading ? (
                  renderSubscriptions(siteSubscriptions)
                ) : (
                  <div className="rates-and-subscription-lower-tile-content-container">
                    <div
                      className="rates-and-subscription-lower-tile"
                      style={{
                        justifyContent: "space-between",
                        borderColor: "#ececec",
                        position: "relative",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{ width: "70px", height: "57px" }}
                          className="animation"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "7px",
                            marginLeft: "22px",
                          }}
                        >
                          <label className="rates-and-subscription-subscriptions-loading-label  animation" />
                          <span className="rates-and-subscription-subscriptions-loading-span  animation" />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "22px",
                          height: "22px",
                          marginRight: "10px",
                        }}
                        className="animation"
                      />
                    </div>
                  </div>
                )}
                <div className="rates-and-subscription-lower-tile-header-container">
                  <img
                    src={mySubscribersLogo}
                    alt="logo"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: 50,
                    }}
                  />
                  <span>Locked Content Subscriptions</span>
                </div>
                {!subscriptionsLoading ? (
                  renderSubscriptions(allSubscriptions)
                ) : (
                  <div className="rates-and-subscription-lower-tile-content-container">
                    {loadingArray.map((item) => {
                      return (
                        <div
                          className="rates-and-subscription-lower-tile"
                          style={{
                            justifyContent: "space-between",
                            borderColor: "#ececec",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{ width: "70px", height: "70px" }}
                              className="animation"
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                marginLeft: "22px",
                              }}
                            >
                              <label className="rates-and-subscription-bottom-subscriptions-loading-top-label animation" />
                              <label className="rates-and-subscription-bottom-subscriptions-loading-mid-label animation" />
                              <span className="rates-and-subscription-bottom-subscriptions-loading-span animation" />
                              <label className="rates-and-subscription-bottom-subscriptions-loading-bottom-label animation" />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "22px",
                              height: "22px",
                              marginRight: "10px",
                            }}
                            className="animation"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {selectedTab === "mySubscribers" && (
              <>
                <div className="rates-and-subscription-lower-tile-header-container">
                  <img
                    src={mySubscribersLogo}
                    alt="logo"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: 50,
                    }}
                    
                  />
                  <span>Locked Content Subscribers</span>
                </div>
                {!subscribersLoading ? (
                  allSubscribers?.length > 0 ? (
                    <div className="rates-and-subscription-lower-tile-content-container">
                      {allSubscribers.map((item) => {
                        return (
                          <div
                            className="rates-and-subscription-lower-tile"
                            style={{
                              justifyContent: "space-between",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={
                                  item?.organization?.logo
                                   || blank
                                }
                                alt="orgLogo"
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  borderRadius: 50,
                                  objectFit: "contain",
                                }}
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent infinite loop
                                  e.target.src = blank; // Fallback to demo image
                                }}
                              />
            
                              
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  {item?.organization?.organization_name}
                                </span>
                              </div>
                            </div>
                            <button
                              className="rates-and-subscription-lower-tile-button"
                              onClick={() => {
                                navigate("/organization", {
                                  state: { data: item?.org_id },
                                });
                                dispatch(renderOrganizationInfo({}));
                              }}
                            >
                              Visit Page
                            </button>
                            <QuestionMarkIcon
                              className="moneyCreditManagement-info"
                              onClick={() => {
                                setInfoPopup(true);
                                setTabsText("Locked Content Subscribers");
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <span
                      style={{
                        color: "#4671a4",
                        marginTop: "10px",
                        fontSize: "20px",
                        fontWeight: "bolder",
                      }}
                    >
                      No Subscribers Found
                    </span>
                  )
                ) : (
                  <div className="rates-and-subscription-lower-tile-content-container">
                    {loadingArray.map((item) => {
                      return (
                        <div
                          className="rates-and-subscription-lower-tile"
                          style={{
                            justifyContent: "space-between",
                            borderColor: "#ececec",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: 50,
                              }}
                              className="animation"
                            />
                            <span className="rates-and-subscription-subscribers-loading-span animation" />
                          </div>
                          <div className="rates-and-subscription-subscribers-loading-button animation" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showEditRatesPopup && (
        <VideoCallingRates
          hide={setShowEditRatesPopup}
          ratesData={selectedRatesData}
          message={`You are Setting ${
            subscriptionsPopUpHeader[selectedRatesData[0]?.rate_type]
          } Rates`}
          rateType={selectedRatesData[0]?.rate_type}
          getAllSubscriptionsRates={getAllSubscriptionsRates}
        />
      )}
      {showEditServiceTicketRatesPopup && (
        <VideoCallingRates
          hide={setShowEditServiceTicketRatesPopup}
          ratesData={serviceTicketingData}
          message="You are Setting Service Ticket Management Rates"
          rateType="service_ticket"
          getAllSubscriptionsRates={getAllAvailableSubscriptions}
        />
      )}
      {showPurchaseSubscription && (
        <PurchaseSubscription
          header={
            subscriptionsPopUpHeader[selectedSubscription?.subscription_type]
          }
          description={`You plan to subscribe to this Service. It will cost you ${selectedSubscription?.rate} Credits per Month`}
          hide={setShowPurchaseSubscription}
          subscribeService={subscribeService}
          setLoading={setPurchaseLoading}
          loading={purchaseLoading}
        />
      )}
      {showConfirmPopup && (
        <RenderConfirm
          message="delete this Service"
          data={selectedSubscription?.id}
          status={handelDeleteSubscription}
          hide={setShowConfirmPopup}
        />
      )}
      {infoPopup && (
        <RenderInformationPopup message={tabsText} hide={setInfoPopup} />
      )}
    </>
  );
};

export default RatesAndSubscription;
