import "firebase/compat/firestore";

import React, { useEffect, useState } from "react";

import NestedCardList from "./NestedCardList";
import firebase from "firebase/compat/app";

const NestedCard = ({
  docid,
  message_id,
  chatUserDetails,
  customSendBy,
  setMessageCount,
  popupShow,
}) => {
  const [nestedMessage, setNestedMessage] = useState("");
console.log({customSendBy})
  useEffect(() => {
    if (docid && message_id) {
      const NestedRef = firebase
        .firestore()
        .collection("external-chat")
        .doc(docid)
        .collection("messages")
        .doc(message_id)
        .collection("messages")
        .orderBy("createdAt", "asc");

      NestedRef.onSnapshot((querySnap) => {
        const allmsg = querySnap.docs?.map((docSanp) => {
          const data = docSanp.data();
          const keey = docSanp.id;
          if (data.createdAt) {
            return {
              ...docSanp.data(),
              createdAt: docSanp.data().createdAt.toDate(),
              keey,
            };
          } else {
            return {
              ...docSanp.data(),
              createdAt: new Date(),
            };
          }
        });
        setMessageCount(allmsg?.length);
        setNestedMessage(allmsg);
      });
    }
  }, [message_id]);

  if (nestedMessage?.length > 0 && popupShow) {
    return (
      <>
        <ul className="chat-thread nested">
          {nestedMessage?.toString() !== ""
            ? nestedMessage?.map((currEle, index) => {
                return (
                  <NestedCardList
                    data={currEle}
                    key={index}
                    chatUserDetails={chatUserDetails}
                    customSendBy={customSendBy}
                  />
                );
              })
            : null}
        </ul>
      </>
    );
  }

  return <></>;
};

export default NestedCard;
