import "./ProfileCard.css";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatIcon from "@mui/icons-material/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import ExternalIcon from "Media/icons/External_icon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconButton } from "@mui/material";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import RenderEditProfile from "Components/Popup/EditProfile/Container/RenderEditProfile";
import RenderEmail from "Components/Popup/Email/Container/RenderEmail";
import { activeInactiveEmployee } from "Config/API/action";
import profilePic from "Media/blank-profile-picture.png";
import { renderChatProfileAction } from "Redux/action/render.action";
import { toast } from "react-toastify";
import useIsMyOrganization from "Hooks/useIsMyOrganization";
import { useNavigate } from "react-router-dom";

const Card = ({
  currEle,
  orgRole,
  usersAllPermissions,
  state,
  selectedCategoryTabData,
  changeCategoryTab,
  adminLoading2,
  showEdit,
  isAdmin,
  getAmniusAdmin,
  getAllCategories
}) => {
  const dispatch = useDispatch();
  const [EmailPopup, setEmailPopup] = useState(false);
  let role = useSelector((state) => state.authenticationReducer?.role);
  const emp_id = useSelector((state) => state.authenticationReducer?.id);
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const isConnected = useSelector(
    (state) => state.renderOrganizationInfoReducer?.isConnected
  );
  const orgName = useSelector(
    (state) => state.renderOrganizationInfoReducer?.organization_name
  );

  const token = localStorage.getItem("token");
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [statusType, setStatusType] = useState("");
  const [deleteUserName, setDeleteUserName] = useState("");
  const isMyOrganization = useIsMyOrganization();

  // Online status or Away status
  const activeStatus = currEle?.online_status ? currEle?.online_status : "";

  let card_role = currEle?.role;

  if (orgRole?.toString() === "other") {
    role = orgRole;
    card_role = orgRole;
  }

  const showChatIcons = () => {
    if (!state?.data || state?.data === "0") return true;
    if (loggedInUser?.scope === "internal" && !isMyOrganization(state?.data))
      return false;
    else return true;
  };

  const moveUserToTrash = (data) => {
    const response = activeInactiveEmployee(
      data?.statusType,
      data?.deleteId,
      data?.token
    );
    response
      .then((result) => {
        if (result?.status) {
          toast(result?.message, { type: "success" });
          if (isAdmin) {
            getAmniusAdmin();
          } else {
            changeCategoryTab(
              "",
              "deleteUser",
              selectedCategoryTabData?.categoryId,
              selectedCategoryTabData?.orgId
            );
          }
        }
      })
      .catch((e) => {
        toast("Failed to move user to trash", { type: "error" });
      });
  };

  return (
    <>
      <div className="card-container">
        {/* only admin & same employee can edit profile  */}
        {(!adminLoading2 &&
          role?.toString() !== "other" &&
          (role?.toString() === "admin" ||
            role?.toString() === "super_admin" ||
            usersAllPermissions?.permission_user_manager?.toString() ===
              "write")) ||
        (role?.toString() === "employee" &&
          emp_id?.toString() === currEle?.id?.toString()) ? (
          <>
            <div className="card-options">
              {(card_role !== "super_admin" ||
                role?.toString() === "super_admin") &&
                showEdit !== "hide" && (
                  <IconButton
                    onClick={() => {
                      setOpenEdit(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}

              {/* only admin can delete the user  */}
              {(card_role !== "admin" &&
                card_role !== "super_admin" &&
                emp_id !== currEle?.id) ||
              (card_role !== "super_admin" &&
                role?.toString() === "super_admin") ? (
                <IconButton
                  className="delete-icon"
                  onClick={() => {
                    if (!confirmPopup) {
                      setDeleteUserName(currEle?.username);
                      setDeleteId(currEle?.id);
                      setStatusType("inactive");
                      setConfirmPopup(true);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </div>
          </>
        ) : null}

        {!adminLoading2 ? (
          <div className="card-board">
            <div className="card-logo">
               
            <img
            src={currEle?.image|| profilePic}
            alt="profile"
            width="55px"
            height="55px"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = profilePic; // Fallback to demo image
            }}
          />
              
              {activeStatus?.toString() === "online" ? (
                <span className="user-status" style={{ color: "#87c823" }}>
                  <FiberManualRecordIcon />
                </span>
              ) : (
                <span className="user-status" style={{ color: "#c23616" }}>
                  <FiberManualRecordIcon />
                </span>
              )}

              {currEle?.scope?.toString() === "external" &&
              currEle?.role?.toString() !== "admin" ? (
                <span className="user-scope">
                      <img
            src={ExternalIcon|| profilePic}
            alt="External" 
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = profilePic; // Fallback to demo image
            }}
          />
                 
                </span>
              ) : null}
            </div>
            <div className="card-text">
              <>
                <h3>{currEle?.username}</h3>
                {activeStatus ? (
                  activeStatus?.toString() === "online" ? (
                    <p style={{ color: "#87c823" }}>{activeStatus}</p>
                  ) : (
                    <p style={{ color: "#ababab" }}>{activeStatus}</p>
                  )
                ) : (
                  <p style={{ color: "#ababab" }}>No Activity Yet</p>
                )}

                <p>{currEle?.designation}</p>
                <p>{currEle?.email}</p>
                <p>{currEle?.phone}</p>
              </>
            </div>
            <>
              {showChatIcons() &&
                emp_id !== currEle?.id &&
                card_role !== "super_admin" && (
                  <div className="card-icons">
                    <IconButton
                      onClick={() => {
                        if (
                          isConnected === "not-connected" ||
                          isConnected === "pending"
                        )
                          return toast("You are not connected to " + orgName, {
                            type: "info",
                          });
                        navigate("/chat");
                        dispatch(renderChatProfileAction(currEle?.id));
                      }}
                    >
                      <ChatIcon className="chat-icon" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (
                          isConnected === "not-connected" ||
                          isConnected === "pending"
                        )
                          return toast("You are not connected to " + orgName, {
                            type: "info",
                          });
                        setEmailPopup(true);
                      }}
                    >
                      <EmailIcon className="email-icon" />
                    </IconButton>
                    {EmailPopup && (
                      <RenderEmail
                        setEmailPopup={setEmailPopup}
                        toEmail={currEle?.email}
                      />
                    )}
                  </div>
                )}
            </>
          </div>
        ) : (
          <div style={{ marginTop: 0 }} className="org-card-board"></div>
        )}
      </div>

      <RenderEditProfile
        currEle={currEle}
        popup={openEdit}
        hide={setOpenEdit}
        getAllCategories={  getAllCategories}
      />

      {confirmPopup ? (
        <RenderConfirm
          message={` archive ${deleteUserName}`}
          data={{ deleteId, statusType, token }}
          hide={setConfirmPopup}
          status={moveUserToTrash}
        />
      ) : null}
    </>
  );
};

export default Card;
