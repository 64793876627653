import "./ProfileOverview.css";

import { Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { getAllAmniusAdmin, getGeneralInfo } from "Config/API/get";
import {
  renderGeneralInfo,
  renderOrganizationInfo,
} from "Redux/action/render.action";
import { useDispatch, useSelector } from "react-redux";

import AddAmniusAdmin from "Components/Popup/AddAmniusAdmin/AddAmniusAdmin";
import EditIcon from "@mui/icons-material/Edit";
import IosShareIcon from "@mui/icons-material/IosShare";
import LanguageIcon from "@mui/icons-material/Language";
import RenderAddUser from "Components/FileExplorer/AddUser/Container/RenderAddUser";
import RenderCard from "Components/Cards/ProfileCard/Container/RenderProfileCard";
import RenderEditOrganization from "Components/Popup/EditOrganization/Container/RenderEditOrganization";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import TabContent from "../../TabContent/TabContent";
import UserManagementIcon from "Media/team-management (1)@2x.png";
import profilePic from "Media/blank-profile-picture.png";
import { sendImageAPI } from "Config/API/action";
import { toast } from "react-toastify";
import { updateImage } from "Config/API/update";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { useNavigate } from "react-router-dom";

const ProfileOverview = () => {
  const isStorageLimitReached = useIsStorageLimitReached();
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [openAddAmniusAdmin, setOpenAddAmniusAdmin] = React.useState(false);
  const [amniusAdmins, setAmniusAdmins] = React.useState([]);
  const [adminLoading, setAdminLoading] = React.useState(false);
  const role = useSelector((state) => state.authenticationReducer?.role);
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const token = localStorage.getItem("token");
  const isOrgAmnius = useIsAmniusOrganization();
  let general_info = useSelector((state) => state.renderGeneralInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!general_info || general_info?.length === 0) {
    general_info = {};
  }

  useEffect(() => {
    if (!isOrgAmnius) return;
    getAmniusAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAmniusAdmin = () => {
    setAdminLoading(true);
    const getAmniusAdminResponse = getAllAmniusAdmin(token);
    getAmniusAdminResponse.then((res) => {
      setAdminLoading(false);
      if (res?.data?.status) {
        setAmniusAdmins(res?.data?.data);
      }
    });
  };

  // image uploading code
  const uploadImage = (e) => {
    const size = e.target.files[0]?.size;
    if (isStorageLimitReached(size))
      return setShowStorageLimitReachedPopup(true);
    if (size > 10000000) {
      toast("File size excceded 10MB", { type: "info" });
    } else {
      const image_status = sendImageAPI(
        token,
        e.target.files[0],
        `profile/${loggedInUser?.id}`,
        general_info?.logo ? general_info?.logo : ""
      );
      image_status.then((res) => {
        if (res?.status) {
          res?.json().then((response) => {
            if (response?.status) {
              if (response?.message?.includes("limit"))
                return toast(response?.message, {
                  type: "info",
                });
              const image_status = updateImage(response?.data, token);
              image_status
                .then((res) => {
                  if (res?.status) {
                    toast(res?.message, {
                      type: "success",
                    });

                    // re-rendering dashboard
                    const received_info = getGeneralInfo(token);
                    received_info.then((res) => {
                      if (res?.status) {
                        dispatch(renderGeneralInfo(res?.org_info));
                      }
                    });
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                })
                .catch(() =>
                  toast("Failed! Try again later", {
                    type: "error",
                  })
                );
            } else {
              toast(response?.message, {
                type: "error",
              });
            }
          });
        } else {
          toast("Failed! Try again later", {
            type: "error",
          });
        }
      });
    }
  };

  // render profile top
  const renderProfileTop = () => {
    return (
      <div className="profile-header-section profile-flex">
        <div className="profile-head">Organization Details</div>
        <div className="profile-view_all">
          <Button
            variant="contained"
            style={{ background: "#5ca127" }}
            onClick={() => {
              navigate("/organization", {
                state: { data: general_info?.org_id, from: "navigation" },
              });
              dispatch(renderOrganizationInfo({}));
            }}
          >
            View Detail
          </Button>
        </div>
      </div>
    );
  };

  // render profile bio
  console.log(general_info)
  const renderProfileBio = () => {
    return (
      <div className="profile-details">
        <div className="profile-logo">
         
              <img
            src={general_info.logo  || profilePic}
            alt="profile"
            width="133px"
            height="133px"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = profilePic; // Fallback to demo image
            }}
          />
          <Button variant="text" component="label" onChange={uploadImage}>
            Upload Logo
            <input type="file" accept="image/*" hidden />
          </Button>
        </div>
        <div className="profile-bio">
          <div className="profile-text">
            <h3>
              {general_info.organization_name
                ? general_info.organization_name
                : null}
            </h3>

            <div className="profile-url">
              <LanguageIcon />
              <span>
                Website:
                <a
                  href={
                    general_info.organization_website
                      ? general_info.organization_website
                      : null
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {general_info.organization_website
                    ? general_info.organization_website
                    : null}
                </a>
              </span>
              <IosShareIcon />
            </div>
            {general_info?.address?.replaceAll(", ", "")?.length > 0 ? (
              <p>
                {general_info?.street}
                {general_info?.city && `, ${general_info?.city}`}
                {general_info?.state && `, ${general_info?.state}`}
                {general_info?.country && `, ${general_info?.country}`}
                {general_info?.zipcode && `, ${general_info?.zipcode}`}
              </p>
            ) : null}
          </div>
          <div className="profile-edit">
            {role?.toString() === "admin" ||
            role?.toString() === "super_admin" ? (
              <IconButton
                onClick={() => {
                  setOpenEdit(true);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  //render admin details
  const renderAdminDetails = () => {
    return (
      <div className="profile-details">
        <div className="profile-with-card">
          <div className="profile-tabs-wrapper">
            <div className="profile-banner">
              {isOrgAmnius ? "SUPER ADMIN" : "ADMIN"}
            </div>
            {loggedInUser?.role !== "super_admin" && (
              <div
                className="user-management-cta"
                onClick={() => {
                  navigate("/service-ticket-user-management");
                }}
              >
                <img src={UserManagementIcon} alt="Service-Ticket" />
                User Management
              </div>
            )}
          </div>
          <div className="profile-card-wrapper">
            {Object.keys(general_info).length?.toString() !== "0" ? (
              <RenderCard currEle={general_info} />
            ) : null}
          </div>
          {isOrgAmnius && (
            <>
              <div
                className="profile-text-wrapper"
                style={{ marginTop: "10px" }}
              >
                <div className="profile-banner">ADMIN</div>
                <div className="profile-add">
                  {role?.toString() === "super_admin" && (
                    <Button
                      variant="contained"
                      style={{ background: "#5ca127" }}
                      onClick={() => {
                        setOpenAddAmniusAdmin(true);
                      }}
                    >
                      +CREATE ADMIN
                    </Button>
                  )}
                </div>
              </div>
              <div className="profile-card-wrapper">
                {!adminLoading ? (
                  amniusAdmins.length > 0 ? (
                    amniusAdmins.map((item) => {
                      return (
                        <RenderCard
                          currEle={item}
                          role={item?.role}
                          org_name={general_info?.organization_name}
                          isAdmin={true}
                          getAmniusAdmin={getAmniusAdmin}
                        />
                      );
                    })
                  ) : (
                    <p>No Admin Found</p>
                  )
                ) : (
                  <>
                    <div className="org-card-container">
                      <div className="org-card-board"></div>
                    </div>
                    <div className="org-card-container">
                      <div className="org-card-board"></div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  // render teams details
  const renderTeamDetails = () => {
    return (
      <div className="profile-details">
        <div className="profile-with-card">
          <TabContent
            role={role}
            setOpenAddUser={setOpenAddUser}
            general_info={general_info}
            openAddUser={openAddUser}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="profile-container">
        <div className="profile-wrapper">
          <div className="profile-upper">{renderProfileTop()}</div>
          <div className="profile-lower">
            {renderProfileBio()}
            {renderAdminDetails()}
            {renderTeamDetails()}
            {Object.keys(general_info).length?.toString() !== "0" ? (
              <RenderEditOrganization popup={openEdit} hide={setOpenEdit} />
            ) : null}

           
            <AddAmniusAdmin
              popup={openAddAmniusAdmin}
              hide={setOpenAddAmniusAdmin}
            />
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", left: 0 }}>
        {showStorageLimitReachedPopup && (
          <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
        )}
      </div>
    </>
  );
};

export default ProfileOverview;
