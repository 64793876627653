import "./PartDetail.css";
import React, { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import uploadIcon from "Media/icons/awesome-file-upload.svg";
import qrcode from "Media/icons/metro-qrcode.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { assetuniqueid } from "Redux/action/variable.action";
import UserManagementInformationPopup from "Components/Popup/UserManagementInfoPop/PureComponents/UserManagementInformationPopup";
import { useNavigate } from "react-router-dom";
import bFolder from "Media/icons/Folder-Close.png";
import demo from "Media/blank-profile-picture.png";
import webLinkImage from "Media/icons/webLinkImage.png";
import {
  archivePartFolder,
  createPartFolder,
  getAssetPartbyId,
  getGeneralInfo,
  getLocationData,
  getPartFolders,
  getUserDetails,
  listOfAppointOrg,
  listOfServiceTicketUsers,
  renamePartFolder,
} from "Config/API/get";
import useFullPageLoader from "Hooks/useFullPageLoader";
import AssetQrPopup from "Components/Popup/AssetQRPopup/assetQrPopup.jsx";
import { toast } from "react-toastify";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { addPartFile } from "Config/API/action";
import options from "Media/icons/feather-more-vertical.svg";
import { getFileIcon } from "Constants/Functions";
import { updatePart } from "Config/API/update";
import blank from "Media/blank-profile-picture.png";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { partuniqueid } from "Redux/action/variable.action";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import IFramePopup from "Components/Popup/IFramePopup/IFramePopup";
const PartDetail = () => {
  const dropdownRef = useRef({});
  const dispatch = useDispatch();
  const vendorRef = useRef(null);
  const locationRef = useRef(null);
  const navigate = useNavigate();
  const contactRef = useRef(null);
  const [inputClass, setInputClass] = useState("inputfieldclass");
  const [inputModal, setInputModal] = useState("");
  const [qrPopup, setQrPopup] = useState(false);
  const [myPart, setMyPart] = useState(null);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [isEditable, setIsEditable] = useState(false);

  const [vendorContactList, setVendorContactList] = useState([]);
  const handleFocus = () => {
    setInputClass("input-no-border");
  };
  const [assetFolders, setAssetFolders] = useState(null);
  const [vendorOrgList, setVendorOrgList] = useState([]);
  const [showVendorDropdown, setShowVendorDropdown] = useState(false);
  const [showContactDropdown, setShowContactDropdown] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [selectedVendorOrg, setSelectedVendorOrg] = useState("");
  const [vendorOrgId, setVendorOrgId] = useState(false);
  const [editedAsset, setEditedAsset] = useState(null);
  const [parentUniqueId, setParentUniqueId] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [assetUserRole, setAssetUserRole] = useState("");
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderName, setFolderName] = useState("");
  // const [uploadedFiles, setUploadedFiles] = useState([]); // Default to empty array instead of null
  // const [combinedData, setcombinedData] = useState([]);
  const [notAccessable, setNotAccessable] = useState("");
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  const isStorageLimitReached = useIsStorageLimitReached();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [infoHeading, setInfoHeading] = useState("");
  const [tabsText, setTabsText] = useState([[]]);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const token = localStorage.getItem("token");
  const [folderPath, setFolderPath] = useState([0]);
  const [bookmark, setBookmark] = useState(null);
  const [folderType, setFolderType] = useState("");
  const [showIFramePopup, setShowIFramePopup] = useState(false);
  const [url, setUrl] = useState("");
  const [isFolder, setIsFolder] = useState(false);
  const handleBlur = () => {
    setInputClass("");
  };
  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);

  const baseURL = parsedURL.protocol + "//" + parsedURL.hostname;
  const qrCodeUrl = myPart?.sharePath
    ?.toString()
    ?.replace(
      baseURL,
      `${baseURL}/amnius-connect/api/deep-link?url=amniusconnect://app`
    );
  const location = useLocation();
  const { shared } =
    { shared: notAccessable === "shared" ? true : false } || {};
  const uniqueid = useSelector((state) => state?.ticketIdReducer);
  useOutsideAlerter(vendorRef);
  useOutside(contactRef);
  useOutsidelocation(locationRef);
  function useOutsidelocation(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowLocation(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowVendorDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowContactDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    getAssetParts();
    getassetlocation();
    getuserData();
  }, []);

  const getuserData = () => {
    const apiResponse = getUserDetails(token);
    apiResponse.then((res) => {
      setAssetUserRole(res?.data?.data?.asset_manager);
    });
  };
  useEffect(() => {
    if (editedAsset) {
      handleprefillLocation(editedAsset?.inventoryLocation);
    } else {
    }
  }, [editedAsset]); // This effect will run when editedAsset is updated

  const getAssetParts = async () => {
    setLoader();
    try {
      const assetData = await getAssetPartbyId(token, uniqueid);

      if (assetData && assetData?.status) {
        resetLoader();
        setMyPart(assetData?.data?.data[0]);
        setEditedAsset(assetData?.data?.data[0]);
        setNotAccessable(assetData?.data?.data[0]?.qrCode);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      console.error("Error fetching part data:", error);
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  const addAFile = async (uploadFile) => {
    setUploadButtonDisable(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });
    console.log(parentUniqueId);
    try {
      const res = await addPartFile(
        token,
        myPart?.assetId,
        parentUniqueId,
        uniqueid,
        uploadFile,
        { current: toastId }
      );

      if (res?.status) {
        if (res?.message?.includes("limit")) {
          return toast(res?.message, { type: "info" });
        }

        toast.update(toastId, {
          render: "Uploaded!",
          type: "success",
          autoClose: 4000,
        });

        getPartFoldersData();
      } else {
        toast(res?.message, { type: "error" });
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast("Failed! Try again later", { type: "error" });
    } finally {
      setUploadButtonDisable(false);
    }
  };
  const [inventoryData, setInventoryData] = useState(
    () => (myPart && myPart?.inventoryLocation) || []
  );

  const handleQuantityChange = (id, change) => {
    setSelectedLocations((prev) =>
      prev.map((item) =>
        item?.id === id
          ? { ...item, quantity: Math.max(1, item.quantity + change) }
          : item
      )
    );
  };

  const getPartFoldersData = async () => {
    setLoader();
    try {
      const folders = await getPartFolders(token, uniqueid, parentUniqueId);
      console.log("API Response (getPartFoldersData):", folders); // Debugging log

      if (folders?.status) {
        const folderList = folders?.data?.data || []; // Ensure it's an array
        const bookmarkList = folders?.data?.bookmarkData || []; // Ensure bookmarks are available

        if (folderList.length > 0) {
          // Sort only if folders exist
          const sortedFolders = [...folderList]?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAssetFolders(sortedFolders);
        } else {
          setAssetFolders([]); // Set an empty array safely
        }

        setBookmark(bookmarkList);
      } else {
        console.error("Failed to fetch part folders:", folders);
        toast.error("Failed to fetch part folders! Try again later.");
      }
    } catch (error) {
      console.error("Error fetching part folders:", error);
      toast.error("An error occurred while fetching part folders.");
    } finally {
      resetLoader();
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);

    getPartFoldersData();
  };
  const handleDownload = async (curr) => {
    console.log(curr);

    if (curr?.type === "file") {
      const fileName = curr?.name;

      try {
        // Fetch the file as a blob
        const response = await fetch(curr?.path);

        if (!response?.ok) {
          throw new Error(`Failed to fetch file: ${response?.statusText}`);
        }

        const blob = await response?.blob();

        // Create a temporary download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        // Success toast
        toast("Downloading completed", { type: "success" });
      } catch (error) {
        console.error("Error downloading the file:", error);

        // Error toast
        toast("Downloading failed", { type: "error" });
      }
    }
  };

  const handleDelete = async (curr) => {
    setLoader();
    try {
      const response = await archivePartFolder(token, curr?.unique_id);
      if (response && response?.status) {
        getPartFoldersData();
        toast("Part folder Archived successfully", { type: "success" });
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed ! Try again later", { type: "error" });
    } finally {
      resetLoader();
      setDropdownOpenId(null);
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setParentUniqueId(0);
    setDropdownOpenId(null);
  };
  const handleDropdownClick = (id) => {
    setDropdownOpenId(dropdownOpenId === id ? null : id);
  };
  const handleOpen = (curr) => {
    if (curr?.type === "folder") {
      setParentUniqueId(curr?.unique_id);
      setFolderPath((prevItems) => [...prevItems, curr?.unique_id]);
    } else {
      localStorage.setItem("filePath", curr?.path);
      localStorage.setItem(
        "fileType",
        curr?.name?.split(".").pop()?.toLowerCase()
      );
      window.open(
        `${window.location.origin}/file-explorer/file-viewer`,
        "_blank"
      );
    }
    setDropdownOpenId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader();
    setIsSubmitting(true);
    // Trim the values to avoid empty or whitespace-only submissions

    const trimmedVendorContactName = editedAsset?.vendorContact?.trim();
    const trimmedVendorEmail = editedAsset?.vendorEmail?.trim();
    const trimmedDescription = editedAsset?.description?.trim();
    const trimmedVendorName = editedAsset?.vendorName?.trim();
    const trimmedInventoryLocation = selectedLocations?.map((loc) => ({
      inventoryLocation: loc?.id,
      quantity: loc?.quantity,
      locationName: loc?.location,
    }));
    const trimmedVendorPhone = editedAsset?.vendorPhone?.trim();

    if (editedAsset?.partHash !== "") {
      if (trimmedVendorName !== "") {
        if (trimmedDescription !== "") {
          if (trimmedInventoryLocation !== "") {
            if (trimmedVendorContactName !== "") {
              if (trimmedVendorEmail !== "") {
                if (
                  trimmedVendorPhone !== "" &&
                  trimmedVendorPhone?.length >= 9 &&
                  trimmedVendorPhone?.length <= 14
                ) {
                  // Show loading spinner or state

                  // Fetch token from local storage
                  const token = localStorage.getItem("token");

                  try {
                    // First, generate the assetPartId using the token
                    const assetPartData = {
                      partUniqueId: uniqueid,
                      partHash: editedAsset?.partHash,
                      description: trimmedDescription,
                      vendorName: trimmedVendorName,
                      vendorContact: trimmedVendorContactName,
                      vendorEmail: trimmedVendorEmail,
                      vendorPhone: trimmedVendorPhone,
                      inventoryLocation: trimmedInventoryLocation,
                    };

                    // Now, create the asset part using the assetPartData
                    const assetPartResponse = await updatePart(
                      token,
                      assetPartData
                    );

                    resetLoader(); // Hide loader once request is done

                    if (assetPartResponse?.status) {
                      toast(assetPartResponse?.message, {
                        type: "success",
                      });
                      setIsEditable(false);

                      getAssetParts();
                      setIsSubmitting(false);
                      setManualLocationInput(" ");
                    } else {
                      setIsSubmitting(false);

                      toast(assetPartResponse?.message, {
                        type: "error",
                      });
                    }
                  } catch (error) {
                    resetLoader(); // Hide loader in case of error
                    console.error("Error during asset part creation:", error);
                    setIsSubmitting(false);
                    toast("An error occurred, please try again", {
                      type: "error",
                    });
                  }
                } else {
                  setIsSubmitting(false);
                  toast("Vendor phone number must be at least 9 digits", {
                    type: "info",
                  });
                }
              } else {
                setIsSubmitting(false);
                toast("Please enter a vendor email", { type: "info" });
              }
            } else {
              setIsSubmitting(false);
              toast("Please enter a vendor  name", { type: "info" });
            }
          } else {
            setIsSubmitting(false);
            toast("Please enter an inventory location", { type: "info" });
          }
        } else {
          setIsSubmitting(false);
          toast("Please enter a description", { type: "info" });
        }
      } else {
        setIsSubmitting(false);
        toast("Please select a vendor organization", { type: "info" });
      }
    } else {
      setIsSubmitting(false);
      toast("Please enter an asset part", { type: "info" });
    }
  };

  const handleClose = () => {
    getAssetParts();
    setIsEditable(false);
    setManualLocationInput(" ");
  };
  const handleEditClick = () => {
    setIsEditable(true);
  };
  const createNewPartFolder = async () => {
    setLoader();
    let type;
    try {
      // Check if a folder with the same name already exists
      setIsFolder(true);
      if (
        Array.isArray(assetFolders) &&
        assetFolders.some((folder) => folder?.name === folderName)
      ) {
        toast("Folder name already exists", { type: "error" });
        resetLoader();
        return;
      }

      const assetData = await createPartFolder(
        token,
        myPart?.assetId,
        uniqueid,

        folderType,
        url,
        parentUniqueId,
        folderName,
        folderName
      );

      if (assetData && assetData?.status) {
        // If folder creation is successful
        toast("Part Folder created successfully", { type: "success" });
        getPartFoldersData();
        setInputModal("");
        setFolderName("");
        setFolderType("");
        setUrl("");
        setIsFolder(false);
      } else {
        toast("Failed! Try again later", { type: "error" });
        setIsFolder(false);
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
      setIsFolder(false);
    } finally {
      resetLoader();
    }
  };
  const handlefolderRename = async () => {
    setLoader();
    try {
      setIsFolder(true);
      const response = await renamePartFolder(
        token,
        currentFolder?.unique_id,
        folderName,
        folderType,
        url
      );
      if (response && response?.status) {
        getPartFoldersData();
        setFolderName("");
        setUrl("");
        setInputModal("");

        setFolderType("");
        setIsFolder(false);
        toast(response?.message, { type: "success" });
      } else {
        toast(response?.message, { type: "error" });
        setIsFolder(false);
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
      setIsFolder(false);
    } finally {
      resetLoader();
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setEditedAsset((prev) => ({
      ...prev,
      [id]: value, // Dynamically update the field by `id`
    }));
  };
  const [assetLocation, setAssetLocation] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [manualLocationInput, setManualLocationInput] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  useEffect(() => {
    getPartFoldersData();
  }, [parentUniqueId]);
  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res?.location);
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };
  const handleRemoveLocation = (id) => {
    setSelectedLocations(selectedLocations?.filter((item) => item?.id !== id));
  };
  const handleAddManualLocation = (location) => {
    const newLocation = {
      id: location, // Generate a unique ID
      location,
      quantity: 1, // Default quantity
    };
    setSelectedLocations([...selectedLocations, newLocation]);
  };
  const handleAddLocation = (loc) => {
    const exists = selectedLocations.find((item) => item?.id === loc?.id);
    if (!exists) {
      setSelectedLocations([
        ...selectedLocations,
        { id: loc?.id, location: loc?.location, quantity: 1 },
      ]);
    }
  };
  const handleprefillLocation = (locArray) => {
    if (Array.isArray(locArray) && locArray?.length > 0) {
      const newSelectedLocations = locArray?.map((loc) => ({
        id: loc?.inventoryLocation, // Set inventoryLocation as the id
        location: loc?.locationName, // Set locationName as the location
        quantity: parseInt(loc?.quantity), // Ensure quantity is an integer
      }));

      setSelectedLocations(newSelectedLocations);
    } else {
      setSelectedLocations([]); // If the array is empty or undefined, reset the selected locations
    }
  };
  const handleGoBack = () => {
    if (folderPath[folderPath?.length - 1] !== 0) {
      setParentUniqueId(folderPath[folderPath?.length - 2]);
      setFolderPath((prevItems) => prevItems?.slice(0, prevItems?.length - 1));
    }
  };

  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}\/?/;

  const handleChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);

    // Reset error when the user types a new value
    setError("");
  };

  // Validate URL when input loses focus
  const handleBlururl = () => {
    if (url && !urlRegex.test(url)) {
      setError("Invalid URL format");
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Guard against undefined references
      const isClickInsideDropdown = Object.values(dropdownRef.current).some(
        (dropdown) => dropdown && dropdown.contains(event.target) // Check for null dropdown
      );
      const isClickInsideButton = event.target.closest(
        ".dropdown-icon-container"
      );

      if (!isClickInsideDropdown && !isClickInsideButton) {
        setDropdownOpenId(null); // Close the dropdown if the click is outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (notAccessable === "noAccessable") {
      setShowPopup(true);
      setTimeout(() => {
        navigate("/asset-management-view-page");
      }, 3000);
    }
  }, [notAccessable, navigate]);

  return (
    <div className="part-detail-parent">
      <div className="part-detail-header">
        <Headers />
      </div>
      <div className="part-detail-navigation">
        <Navigation />
      </div>
      <div className="part-detail-main">
        <div className="part-detail-left">
          <div className="left-header edit-part-detail">
            Part Details
            {!shared &&
              assetUserRole &&
              (!isEditable ? (
                <button className="edit-button" onClick={handleEditClick}>
                  <CreateIcon /> &nbsp;Edit
                </button>
              ) : (
                <div
                  style={{
                    height: "27px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="submit-part-detail-btn"
                    onClick={handleSubmit}
                    style={{ marginLeft: "10px" }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <IconButton
                    onClick={() => handleClose()}
                    style={{ padding: "6px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
          </div>
          <div className="left-form">
            {myPart ? ( // Assuming 'myPart' is the state that holds part details
              <>
                <div className="part-detail-field">
                  <label htmlFor="partHash">Part #:</label>
                  <input
                    type="text"
                    id="partHash" // Matches key in `editedAsset`
                    value={editedAsset?.partHash || ""}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    maxLength={30}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="description">Part Description:</label>
                  <textarea
                    id="description"
                    value={editedAsset?.description || ""}
                    readOnly={!isEditable}
                    onChange={handleInputChange}
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    rows="4"
                    maxLength={250}
                    style={{ resize: "none" }}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="inventoryLocation">
                    Inventory Location(s):
                  </label>

                  <div
                    ref={locationRef}
                    className="vendor-wrapper edit-vendor-wrapper"
                  >
                    {/* Show selected locations as chips */}
                    <div className="selected-locations-wrapper">
                      {selectedLocations?.map((loc) => (
                        <div
                          className="location-chip-with-quantity"
                          key={loc?.id}
                        >
                          <div className="location-chip">
                            {isEditable && (
                              <button
                                type="button"
                                className="chip-remove-btn"
                                onClick={() => handleRemoveLocation(loc?.id)} // Allow users to remove locations
                                disabled={!isEditable} // Only allow removal when editing is enabled
                              >
                                ✖
                              </button>
                            )}

                            <span
                              className="chip-text"
                              style={{ color: "#446fa2" }}
                            >
                              {loc?.location} {/* Display the location name */}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    <input
                      type="text"
                      id="inventoryLocation"
                      value={manualLocationInput}
                      maxLength={30}
                      onChange={(e) => {
                        const value = e.target.value.trim();

                        // Update input state
                        setManualLocationInput(value);

                        if (value.length > 0) {
                          // Check if location already selected
                          const isLocationSelected = selectedLocations?.some(
                            (loc) =>
                              loc?.location?.toLowerCase() ===
                              value.toLowerCase()
                          );

                          // Fetch and filter asset locations
                          if (!isLocationSelected) {
                            getassetlocation();
                            const filtered = assetLocation?.filter(
                              (loc) =>
                                loc?.location
                                  .toLowerCase()
                                  .includes(value.toLowerCase()) &&
                                !selectedLocations.some(
                                  (selectedLoc) =>
                                    selectedLoc?.location === loc?.location
                                )
                            );

                            setFilteredLocations(filtered);
                            setShowLocation(true);

                            // Automatically select if exact match is found
                            const exactMatch = filtered?.find(
                              (loc) =>
                                loc?.location?.toLowerCase() ===
                                value?.toLowerCase()
                            );
                            if (exactMatch) {
                              handleAddLocation(exactMatch);
                              setManualLocationInput(""); // Clear input after adding
                              setShowLocation(false); // Hide dropdown
                            }
                          } else {
                            setShowLocation(false); // Hide dropdown if location already selected
                          }
                        } else {
                          setShowLocation(false); // Hide dropdown if input is cleared
                          setFilteredLocations([]); // Clear filtered locations
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && manualLocationInput.trim()) {
                          e.preventDefault(); // Prevent form submission

                          // Check if location is already selected
                          const isLocationSelected = selectedLocations.some(
                            (loc) =>
                              loc?.location?.toLowerCase() ===
                              manualLocationInput?.toLowerCase()
                          );

                          if (!isLocationSelected) {
                            handleAddManualLocation(manualLocationInput); // Add manual location
                            setManualLocationInput(""); // Clear input after adding
                            setShowLocation(false); // Hide dropdown
                          }
                        }
                      }}
                      readOnly={!isEditable}
                      className={inputClass}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />

                    {/* Dropdown for location suggestions */}
                    {showLocation && (
                      <div className="dropdown-list">
                        {filteredLocations &&
                          filteredLocations.length > 0 &&
                          filteredLocations.map((loc) => (
                            <div
                              className="dropdown-item"
                              key={loc?.id}
                              onClick={() => {
                                handleAddLocation(loc); // Add location to selected locations
                                setShowLocation(false); // Hide dropdown
                                setManualLocationInput(""); // Clear input
                              }}
                            >
                              <span
                                className="dropdown-text"
                                style={{ color: "#446fa2" }}
                              >
                                {loc?.location || "No Location"}{" "}
                                {/* Show location name */}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="part-detail-field">
                  <label htmlFor="vendorName">Vendor Org:</label>
                  <div className="part-detail-vendor-wrapper" ref={vendorRef}>
                    <input
                      type="text"
                      id="vendorName"
                      maxLength={30}
                      readOnly={!isEditable}
                      value={editedAsset?.vendorName || " "}
                      onChange={(e) => {
                        const value = e.target.value;
                        setVendorName(value); // Update the vendor name in state
                        // Only fetch vendor list if the input is not empty
                        setEditedAsset({
                          ...editedAsset,
                          vendorName: value, // Update edited asset with the modified value
                        });
                        if (value.length > 0) {
                          const vendor_list = listOfAppointOrg(token, value);
                          vendor_list.then((res) => {
                            if (res?.status) {
                              setVendorOrgList(res?.organizationData); // Set fetched vendor list
                              setShowVendorDropdown(true); // Show dropdown only if there are results
                            } else {
                              toast(res?.message, { type: "error" });
                              setShowVendorDropdown(false);
                            }
                          });
                        } else {
                          setShowVendorDropdown(false);
                        }
                      }}
                      onClick={() => {
                        setVendorOrgList("");
                      }}
                      className={inputClass}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />

                    {showVendorDropdown && vendorOrgList.length > 0 && (
                      <div className="vendor-organization-list">
                        {vendorOrgList?.length > 0
                          ? vendorOrgList.map((curr, index) => (
                              <div className="data-list" key={index}>
                                <div>
                                  <img
                                    src={curr?.logo || blank}
                                    alt="Vendor Logo"
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop
                                      e.target.src = blank; // Fallback to demo image
                                    }}
                                  />
                                  <span style={{ color: "#446FA2" }}>
                                    {curr?.organization_name || "No Name"}
                                  </span>
                                </div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    setEditedAsset({
                                      ...editedAsset,
                                      vendorName: curr?.organization_name,
                                    });
                                    setSelectedVendorOrg(curr?.id); // Set selected vendor ID
                                    setVendorOrgId(true);
                                    setVendorName(curr?.organization_name); // Set vendor name in input
                                    setVendorOrgList([]); // Clear the vendor list after selection
                                    setShowVendorDropdown(false); // Close the dropdown
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            ))
                          : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="part-detail-field">
                  <label htmlFor="vendorContact">Vendor Name:</label>
                  <div className="part-detail-vendor-wrapper">
                    <input
                      type="text"
                      id="vendorContact"
                      maxLength={30}
                      value={editedAsset?.vendorContact || ""}
                      readOnly={!isEditable}
                      onClick={() => {
                        if (selectedVendorOrg && selectedVendorOrg !== "") {
                          const vendor_list = listOfServiceTicketUsers(
                            token,
                            selectedVendorOrg,
                            ""
                          );
                          vendor_list.then((res) => {
                            if (res?.status) {
                              setVendorContactList(res?.data); // Update the contact list
                              setShowContactDropdown(true); // Show dropdown
                            } else {
                              toast(res?.message, { type: "error" });
                              setShowContactDropdown(false); // Hide dropdown if no data
                            }
                          });
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value.trim(); // Trim spaces
                        setEditedAsset({
                          ...editedAsset,
                          vendorContact: value,
                        });

                        // Check if selectedVendorOrg is valid (not empty, null, or undefined) before making the API call
                        if (
                          selectedVendorOrg &&
                          selectedVendorOrg !== "" &&
                          value.length > 0
                        ) {
                          const vendor_list = listOfServiceTicketUsers(
                            token,
                            selectedVendorOrg,
                            value
                          );
                          vendor_list.then((res) => {
                            if (res?.status) {
                              setVendorContactList(res?.data); // Update the contact list
                              setShowContactDropdown(true); // Show dropdown
                            } else {
                              toast(res?.message, { type: "error" });
                              setShowContactDropdown(false); // Hide dropdown if no data
                            }
                          });
                        } else {
                          setShowContactDropdown(false); // Hide dropdown if no selectedVendorOrg or value is empty
                        }
                      }}
                      className={inputClass}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />

                    {/* Vendor contact dropdown */}
                    {showContactDropdown && vendorContactList.length > 0 && (
                      <div
                        className="vendor-organization-list"
                        ref={contactRef}
                      >
                        {vendorContactList.map((curr, index) => (
                          <div className="data-list" key={index}>
                            <div>
                            
                              <img
                                    src={curr?.image || blank}
                                       alt="Vendor Contact"
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop
                                      e.target.src = blank; // Fallback to demo image
                                    }}
                                  />
                              <span style={{ color: "#446FA2" }}>
                                {curr?.name || "No Name"}
                              </span>
                            </div>
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                setEditedAsset({
                                  ...editedAsset,
                                  vendorContact: curr?.name,
                                  vendorEmail: curr?.email,
                                  vendorPhone: curr?.phone,
                                });
                                setShowContactDropdown(false);
                                setVendorContactList([]);
                              }}
                            >
                              Select
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="part-detail-field">
                  <label htmlFor="vendorEmail">Vendor Email:</label>
                  <input
                    type="email"
                    id="vendorEmail"
                    maxLength={40}
                    value={editedAsset?.vendorEmail || ""}
                    readOnly={!isEditable}
                    onChange={handleInputChange}
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="vendorPhone">Vendor Phone:</label>
                  <input
                    type="text"
                    id="vendorPhone"
                    value={editedAsset?.vendorPhone || ""}
                    readOnly={!isEditable}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,14}$/.test(inputValue)) {
                        setEditedAsset((prev) => ({
                          ...prev,
                          vendorPhone: inputValue,
                        }));
                      }
                    }}
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
              </>
            ) : (
              <div className="part-detail-field">No parts available.</div>
            )}
          </div>
          <div className="left-footer">
            <p onClick={() => setQrPopup(true)}>
              <img src={qrcode} width="21px" alt="Create QR Code" />
              <span>Create QR Code</span>
            </p>
            {qrPopup ? (
              <>
                <AssetQrPopup
                  orglogo={myPart?.orgDetail?.logo}
                  partName={myPart?.partHash}
                  hide={setQrPopup}
                  path={qrCodeUrl}
                  demologo={
                    myPart?.orgDetail?.logo ? myPart?.orgDetail?.logo : demo
                  }
                />
              </>
            ) : null}
            <p>
              <label htmlFor="browse files" onClick={handleOpenModal}>
                <img src={uploadIcon} width="17px" alt="Part Files" />
                <span>Part Files</span>
              </label>
            </p>
            <Modal
              open={inputModal?.length > 0}
              onClose={() => {
                setFolderName("");
                setFolderType("");
                setError("");
                setUrl("");
                setInputModal("");
              }}
            >
              <div className="modal-content-input">
                {(inputModal === "folder" || inputModal === "rename") && (
                  <input
                    type="text"
                    value={folderName}
                    maxLength={50}
                    style={{
                      fontSize: "14px",
                      marginRight: "12px",
                      width: "100%",
                    }}
                    placeholder="Enter folder name"
                    onChange={(e) => {
                      setFolderName(e.target.value);
                      setFolderType("folder");
                    }}
                  />
                )}
                {/* Link Name Input */}
                {(inputModal === "Weblink" ||
                  inputModal === "renameweblink") && (
                  <>
                    {/* Folder Name Input */}
                    <input
                      type="text"
                      value={folderName}
                      maxLength={50}
                      style={{
                        fontSize: "14px",
                        marginRight: "12px",
                        width: "100%",
                        borderColor: error && !folderName?.trim() ? "red" : "", // Red border for empty folder name
                      }}
                      placeholder="Enter link name"
                      onChange={(e) => {
                        setFolderName(e.target.value);
                        setFolderType("weblink");
                      }}
                    />
                    {error && !folderName?.trim() && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          margin: "0",
                          padding: "2px",
                        }}
                      >
                        Please enter a link name.
                      </p>
                    )}

                    {/* URL Input */}
                    <input
                      ref={inputRef}
                      type="text"
                      value={url}
                      style={{
                        fontSize: "14px",
                        marginRight: "12px",
                        width: "100%",
                        borderColor:
                          error && (!url || !urlRegex.test(url)) ? "red" : "", // Red border for invalid URL
                      }}
                      placeholder="Enter URL"
                      onBlur={handleBlururl}
                      onChange={handleChange}
                    />
                    {error && (!url || !urlRegex.test(url)) && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          margin: "0",
                          padding: "2px",
                        }}
                      >
                        Invalid URL format.
                      </p>
                    )}
                  </>
                )}

                <button
                  onClick={() => {
                    if (!folderName?.trim()) {
                      setError("Please enter a link name.");
                      return;
                    }

                    if (
                      inputModal === "Weblink" ||
                      inputModal === "renameweblink"
                    ) {
                      if (!url || !urlRegex.test(url)) {
                        setError("Invalid URL format");
                        return;
                      }
                    }

                    // If validation passes, clear the error and submit
                    setError("");
                    // setInputModal("");

                    if (inputModal === "folder") {
                      createNewPartFolder();
                    } else if (inputModal === "renameweblink") {
                      handlefolderRename();
                    } else if (inputModal === "rename") {
                      handlefolderRename();
                    } else if (inputModal === "Weblink") {
                      createNewPartFolder();
                    }
                  }}
                  style={{ alignSelf: "center" }}
                  className="service-request"
                  disabled={isFolder}
                >
                  Submit
                </button>
              </div>
            </Modal>
            {/* Modal for File Upload */}
            <Modal open={isModalOpen} onClose={handleCloseModal}>
              <div className="modal-content">
                {/* Close Button */}
                <div className="folders-close">
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                  </IconButton>
                </div>

                {/* Modal Header with Upload Button */}
                <div className="modal-header">
                  <h2>Uploaded Files</h2>

                  <input
                    type="file"
                    id="uploadFile"
                    maxLength={50}
                    hidden
                    disabled={uploadButtonDisable}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (!file) return;

                      const size = file.size;
                      if (isStorageLimitReached(size)) {
                        setShowStorageLimitReachedPopup(true);
                        return;
                      }

                      addAFile(file);
                      e.target.value = ""; // Clear the input field after selecting file
                    }}
                    style={{
                      pointerEvents: uploadButtonDisable ? "none" : "auto",
                    }}
                  />
                </div>
                <div className="modal-header">
                  {!shared && assetUserRole && (
                    <div className="filesfolders">
                      <button
                        onClick={() => setInputModal("folder")}
                        className="create-folder"
                      >
                        Create Folder
                      </button>

                      <label htmlFor="uploadFile" className="upload-file-btn">
                        <img src={uploadIcon} width="15px" alt="Add file" />
                        <p>Upload file</p>
                      </label>
                      <button
                        onClick={() => setInputModal("Weblink")}
                        className="create-folder"
                      >
                        Add WebLink
                      </button>
                    </div>
                  )}
                </div>
                <div className="top-button-container">
                  <div className="bookmark-asset">
                    {Array.isArray(bookmark) && bookmark?.length > 0 && (
                      <div className="breadcrumb">
                        {/* Display the root folder (first item in the array) */}
                        {bookmark
                          .filter((item) => item?.parent_unique_id === "0")
                          .map((folder, index) => (
                            <span key={folder?.unique_id}>
                              {/* Always show the root folder */}
                              <span
                                style={{
                                  cursor: "pointer",
                                  padding: "10px",
                                  color: "#446fa2",
                                }}
                                onClick={() => {
                                  setParentUniqueId(folder?.unique_id);
                                }}
                              >
                                {folder?.name}
                              </span>
                              {index < bookmark?.length - 1 && " > "}
                            </span>
                          ))}

                        {bookmark
                          .filter((item) => item?.parent_unique_id !== "0")
                          .map((folder) => (
                            <span key={folder?.unique_id}>
                              {" > "}
                              <span
                                style={{
                                  cursor: "pointer",
                                  padding: "5px",
                                  color: "#446fa2",
                                }}
                                onClick={() => {
                                  // Set selected path to clicked folder name
                                  setParentUniqueId(folder?.unique_id);
                                }}
                              >
                                {folder?.name}
                              </span>
                            </span>
                          ))}
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    id="uploadFile"
                    maxLength={50}
                    hidden
                    disabled={uploadButtonDisable}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (!file) return;
                      const size = file?.size;
                      if (isStorageLimitReached(size)) {
                        setShowStorageLimitReachedPopup(true);
                        return;
                      }
                      addAFile(file);
                      e.target.value = "";
                    }}
                    style={{
                      pointerEvents: uploadButtonDisable ? "none" : "auto",
                    }}
                  />
                </div>
                {/* Modal Body (Displaying Uploaded Files) */}
                <div className="modal-body-files">
                  {assetFolders && assetFolders.length > 0 ? (
                    <div className="asset_single_files">
                      {assetFolders?.map((curr, index) => (
                        <div
                          onClick={() => {
                            curr?.type !== "folder"
                              ? handleDropdownClick(curr?.unique_id)
                              : handleOpen(curr);
                          }}
                          ref={(el) =>
                            (dropdownRef.current[curr?.unique_id] = el)
                          }
                          className="assets-container-files"
                          key={index}
                        >
                          <div className="assets-container">
                            <div
                              className="dropdown-icon-container"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDropdownClick(curr?.unique_id);
                              }}
                            >
                              <img
                                src={options}
                                alt="Menu options"
                                className="dropdown-icon"
                              />

                              {/* Dropdown Menu */}
                              {dropdownOpenId === curr?.unique_id && (
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  className="dropdown-menu dropdown-menu-width"
                                >
                                  {curr?.type !== "folder" && (
                                    <button
                                      className="btn-dropdown"
                                      onClick={() => {
                                        if (curr?.type === "weblink") {
                                          setShowIFramePopup(true); // Show the iframe popup
                                        } else {
                                          handleOpen(curr); // Handle other types
                                        }
                                      }}
                                    >
                                      Open
                                    </button>
                                  )}
                                  {showIFramePopup && (
                                    <IFramePopup
                                      hide={setShowIFramePopup}
                                      filePath={curr?.path}
                                      org_id={curr?.org_id}
                                    />
                                  )}
                                  {assetUserRole && !shared && (
                                    <button
                                      className="btn-dropdown"
                                      onClick={() => {
                                        setFolderName(
                                          curr?.name?.includes(".")
                                            ? curr?.name
                                                .split(".")
                                                .slice(0, -1)
                                                .join(".")
                                            : curr?.name
                                        );
                                        setCurrentFolder(curr);
                                        if (curr?.type === "weblink") {
                                          setUrl(curr?.path);
                                          setInputModal("renameweblink");
                                        } else {
                                          setInputModal("rename");
                                        }

                                        setDropdownOpenId(null);
                                      }}
                                    >
                                      Rename
                                    </button>
                                  )}
                                  {assetUserRole && !shared && (
                                    <button
                                      className="btn-dropdown"
                                      onClick={() => {
                                        setConfirmPopup(true);
                                        setSelectedAssetId(curr);
                                      }}
                                    >
                                      Archive
                                    </button>
                                  )}
                                  {confirmPopup && (
                                    <RenderConfirm
                                      message={`Archive it?`}
                                      data={selectedAssetId}
                                      hide={setConfirmPopup}
                                      status={handleDelete}
                                    />
                                  )}
                                  {curr?.type === "file" && (
                                    <button
                                      className="btn-dropdown"
                                      onClick={() => handleDownload(curr)}
                                    >
                                      Download
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>

                            <div className="asset-item">
                              <div className="asset-icon">
                                {curr?.type === "folder" ? (
                                  <img src={bFolder} alt="Folder Icon" />
                                ) : curr?.type === "weblink" ? (
                                  <img src={webLinkImage} alt="Folder Icon" />
                                ) : (
                                  <img
                                    src={getFileIcon(curr)}
                                    alt="File Icon"
                                  />
                                )}
                              </div>
                              <div className="asset-name">
                                {curr?.name ? curr?.name : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p style={{ padding: "0 30px" }}>No files uploaded.</p>
                  )}
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className="part-detail-right">
          <div className="right-insider" style={{ marginBottom: "20px" }}>
            <p>Assets Linked</p>
            <div
              className="linked-asset-header"
              style={{
                display: "flex",
                fontWeight: "bold",
                padding: "10px 0 0 0",
              }}
            >
              <div
                className="header-box"
                style={{ width: "33.4%", textAlign: "center" }}
              >
                ASSET#
              </div>
              <div
                className="header-box"
                style={{ width: "33.4%", textAlign: "center" }}
              >
                ASSET NAME
              </div>
              <div
                className="header-box"
                style={{ width: "33.4%", textAlign: "center" }}
              >
                LOCATION
              </div>
            </div>
            <div className="linked-asset-content">
              {myPart && myPart?.relatedAssets?.length > 0 ? (
                myPart?.relatedAssets.map((asset) => (
                  <div
                    key={asset?.id}
                    className="linked-asset-row"
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div
                      className="row-box"
                      style={{
                        width: "33.4%",
                        textAlign: "center",
                        borderRight: "1px solid #ccc",
                        padding: "6px 4px",
                        fontSize: "15px",
                        color: "rgb(68, 111, 162)",
                      }}
                      onClick={() => {
                        dispatch(assetuniqueid(asset?.unique_id));
                        navigate("/asset-details", {
                          state: { isShared: shared },
                        });
                      }}
                    >
                      {asset?.assetNumber.length > 20
                        ? asset?.assetNumber.substring(0, 20) + "..."
                        : asset?.assetNumber}
                    </div>
                    <div
                      className="row-box"
                      style={{
                        width: "33.4%",
                        textAlign: "center",
                        borderRight: "1px solid #ccc",
                        fontSize: "15px",
                        padding: "6px 4px",
                      }}
                    >
                      {asset?.assetsName.length > 20
                        ? asset?.assetsName.substring(0, 20) + "..."
                        : asset?.assetsName}
                    </div>
                    <div
                      className="row-box"
                      style={{
                        width: "33.4%",
                        textAlign: "center",
                        fontSize: "15px",
                        borderRight: "1px solid #ccc",
                        padding: "6px 4px",
                      }}
                    >
                      {asset?.assetLocation.length > 20
                        ? asset?.assetLocation.substring(0, 20) + "..."
                        : asset?.assetLocation}
                    </div>
                  </div>
                ))
              ) : (
                <p>No assets linked</p>
              )}
            </div>
          </div>

          <div className="right-insider">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="right-insider-inventory"
            >
              <p>Inventory On Hand</p>
              <QuestionMarkIcon
                style={{
                  backgroundColor: "#446fa2",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  color: "white",
                  padding: "4px",
                }}
                onClick={() => {
                  setInfoPopup(true);
                  setInfoHeading("Inventory Management");
                  setTabsText([
                    [
                      "Inventory Management",
                      "Ability to manage, edit, and delete inventory for specific locations. Click the edit button to modify details, update inventory quantities, or remove items.",
                    ],
                  ]);
                }}
              />
            </div>

            {myPart && (
              <div className="inventory-content">
                {selectedLocations && selectedLocations.length > 0
                  ? selectedLocations?.map((item, index) => (
                      <div key={index} className="inventory-content-box">
                        <div style={{ display: "flex" }}>
                          {isEditable && (
                            <button
                              type="button"
                              className="chip-remove-btn"
                              style={{ fontSize: "15px" }}
                              onClick={() => handleRemoveLocation(item?.id)} // Allow users to remove locations
                            >
                              ✖
                            </button>
                          )}

                          <p>{item?.location}</p>
                        </div>

                        <div className="inventory-quantity">
                          {/* Decrease quantity button */}
                          {isEditable && (
                            <RemoveCircleOutlineIcon
                              onClick={(e) => {
                                if (isEditable && item?.quantity > 1) {
                                  handleQuantityChange(item?.id, -1); // Decrease quantity only if editable and quantity > 1
                                }
                              }}
                              style={{
                                cursor:
                                  isEditable && item?.quantity > 1
                                    ? "pointer"
                                    : "not-allowed", // Only allow if editable and quantity > 1
                              }}
                              disabled={item?.quantity === 1}
                            />
                          )}

                          {/* Quantity value display */}
                          <span
                            className="quantity-value"
                            style={{
                              color: "#446fa2",
                              fontSize: "16px",
                              padding: "0 20px",
                            }}
                          >
                            {item?.quantity}
                          </span>

                          {/* Increase quantity button */}
                          {isEditable && (
                            <ControlPointIcon
                              onClick={(e) => {
                                if (isEditable) {
                                  handleQuantityChange(item?.id, 1); // Increase quantity only if editable
                                }
                              }}
                              style={{
                                cursor: isEditable ? "pointer" : "not-allowed",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            )}
          </div>
        </div>
      </div>
      {infoPopup ? (
        <UserManagementInformationPopup
          message={tabsText}
          hide={setInfoPopup}
          infoHeading={infoHeading}
        />
      ) : null}
      {showPopup && (
        <div className="popupStyle">
          <div className="messagestyling">
            <p>You don't have access to this asset</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartDetail;
