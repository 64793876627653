import "./CreateExternalChatRoom.css";

import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import DoneIcon from "@mui/icons-material/Done";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import blank from "Media/blank-profile-picture.png";
import { createARoom } from "Config/API/action";
import star from "Media/icons/star.png";
import { statusOfRoom } from "Config/API/get";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreateExternalChatRoom = ({
  selectedTeamMember,
  setSelectedTeamMember,
  popup,
  hide,
  availableMember,
  hideChatHandling,
  allExternalMember,
  externalMember,
  setExternalMember,
  externalProfile,
  videoCallCreatorAdmin,
  isBillable,
  toast,
  billingRate,
}) => {
  const userToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [externalInputValue, setExternalInputValue] = useState("");
  const [show, setShow] = useState(false);
  const [externalShow, setExternalShow] = useState(false);
  const [visibleMember, setVisibleMember] = useState([]);
  const [visibleExternalMember, setVisibleExternalMember] = useState([]);

  const onCreateClick = async () => {
    const role = "host";
    const conn_id = isBillable === "true" ? externalProfile?.id : "";
    const allInternalIds = selectedTeamMember.map((item) => {
      return item?.id;
    });
    const allExternalIds = externalMember.map((item) => {
      return item?.id;
    });
    const allIds = allInternalIds?.concat(allExternalIds);
    const allIdsAsc = await allIds?.sort((a, b) => {
      return a - b;
    });
    const uniqueId = allIdsAsc?.join("-");
    const createRoom = createARoom(
      userToken,
      uniqueId,
      isBillable,
      conn_id,
      billingRate,
      "true"
    );
    createRoom
      .then((res) => {
        if (res?.status) {
          const token = res?.data?.msToken;
          const id = res?.data?.id;
          const name = res?.data?.name;
          const room_status = statusOfRoom(userToken, token, id);
          room_status.then((res) => {
            if (res?.status) {
              if (res?.data.enabled) {
                navigate("/video-chat");
                dispatch(
                  storeVideoChatLinkAction({
                    token,
                    id,
                    name,
                    role,
                    from: "videoChat",
                  })
                );
              } else {
                toast("Link is expired! Create a new Video Chat", {
                  type: "error",
                });
              }
            } else {
              toast("Link is expired! Create a new Video Chat", {
                type: "error",
              });
            }
          });
        } else
          toast(res?.message, {
            type: "info",
          });
      })
      .catch((error) => {
        toast(error, {
          type: "error",
        });
      });
    setSelectedTeamMember([]);
    setExternalMember([]);
    setVisibleMember(availableMember);
    setVisibleExternalMember(allExternalMember);
    setInputValue("");
    setExternalInputValue("");
    setShow(false);
    setExternalShow(false);
    hide(false);
    hideChatHandling(false);
  };

  const closeDialog = () => {
    setSelectedTeamMember([]);
    setExternalMember([]);
    setVisibleMember(availableMember);
    setVisibleExternalMember(allExternalMember);
    setInputValue("");
    setExternalInputValue("");
    setShow(false);
    setExternalShow(false);
    hide(false);
    hideChatHandling(false);
  };

  const searchMember = (e) => {
    setInputValue(e.target.value);
    const array = availableMember?.filter((item) => {
      if (
        item?.name
          ?.toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      ) {
        return item;
      }
    });
    setVisibleMember(array);
  };

  const searchExternalMember = (e) => {
    setExternalInputValue(e.target.value);
    const array = allExternalMember?.filter((item) => {
      if (
        item?.name
          ?.toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      ) {
        return item;
      }
    });
    setVisibleExternalMember(array);
  };

  const addTeamMember = (item) => {
    const allIds = selectedTeamMember.map((element) => {
      return element?.id;
    });
    if (!allIds?.includes(item?.id)) {
      setSelectedTeamMember([...selectedTeamMember, item]);
    }
  };

  const removeMember = (item) => {
    let newArray = selectedTeamMember;
    const index = selectedTeamMember?.indexOf(item);
    newArray?.splice(index, 1);
    setSelectedTeamMember([...newArray]);
  };

  const addExternalTeamMember = (item) => {
    const allIds = externalMember.map((element) => {
      return element?.id;
    });
    if (!allIds?.includes(item?.id)) {
      setExternalMember([...externalMember, item]);
    }
  };

  const removeExternalMember = (item) => {
    let newArray = externalMember;
    const index = externalMember?.indexOf(item);
    newArray?.splice(index, 1);
    setExternalMember([...newArray]);
  };

  const checkStatusButton = (item) => {
    const allIds = selectedTeamMember.map((element) => {
      return element?.id;
    });
    return allIds?.includes(item?.id) ? (
      <DoneIcon color="success" fontSize="medium" style={{ marginRight: 7 }} />
    ) : (
      <button
        className="create-chat-room-close-button"
        style={{ marginRight: 3 }}
        onClick={() => addTeamMember(item)}
      >
        <PersonAddIcon color="info" fontSize="medium" />
      </button>
    );
  };

  const checkExternalStatusButton = (item) => {
    const allIds = externalMember.map((element) => {
      return element?.id;
    });
    return allIds?.includes(item?.id) ? (
      <DoneIcon color="success" fontSize="medium" style={{ marginRight: 7 }} />
    ) : (
      <button
        className="create-chat-room-close-button"
        style={{ marginRight: 3 }}
        onClick={() => addExternalTeamMember(item)}
      >
        <PersonAddIcon color="info" fontSize="medium" />
      </button>
    );
  };

  const onInputClick = () => {
    setShow(true);
    setExternalShow(false);
    if (inputValue?.length?.toString() === "0") {
      setVisibleMember(availableMember);
    }
  };

  const onExternalInputClick = () => {
    setShow(false);
    setExternalShow(true);
    if (externalInputValue?.length?.toString() === "0") {
      setVisibleExternalMember(allExternalMember);
    }
  };

  return popup ? (
    <div className="create-chat-room-container">
      <div className="create-external-chat-room-dialog">
        <div className="create-external-chat-room-icon">
          <button
            className="create-chat-room-close-button"
            onClick={() => closeDialog()}
          >
            <CloseIcon fontSize="small" color="black" />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>
            You Are Creating A Chatroom With{" "}
            <span style={{ color: "#4973A3" }}>
              {externalProfile?.organization_name}
            </span>
          </span>
          <span style={{ marginTop: 0.5 }}>
            Add Additional Users If Necessary
          </span>
        </div>
        <div className="create-chat-room-inside-container">
          <div className="create-chat-room-inside-content">
            <div className="create-external-chat-room-image">
           
                  <img
            src={videoCallCreatorAdmin[0]?.logo  || blank}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
            </div>
            <span style={{ marginTop: 1 }}>
              {videoCallCreatorAdmin[0]?.organization_name}
            </span>
            <div style={{ position: "relative" }}>
              <input
                placeholder="Enter Name"
                type="search"
                value={inputValue}
                onChange={(e) => searchMember(e)}
                name="nameSearch"
                id="nameSearch"
                onClick={() => onInputClick()}
                autoComplete="off"
              />
              {show && (
                <div className="create-chat-room-select-members-container">
                  {visibleMember?.length > 0 ? (
                    visibleMember?.map((item, index) => {
                      return (
                        <div className="create-chat-room-select-members">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="create-chat-room-select-members-content">
                              <div style={{ position: "relative" }}>
                              
                                <img
            src={item?.image || blank}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
                                {item?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    className="create-chat-room-online-icon"
                                    color="success"
                                    fontSize="small"
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    className="create-chat-room-online-icon"
                                    color="error"
                                    fontSize="small"
                                  />
                                )}
                              </div>
                              {item?.name}
                            </div>
                            {checkStatusButton(item)}
                          </div>
                          <Divider style={{ marginTop: 2 }} />
                        </div>
                      );
                    })
                  ) : (
                    <div className="create-chat-room-select-no-members">
                      No Member Found
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="create-chat-room-members-container">
              {selectedTeamMember.map((item) => {
                {
                  return (
                    <div className="create-chat-room-members">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="create-chat-room-members-content">
                          <div style={{ position: "relative" }}>
                            
                                 
                            <img
            src={item?.image || blank}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
                            {item?.online_status?.toString() === "online" ? (
                              <FiberManualRecordIcon
                                className="create-chat-room-online-icon"
                                color="success"
                                fontSize="small"
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className="create-chat-room-online-icon"
                                color="error"
                                fontSize="small"
                              />
                            )}
                          </div>
                          {item?.name}
                        </div>
                        {item?.isCreator?.toString() === "true" && (
                          <img
                            src={star}
                            style={{ height: 20, width: 20, marginRight: 8 }}
                          />
                        )}
                        {item?.isPermanent?.toString() === "true" ? null : (
                          <button
                            onClick={() => removeMember(item)}
                            className="create-chat-room-select-members-content"
                          >
                            <DeleteIcon color="error" fontSize="medium" />
                          </button>
                        )}
                      </div>
                      <Divider style={{ marginTop: 4 }} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="create-chat-room-inside-content">
            <div className="create-external-chat-room-image">
             
                   <img
            src={externalProfile?.logo  || blank}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
            </div>
            <span style={{ marginTop: 1 }}>
              {externalProfile?.organization_name}
            </span>
            <div style={{ position: "relative" }}>
              <input
                placeholder="Enter Name"
                type="search"
                value={externalInputValue}
                onChange={(e) => searchExternalMember(e)}
                name="nameSearchExternal"
                id="nameSearchExternal"
                onClick={() => onExternalInputClick()}
                autoComplete="off"
              />
              {externalShow && (
                <div className="create-chat-room-select-members-container">
                  {visibleExternalMember?.length > 0 ? (
                    visibleExternalMember?.map((item, index) => {
                      return (
                        <div className="create-chat-room-select-members">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="create-chat-room-select-members-content">
                              <div style={{ position: "relative" }}>
                                
                                
                   <img
            src={item?.image || blank}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
                                {item?.online_status?.toString() ===
                                "online" ? (
                                  <FiberManualRecordIcon
                                    className="create-chat-room-online-icon"
                                    color="success"
                                    fontSize="small"
                                  />
                                ) : (
                                  <FiberManualRecordIcon
                                    className="create-chat-room-online-icon"
                                    color="error"
                                    fontSize="small"
                                  />
                                )}
                              </div>
                              {item?.name}
                            </div>
                            {checkExternalStatusButton(item)}
                          </div>
                          <Divider style={{ marginTop: 2 }} />
                        </div>
                      );
                    })
                  ) : (
                    <div className="create-chat-room-select-no-members">
                      No Member Found
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="create-chat-room-members-container">
              {externalMember.map((item) => {
                {
                  return (
                    <div className="create-chat-room-members">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="create-chat-room-members-content">
                          <div style={{ position: "relative" }}>
                          <img
            src={item?.image || blank}
            alt="logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
                            {item?.online_status?.toString() === "online" ? (
                              <FiberManualRecordIcon
                                className="create-chat-room-online-icon"
                                color="success"
                                fontSize="small"
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className="create-chat-room-online-icon"
                                color="error"
                                fontSize="small"
                              />
                            )}
                          </div>
                          {item?.name}
                        </div>
                        {item?.isCreator?.toString() === "true" && (
                          <img
                            src={star}
                            style={{ height: 20, width: 20, marginRight: 8 }}
                          />
                        )}
                        {item?.isPermanent?.toString() === "true" ? null : (
                          <button
                            onClick={() => removeExternalMember(item)}
                            className="create-chat-room-select-members-content"
                          >
                            <DeleteIcon color="error" fontSize="medium" />
                          </button>
                        )}
                      </div>
                      <Divider style={{ marginTop: 4 }} />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <button
          className="create-chat-room-create-button"
          onClick={() => onCreateClick()}
        >
          Create
        </button>
      </div>
    </div>
  ) : null;
};

export default CreateExternalChatRoom;
