import "App.css";
import "react-toastify/dist/ReactToastify.css";

import { Slide, ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AlertPopup from "Components/Alert/AlertPopup/AlertPopup";
import AppRoutes from "Routes/App.Routes";
import VerificationDialog from "Components/Alert/DialogBox/VerificationDialog";

import { firebaseConfig, messaging } from "Constants/FirebaseConfig";
import { getNotificationTabsTotalCount, getUserList } from "Config/API/get";
import { getRecentNotificationCountAction } from "Redux/action/get.action";
import { newChatAlert } from "Redux/action/alert.action";
import { requestForNotificationToken } from "Constants/PushNotificationRenderer";

import firebase from "firebase/compat/app";
import "firebase/firestore"; // Ensure Firestore is imported
import { onMessage } from "firebase/messaging";

// Prevent Firebase from re-initializing multiple times
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  const dispatch = useDispatch();
  const loggedInUserId = useSelector(
    (state) => state?.authenticationReducer?.id
  );

  // Retrieve token (fix issue where token disappears)
  const token = sessionStorage.getItem("token") || localStorage.getItem("token");
  console.log("Retrieved token:", token);

  useEffect(() => {
    if (loggedInUserId) {
      requestForNotificationToken(loggedInUserId);
    }
  }, [loggedInUserId]);

  const getNotificationTabsTotalCountHandler = async () => {
    try {
      const response = await getNotificationTabsTotalCount(token);
      if (response?.status) {
        dispatch(getRecentNotificationCountAction(response?.TotalUnreadNotificationCount));
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  // Ensure Push Notification Event Listener is added only once
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      toast(
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>{payload?.data?.title}</label>
          <label style={{ fontSize: "13px" }}>{payload?.data?.body}</label>
        </div>,
        { type: "info" }
      );
      getNotificationTabsTotalCountHandler();
    });

    return () => unsubscribe(); // ✅ Cleanup listener on unmount
  }, []);

  const [recentUserIds, setRecentUserIds] = useState([]);
  const [latestQuerySnap, setLatestQuerySnap] = useState(null);

  const user_data_list = useSelector(
    (state) => state?.renderRecentChatUserReducer
  );

  const messageRef = firebase
    .firestore()
    .collection("chatroom")
    .orderBy("createdAt", "desc");

  useEffect(() => {
    const unsubscribe = messageRef?.onSnapshot((querySnap) => {
      let updatedRecentUserIds = new Set([...recentUserIds]);

      querySnap?.docs?.forEach((docSnap) => {
        const data = docSnap.data();
        if (user_data_list?.length === 0) {
          for (let i in data?.chatID) {
            if (loggedInUserId?.toString() === data?.chatID[i]?.toString()) {
              updatedRecentUserIds?.add(data?.docID);
            }
          }
        }
      });

      const newRecentUserIds = Array.from(updatedRecentUserIds);

      if (JSON.stringify(newRecentUserIds) !== JSON.stringify(recentUserIds)) {
        setRecentUserIds(newRecentUserIds);
        console.log("Updated recentUserIds:", newRecentUserIds);
      }

      setLatestQuerySnap(querySnap);

      processUnreadMessages(querySnap, user_data_list);
    });

    return () => unsubscribe(); // ✅ Cleanup Firestore listener on unmount
  }, [loggedInUserId, dispatch, user_data_list]);

  useEffect(() => {
    const fetchUserList = async () => {
      if (user_data_list?.length === 0 && recentUserIds?.length > 0) {
        try {
          const res = await getUserList(token, recentUserIds);
          console.log("dispatching");

          if (res?.status) {
            dispatch({ type: "UPDATE_USER_LIST", payload: res?.data });

            if (latestQuerySnap) {
              processUnreadMessages(latestQuerySnap, res?.data);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserList();
  }, [recentUserIds, token, dispatch, latestQuerySnap]);

  const processUnreadMessages = (querySnap, updatedUserList) => {
    if (!querySnap) return;

    let unreadMessage = [];

    querySnap?.docs.forEach((docSnap) => {
      const data = docSnap.data();

      for (let userData of updatedUserList) {
        if (data?.docID === userData?.unique_id) {
          if (
            data?.chatID?.some(
              (id) => id?.toString() === loggedInUserId?.toString()
            )
          ) {
            data?.seen_data?.forEach((seen) => {
              if (
                Object.values(seen)?.toString() === "unseen" &&
                Object.keys(seen)?.toString() === loggedInUserId?.toString()
              ) {
                unreadMessage.push(1);
              }
            });
          }
          break;
        }
      }
    });

    dispatch(newChatAlert(unreadMessage.length));
  };

  return (
    <div className="App">
      <AppRoutes />
      <AlertPopup />
      <VerificationDialog />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        theme="colored"
        transition={Slide}
      />
    </div>
  );
}

export default App;
