import "./ChatConnection.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatUserCard from "Components/Cards/ChatUserCard/ChatUserCard";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "Constants/FirebaseConfig";
import { userListAction } from "Redux/action/variable.action";

const ChatConnection = () => {
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  const dispatch = useDispatch();

  // firebase integration
  firebase.initializeApp(firebaseConfig);

  let recent_user_ids = [];

  // getting recent chats from firebase
  useEffect(() => {
    const messageFunction = async () => {
      const messageRef = firebase
        .firestore()
        .collection("chatroom")
        .orderBy("createdAt", "desc");

      // this function returns the alternate user
      const alternateUser = (data) => {
        for (let i in data) {
          if (my_id !== data[i]) {
            recent_user_ids = [...recent_user_ids, data[i]?.toString()];
            console.log({data})
          }
        }
      };

      messageRef.onSnapshot((querySnap) => {
        querySnap.docs?.map((docSanp) => {
          const data = docSanp.data();
          // function here returns the chatID of our chats
          for (let i in data?.chatID) {
            if (my_id?.toString() === data?.chatID[i]?.toString()) {
              // checking condition for group or one-to-one chat
              // if (data?.type?.toString() === "group") {
                recent_user_ids = [...recent_user_ids, data?.docID];
              // } else {
              //   alternateUser(data?.chatID);
              // }
            }
          }
        });
        dispatch(userListAction(recent_user_ids));
        recent_user_ids = [];
      });
    };

    const messageFnReturn = messageFunction();
  }, []);

  return (
    <>
      <div className="chatconnection-container">
        <div className="chatconnection-upper">
          <h3>Recent Chats</h3>
        </div>
        <div className="chatconnection lower">
          <div className="chatconnection-wrapper">
            <ChatUserCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatConnection;
