import React, { useState, useRef, useEffect } from "react";
import "./AddAssetPart.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { useNavigate } from "react-router-dom";
import { createAssetPart, generateAssetPartId } from "Config/API/action";
import { partuniqueid } from "Redux/action/variable.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import blank from "Media/blank-profile-picture.png";
import { Button } from "@mui/material";
import {
  getAssetAllPart,
  getGeneralInfo,
  getLocationData,
  getOrganizationConnection,
  listOfAppointOrg,
  listOfServiceTicketUsers,
} from "Config/API/get";
const AddAssetPart = () => {
  const navigate = useNavigate();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [assetPart, setAssetPart] = useState("");
  const [assetPartdropdown, setAssetPartdropdown] = useState("");
  const [filteredassetpart, setFilteredassetpart] = useState("");
  const [vendorContactName, setVendorContactName] = useState("");
  const [description, setDescription] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [showVendorDropdown, setShowVendorDropdown] = useState(false);
  const [showPartDropdown, setShowPartDropdown] = useState(false);
  const [vendorPhone, setVendorPhone] = useState("");

  const uniqueid = useSelector((state) => state.assetIdReducer);
  const assethash = useSelector((state) => state.assetHashReducer);
  const [assetLocation, setAssetLocation] = useState([]);
  const [selectedVendorOrg, setSelectedVendorOrg] = useState([]);
  const [vendorContactList, setVendorContactList] = useState([]);
  const [vendorContactId, setVendorContactId] = useState([]);
  const [assetLocationType, setAssetLocationType] = useState("");
  const [vendorOrgList, setVendorOrgList] = useState([]);
  // const [selectedLocation, setSelectedLocation] = useState("");
  const [showLocation, setShowLocation] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState("");
  const [existingPart, setExistingPart] = useState(false);
  const [prefillState, setPrefillState] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const partRef = useRef();
  const vendorRef = useRef();
  const locationRef = useRef();
  const vendorWrapperRef = useRef();
  useOutsideAlerter(vendorRef);
  useOutsideAlerter(vendorWrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (locationRef.current && !locationRef.current.contains(event.target)) {
        setShowLocation(false);
      }
      if (partRef.current && !partRef.current.contains(event.target)) {
        setShowPartDropdown(false);
      }
      if (vendorRef.current && !vendorRef.current.contains(event.target)) {
        setVendorContactList(""); // Close vendor dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Handle location dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        vendorWrapperRef.current &&
        !vendorWrapperRef.current.contains(event.target)
      ) {
        setShowVendorDropdown(false); // Close vendor dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Handle vendor dropdown

  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res.location);

        console.log(res.location);
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };
  const [partUniqueId, setPartUniqueId] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Trim the values to avoid empty or whitespace-only submissions
    const trimmedAssetPart = assetPart.trim();
    const trimmedVendorContactName = vendorContactName.trim();
    const trimmedVendorEmail = vendorEmail.trim();
    const trimmedDescription = description.trim();
    const trimmedVendorName = vendorName.trim();
    const trimmedInventoryLocation = selectedLocations.map((loc) => ({
      inventoryLocation: loc.id,
      quantity: loc.quantity,
      locationName: loc.location,
    }));
    const trimmedVendorPhone = vendorPhone.trim();

    if (trimmedAssetPart !== "") {
      if (trimmedVendorName !== "") {
        if (trimmedDescription !== "") {
          if (trimmedInventoryLocation !== "") {
            if (trimmedVendorContactName !== "") {
              if (trimmedVendorEmail !== "") {
                if (
                  trimmedVendorPhone !== "" &&
                  trimmedVendorPhone.length >= 9
                ) {
                  setLoader(); // Show loading spinner or state

                  // Fetch token from local storage
                  const token = localStorage.getItem("token");

                  try {
                    // First, generate the assetPartId using the token
                    const assetPartData = {
                      token: token,
                      assetId: uniqueid,
                      partuniqueid: partUniqueId,
                      partHash: trimmedAssetPart,
                      description: trimmedDescription,

                      vendorName: trimmedVendorName,
                      vendorContact: trimmedVendorContactName,
                      vendorEmail: trimmedVendorEmail,
                      vendorPhone: trimmedVendorPhone,
                      assetLocationType: assetLocationType,
                      inventoryLocation: trimmedInventoryLocation,
                      type: "part",
                      parentUniqueId: "0",
                    };

                    // Now, create the asset part using the assetPartData
                    const assetPartResponse = await createAssetPart(
                      assetPartData
                    );

                    resetLoader(); // Hide loader once request is done

                    if (assetPartResponse?.status) {
                      toast(assetPartResponse?.message, {
                        type: "success",
                      });
                      setIsSubmitting(false);
                      dispatch(partuniqueid(assetPartResponse.data.unique_id));
                      // navigate("/part-detail");
                      navigate("/asset-details", {
                        state: { partdetail: true }, // Pass true as a boolean, not a string
                      });
                    } else {
                      setIsSubmitting(false);

                      toast(assetPartResponse?.message, {
                        type: "error",
                      });
                    }
                  } catch (error) {
                    setIsSubmitting(false);
                    resetLoader(); // Hide loader in case of error
                    console.error("Error during asset part creation:", error);
                    toast("An error occurred, please try again", {
                      type: "error",
                    });
                  }
                } else {
                  setIsSubmitting(false);
                  toast("Vendor phone number must be at least 9 digits", {
                    type: "info",
                  });
                }
              } else {
                setIsSubmitting(false);
                toast("Please enter vendor email ", {
                  type: "info",
                });
              }
            } else {
              setIsSubmitting(false);
              toast("Please enter a vendor name", { type: "info" });
            }
          } else {
            setIsSubmitting(false);
            toast("Please enter an inventory location", { type: "info" });
          }
        } else {
          setIsSubmitting(false);
          toast("Please enter a description", { type: "info" });
        }
      } else {
        setIsSubmitting(false);
        toast("Please select a vendor org", { type: "info" });
      }
    } else {
      setIsSubmitting(false);
      toast("Please enter an asset part", { type: "info" });
    }
  };
  const [selectedLocations, setSelectedLocations] = useState([]);
  const handleAddLocation = (loc) => {
    const exists = selectedLocations.find((item) => item.id === loc.id);
    if (!exists) {
      setSelectedLocations([
        ...selectedLocations,
        { id: loc.id, location: loc.location, quantity: 1 },
      ]);
    }
  };

  const handleprefillLocation = (locArray) => {
    if (locArray !== " ") {
      const newSelectedLocations = locArray.map((loc) => ({
        id: loc.inventoryLocation, // Set inventoryLocation as the id
        location: loc.locationName, // Set locationName as the location
        quantity: parseInt(loc.quantity), // Ensure quantity is an integer
      }));
      setPrefillState(true);
      setSelectedLocations(newSelectedLocations);
    } else {
      setPrefillState(false);
      setSelectedLocations([]);
    }
  };

  const handleRemoveLocation = (id) => {
    setSelectedLocations(selectedLocations.filter((item) => item.id !== id));
  };

  const handleQuantityChange = (id, change) => {
    setSelectedLocations((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, quantity: Math.max(1, item.quantity + change) }
          : item
      )
    );
  };
  useEffect(() => {
    getAssetParts();
  }, []);
  const getAssetParts = async () => {
    setLoader();
    try {
      const assetData = await getAssetAllPart(token);

      if (assetData && assetData.status) {
        setAssetPartdropdown(assetData.data.data);
        console.log(assetData.data.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  const [manualLocationInput, setManualLocationInput] = useState("");

  // Handle manual location addition
  const handleAddManualLocation = (location) => {
    const newLocation = {
      id: location, // Generate a unique ID
      location,
      quantity: 1, // Default quantity
    };
    setSelectedLocations([...selectedLocations, newLocation]);
  };
  useEffect(() => {
    getassetlocation();
    getloginorg();
  }, []);
  const [generalInfo, setGeneralInfo] = useState("");
  const getloginorg = async () => {
    const info = await getGeneralInfo(token);
    if (info?.status) {
      setGeneralInfo(info?.org_info);
    }
  };
  return (
    <>
      <div className="create-assets-parent">
        <div className="create-assets-header">
          <Headers />
        </div>
        <div className="create-assets-navigation">
          <Navigation />
        </div>
        <div className="create-assets-main create-parts">
          <div className="create-asset-heading">
            <h2>Create/Add New Part</h2>
          </div>
          <div className="create-assets-form">
            <form
              className="create-assets-form-body"
              onSubmit={handleSubmit} // Attach the submit handler here
              autoComplete="off"
            >
              <div className="create-asset-fields">
                <label htmlFor="Asset-Part">
                  {" "}
                  Asset #<span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Part"
                  required
                  value={assethash} // Bind to state
                  readOnly
                  style={{
                    color: "#446FA2",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="assetPart">
                  Enter Part # <span>*</span>:
                </label>

                <div ref={partRef} className="vendor-wrapper addpart-wrapper">
                  <div className="input-wrapper">
                    <input
                      type="text"
                      name="assetPart"
                      className="input-box"
                      required
                      maxLength={30}
                      placeholder=" Enter Part #"
                      value={assetPart}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAssetPart(value); // Update the asset part value

                        // Reset other fields when changing the part
                        setDescription("");
                        setVendorName("");
                        setVendorContactName("");
                        setVendorEmail("");
                        setVendorPhone("");
                        setExistingPart("false");
                        handleprefillLocation(" ");
                        // Filter parts only if the input has 3 or more characters
                        if (value.length >= 3) {
                          const filteredParts = assetPartdropdown.filter(
                            (part) =>
                              part.partHash
                                .toLowerCase()
                                .includes(value.toLowerCase()) // Case-insensitive search
                          );
                          setFilteredassetpart(filteredParts); // Update filtered parts
                          setShowPartDropdown(true);
                        } else {
                          setFilteredassetpart();
                          setShowPartDropdown(false);
                        }
                      }}
                      onClick={() => {
                        // Show dropdown immediately when input is clicked
                        // setFilteredassetpart([...assetPartdropdown]);
                        // setShowPartDropdown(true);
                      }}
                    />
                  </div>

                  {/* Dropdown for displaying parts */}
                  {showPartDropdown && filteredassetpart.length > 0 && (
                    <div className="dropdown-list">
                      {filteredassetpart.map((part) => (
                        <div
                          className="dropdown-item"
                          key={part.unique_id}
                          onClick={() => {
                            setAssetPart(part.partHash);
                            setShowPartDropdown(false);
                            setDescription(part.description);
                            setVendorName(part?.vendorName);
                            setVendorContactName(part?.vendorContact);
                            setVendorEmail(part?.vendorEmail);
                            setVendorPhone(part?.vendorPhone);
                            setPartUniqueId(part?.unique_id);
                            setExistingPart("true");
                            handleprefillLocation(part?.inventoryLocation);
                          }}
                        >
                          <span
                            className="dropdown-text"
                            style={{ color: "#446fa2" }}
                          >
                            {part.partHash || "No Part"}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="create-asset-fields">
                <label htmlFor="Asset-Description">
                  Enter Description <span>*</span>:
                </label>
                <textarea
                  id="Asset-Description"
                  rows={4}
                  required
                  maxLength={250}
                  value={description} // Bind to state
                  onChange={(e) => setDescription(e.target.value)} // Update state
                  placeholder="Enter Description "
                  readOnly={existingPart === "true"}
                ></textarea>
              </div>

              <div className="create-asset-fields">
                <label htmlFor="assetLocation">Enter Inventory Location:</label>

                <div ref={locationRef} className="vendor-wrapper">
                  <div className="input-wrapper">
                    {/* Render selected locations as chips with quantity controls */}
                    {selectedLocations.map((loc) => (
                      <div className="location-chip-with-quantity" key={loc.id}>
                        <div className="location-chip">
                          <button
                            type="button"
                            className="chip-remove-btn"
                            onClick={() => handleRemoveLocation(loc.id)}
                            disabled={prefillState}
                          >
                            ✖
                          </button>
                          <span
                            className="chip-text"
                            style={{ color: "#446fa2" }}
                          >
                            {loc.location}
                          </span>
                        </div>

                        {/* Quantity controls for each selected location */}
                        <div className="quantity-controls">
                          <button
                            type="button"
                            className="quantity-btn"
                            onClick={() => handleQuantityChange(loc.id, -1)}
                            disabled={loc.quantity === 1 || prefillState}
                          >
                            -
                          </button>
                          <span
                            className="quantity-value"
                            style={{ color: "#446fa2" }}
                          >
                            {loc.quantity}
                          </span>
                          <button
                            type="button"
                            className="quantity-btn"
                            onClick={() => handleQuantityChange(loc.id, 1)}
                            disabled={prefillState}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    ))}

                    {/* Input for searching and adding new locations */}
                    <input
                      type="text"
                      name="assetLocation"
                      className="input-box"
                      placeholder="Search or Add Location"
                      value={manualLocationInput}
                      maxLength={30}
                      disabled={prefillState}
                      onChange={(e) => {
                        const value = e.target.value;
                        const inputValue = value.toLowerCase().trim();

                        setManualLocationInput(value);

                        if (value.length > 0) {
                          getassetlocation(); // Fetch asset locations (if necessary)

                          const filteredLocations = assetLocation.filter(
                            (loc) =>
                              loc.location.toLowerCase().includes(inputValue)
                          );
                          setFilteredLocations(filteredLocations);
                          setShowLocation(true); // Show dropdown

                          // Automatically select if input matches an existing location
                          const exactMatch = filteredLocations.find(
                            (loc) => loc.location.toLowerCase() === inputValue
                          );
                          if (exactMatch) {
                            handleAddLocation(exactMatch); // Add location directly
                            setManualLocationInput(""); // Clear the input
                            setShowLocation(false); // Hide dropdown
                          }
                        } else {
                          setShowLocation(false); // Hide dropdown if input is cleared
                          setFilteredLocations([]); // Optionally clear the filtered locations
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && manualLocationInput.trim()) {
                          e.preventDefault(); // Prevent form submission or other default behavior
                          handleAddManualLocation(manualLocationInput);
                          setManualLocationInput(""); // Clear input after adding location
                          setShowLocation(false); // Hide dropdown after adding
                        }
                      }}
                      onClick={() => {
                        // Optional: Trigger the location fetch on input click if needed
                      }}
                    />
                  </div>

                  {showLocation && (
                    <div className="dropdown-list">
                      {filteredLocations &&
                        filteredLocations.length > 0 &&
                        filteredLocations.map((loc) => (
                          <div
                            className="dropdown-item"
                            key={loc.id}
                            onClick={() => {
                              handleAddLocation(loc);
                              setShowLocation(false);
                              setManualLocationInput("");
                            }}
                          >
                            <span
                              className="dropdown-text"
                              style={{ color: "#446fa2" }}
                            >
                              {loc.location || "No Location"}
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Vendor-Name">Vendor Org :</label>
                <div ref={vendorWrapperRef} className="vendor-wrapper">
                  <input
                    type="search"
                    id="Vendor-Name"
                    name="Vendor-Name"
                    value={vendorName}
                    maxLength={30}
                    placeholder="Search Vendor Org"
                    style={{
                      backgroundColor:
                        vendorName.length > 0 ? "#f1f2f6" : "white",
                    }}
                    disabled={existingPart === "true"}
                    onClick={() => {
                      if (vendorName.length > 0) return;

                      let vendors = generalInfo ? [generalInfo] : []; // Start with `generalInfo`

                      // Fetch organizations using getOrganizationConnection
                      getOrganizationConnection(token, "").then((res) => {
                        if (res?.status) {
                          let filteredVendors = res.organizationData || [];

                          // Ensure `generalInfo` is included at the top and not duplicated
                          if (
                            generalInfo &&
                            !filteredVendors.some(
                              (org) => org.id === generalInfo.id
                            )
                          ) {
                            filteredVendors = [generalInfo, ...filteredVendors];
                          }

                          setVendorOrgList(filteredVendors);
                          setShowVendorDropdown(true); // Always show dropdown on click
                        } else {
                          toast(res?.message, { type: "error" });
                          setShowVendorDropdown(true); // Show even if API fails
                          setVendorOrgList(vendors); // Ensure `generalInfo` is shown by default
                        }
                      });
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setVendorName(value.trimStart());
                      setSelectedVendorOrg([]);

                      let vendors = generalInfo ? [generalInfo] : []; // Start with `generalInfo`

                      if (value.length > 0) {
                        getOrganizationConnection(token, value).then((res) => {
                          if (res?.status) {
                            let filteredVendors = res.organizationData.filter(
                              (org) =>
                                org.organization_name
                                  .toLowerCase()
                                  .includes(value.toLowerCase())
                            );

                            // Ensure `generalInfo` is included and not duplicated
                            if (
                              generalInfo &&
                              generalInfo.organization_name
                                .toLowerCase()
                                .includes(value.toLowerCase()) &&
                              !filteredVendors.some(
                                (org) => org.id === generalInfo.id
                              )
                            ) {
                              filteredVendors = [
                                generalInfo,
                                ...filteredVendors,
                              ];
                            }

                            setVendorOrgList(filteredVendors);
                            setShowVendorDropdown(true);
                          } else {
                            toast(res?.message, { type: "error" });
                            setShowVendorDropdown(true); // Keep dropdown open
                            setVendorOrgList(vendors);
                          }
                        });
                      } else {
                        setShowVendorDropdown(true); // Keep dropdown open when input is empty
                        setVendorOrgList(vendors);
                      }
                    }}
                  />

                  {showVendorDropdown && vendorOrgList.length > 0 && (
                    <div className="vendor-organization-list">
                      {vendorOrgList.map((vendor, index) => (
                        <div
                          className="data-list"
                          key={vendor.id || index}
                          onClick={() => {
                            setVendorName(vendor.organization_name);
                            setSelectedVendorOrg(vendor.id);
                            setVendorOrgList([]);
                            setShowVendorDropdown(false);
                          }}
                        >
                          <div>
                            <div className="organization-img-parent">
                             
                                <img
              src={ vendor.logo || blank} alt="Logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
                            </div>
                            <span
                              className="multiple-line-text"
                              style={{ color: "#446FA2" }}
                            >
                              {vendor.organization_name || "No Name"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="create-asset-fields">
                <label htmlFor="Vendor-Contact-Name">Vendor Name:</label>
                <div ref={vendorRef} className="vendor-wrapper">
                  <input
                    type="text"
                    id="Vendor-Contact-Name"
                    name="Vendor-Contact-Name"
                    value={vendorContactName}
                    maxLength={30}
                 autoComplete="new-email"
                    disabled={existingPart === "true"}
                    style={{
                      backgroundColor: selectedVendorOrg === "" && "#EFEFEF",
                    }}
                    onClick={() => {
                      if (selectedVendorOrg.length !== 0) {
                        const vendor_list = listOfServiceTicketUsers(
                          token,
                          selectedVendorOrg,
                          ""
                        );
                        vendor_list.then((res) => {
                          if (res?.status) {
                            setVendorContactList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    onChange={(e) => {
                      setVendorContactName(e.target.value);
                      setVendorEmail("");
                      setVendorPhone("");
                      if (selectedVendorOrg.length !== 0) {
                        const vendor_list = listOfServiceTicketUsers(
                          token,
                          selectedVendorOrg,
                          e.target.value
                        );
                        vendor_list.then((res) => {
                          if (res?.status) {
                            setVendorContactList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 8) {
                        setVendorContactId("");
                      }
                    }}
                    required
                    placeholder="Part Vendor Contact Name"
                  />

                  {/****************  Vendor Contact List ***************/}
                  <div
                    className="vendor-organization-list"
                    style={{
                      display: vendorContactList.length > 0 ? "block" : "none",
                    }}
                  >
                    {vendorContactList.length > 0 && !vendorContactList.message
                      ? vendorContactList?.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <div>
                              
                                       <img
              src={ curr?.image || blank} alt="Logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
                                <span style={{ color: "#446FA2" }}>
                                  {curr?.name || "No Name"}
                                </span>
                              </div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  setVendorContactId(curr?.id);
                                  setVendorContactList([]); // Clear the list after selection
                                  setVendorContactName(curr?.name);
                                  setVendorEmail(curr?.email);
                                  setVendorPhone(curr?.phone);
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-asset-fields">
  <label htmlFor="Vendor-Email">Vendor Email :</label>
  <input
    type="email"
    id="Vendor-Email"
    maxLength={40}
    required
    autoComplete="new-email" // Prevent autofill
    readOnly={existingPart === "true"}
    value={vendorEmail}
    onChange={(e) => setVendorEmail(e.target.value)}
    placeholder="Enter Vendor Email"
  />
</div>

<div className="create-asset-fields">
  <label htmlFor="Vendor-Phone">Vendor Phone:</label>
  <input
    type="text"
    id="Vendor-Phone"
    autoComplete="new-phone" // Prevent autofill
    required
    readOnly={existingPart === "true"}
    value={vendorPhone}
    onChange={(e) => {
      const inputValue = e.target.value;
      if (/^\d{0,14}$/.test(inputValue)) {
        setVendorPhone(inputValue);
      }
    }}
    placeholder="Enter Vendor Phone Number"
  />
</div>


              <div className="submitbutton">
                <button
                  type="submit"
                  onSubmit={handleSubmit}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default AddAssetPart;
