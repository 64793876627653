import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const domainIdentifier = window.location.href
export const firebaseConfig = domainIdentifier.includes('https://www.amniusconnect.com')
  ? {
      apiKey: "AIzaSyBJuhuOEeqicxUsbZigOF-E1_a2B_kmLPU",
      authDomain: "amnius-connect-7e2d0.firebaseapp.com",
      projectId: "amnius-connect-7e2d0",
      storageBucket: "amnius-connect-7e2d0.firebasestorage.app",
      messagingSenderId: "814648259772",
      appId: "1:814648259772:web:7ae0d9f32668287c7eccb9",
    }
  : {
    apiKey: "AIzaSyC7qraHWi7A4b_mgvYteLVDI6F_5soWF7M",
    authDomain: "amnius-81329.firebaseapp.com",
    projectId: "amnius-81329",
    storageBucket: "amnius-81329.appspot.com",
    messagingSenderId: "884794453858",
    appId: "1:884794453858:web:22db7f22825068a7b6863b",
  };



export const initializeFirebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(initializeFirebaseApp);
