import "./WorkBookEditing.css";

import {
  addWorkBookFiles,
  changeWorkbookFileSequence,
  deleteWorkBookFile,
} from "Config/API/update";
import {
  getOrganizationConnection,
  getPortfolioData,
  getSearchedPortfolioFolder,
  getWorkBookFiles,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddWebLink from "Components/Popup/AddWebLink/AddWebLink";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { CompareTwoArray } from "Constants/Functions";
import DeleteIcon from "@mui/icons-material/Delete";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import PurchaseSubscription from "Components/Popup/PurchaseSubscription/PurchaseSubscription";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import SearchIcon from "@mui/icons-material/Search";
import SendRequestpopup from "Components/Popup/SendRequestPopup/SendRequestPopup";
import blank from "Media/blank-profile-picture.png";
import cadIcon from "Media/icons/cad.png";
import excelDocument from "Media/icons/excelDocument.png";
import folderOpen from "Media/icons/folder@2x.png";
import imageIcon from "Media/icons/simple-image.png";
import moment from "moment-timezone";
import pdfDocument from "Media/icons/pdfDocument.png";
import portfolioFiles from "Media/icons/portfolioFiles.png";
import pptDocument from "Media/icons/pptx.png";
import { purchaseSubscription } from "Config/API/action";
import { purchaseSubscriptionMessages } from "Constants/Values";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import useIsMyOrganization from "Hooks/useIsMyOrganization";
import useMediaQuery from "@mui/material/useMediaQuery";
import videoFile from "Media/icons/videoFile.png";
import webLink from "Media/icons/webLink.svg";
import webLinkImage from "Media/icons/webLinkImage.png";
import wordDocument from "Media/icons/wordDocument.png";

export default function WorkBookEditing() {
  let doneTypingInterval = 1500;
  let typingTimer;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const isOrgAmnius = useIsAmniusOrganization();
  const isMyOrganization = useIsMyOrganization();
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [searchedValue, setSearchedValue] = useState("");
  const [showAddWebLink, setShowAddWebLink] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchasePopup, setShowPurchasePopup] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState([]);
  const [connectedOrganisationData, setConnectedOrganisationData] = useState(
    []
  );
  const [bookMarkData, setBookMarkData] = useState([]);
  const [orgPortfolioData, setOrgPortfolioData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(general_info?.id);
  const [generalFolderOpened, setGeneralFolderOpened] = useState(true);
  const [initialBookmark, setInitialBookmark] = useState("Internal");
  const [pageLoading, setPageLoading] = useState(false);
  const [portfolioLoading, setPortfolioLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("fileSelection");
  const loadingArray = ["", "", "", ""];
  const pageLoadingArray = ["", "", ""];
  const [workbookData, setWorkbookData] = useState([]);
  const [workbookOriginalData, setWorkbookOriginalData] = useState([]);
  const [workbookDataUniqueIds, setWorkbookDataUniqueIds] = useState([]);
  const matches = useMediaQuery("(max-width:900px)");
  const isMediumView = useMediaQuery("(max-width:925px)");
  const portfolioRef = useRef(null);
  const [showsendRequestpopup, setShowSendRequestpopup] = useState(false);
  const container = document.getElementById("label-container");
  container?.addEventListener("wheel", function (e) {
    if (e.deltaY > 0) {
      container.scrollLeft += 100;
      e.preventDefault();
      // prevenDefault() will help avoid worrisome
      // inclusion of vertical scroll
    } else {
      container.scrollLeft -= 100;
      e.preventDefault();
    }
  });

  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );

  // calling api of organization list and workbook file
  useEffect(() => {
    getOrgPortfolioData(isOrgAmnius ? "-1" : general_info?.id, 0);
    getConnectedOrganizationList();
    getWorkBookFilesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrgPortfolioData = (orgId, parentUniqueId) => {
    if (!portfolioLoading) setPortfolioLoading(true);
    const organizationDataResponse = getPortfolioData(
      token,
      "portfolio",
      parentUniqueId,
      orgId
    );
    organizationDataResponse.then((res) => {
      setPortfolioLoading(false);
      if (res.data.status) {
        setBookMarkData(res.data.bookmarkData.splice(1));
        res?.data?.data?.map((item) => {
          item["checked"] = false;
          return item;
        });
        setOrgPortfolioData(res?.data?.data);
        switch (res.data.message) {
          case "You can not access workbook of other organization":
            toast(res.data.message, {
              type: "info",
            });
            break;
          default:
            // Do Nothing
            break;
        }
      } else
        toast(res.data.message, {
          type: "error",
        });
    });
  };

  const getConnectedOrganizationList = () => {
    if (!pageLoading) setPageLoading(true);
    const organization_list = getOrganizationConnection(token);
    organization_list.then((res) => {
      setPageLoading(false);
      if (res?.status) {
        setConnectedOrganisationData(res?.organizationData);
      }
    });
  };

  const getWorkBookFilesData = () => {
    const response = getWorkBookFiles(token, state?.data?.unique_id);
    response.then((res) => {
      setPortfolioLoading(false);
      resetLoader();
      if (res?.data?.status) {
        res?.data?.data?.map((item) => {
          item["checked"] = false;
          return item;
        });
        setWorkbookData(res?.data?.data);
        setWorkbookOriginalData(res?.data?.data);
        setWorkbookDataUniqueIds(
          res?.data?.data?.map((item) => {
            return item?.unique_id;
          })
        );
      } else toast(res?.data?.message, { type: "error" });
    });
  };

  const addFilesToWorkBook = () => {
    setPortfolioLoading(true);
    let array = [...orgPortfolioData];
    const response = addWorkBookFiles(
      token,
      selectedOrgId,
      state?.data?.unique_id,
      selectedItem
    );
    response.then((res) => {
      if (res?.status) {
        toast(res?.message, { type: "success" });
        getWorkBookFilesData();
        array.map((item) => {
          item["checked"] = false;
          return item;
        });
        setOrgPortfolioData(array);
        setSelectedItem([]);
      } else {
        setPortfolioLoading(false);
        toast(res?.message, { type: "error" });
      }
    });
  };

  const deleteWorkbookFile = (item) => {
    setShowConfirmPopup(false);
    setLoader();
    const response = deleteWorkBookFile(token, selectedItem);
    response.then((res) => {
      if (res?.status) {
        toast(res?.message, { type: "success" });
        getWorkBookFilesData();
        setSelectedItem([]);
      } else {
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleSaveClick = () => {
    setLoader();
    const newSequence = workbookData?.map((item) => {
      return item?.unique_id;
    });
    const response = changeWorkbookFileSequence(
      token,
      newSequence,
      state?.data?.unique_id
    );
    response.then((res) => {
      if (res?.status) {
        setShowSaveButton(false);
        toast(res?.message, { type: "success" });
        getWorkBookFilesData();
        setSelectedItem([]);
      } else {
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  const subscribeOrganization = () => {
    const response = purchaseSubscription(
      token,
      "portfolio",
      selectedFolder?.rate,
      selectedOrgId
    );
    response.then((res) => {
      setPurchaseLoading(false);
      if (res.status) {
        if (purchaseSubscriptionMessages?.includes(res?.message?.toLowerCase()))
          return toast(res.message, {
            type: "info",
          });
        else {
          setShowPurchasePopup(false);
          toast(res.message, {
            type: "success",
          });
          if (
            initialBookmark === "All Portfolios" ||
            initialBookmark === "Searched Portfolios"
          )
            searchData(searchedValue);
          else getOrgPortfolioData(selectedOrgId, 0);
        }
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const renderItemImage = (item) => {
    if (item?.type === "folder") {
      return <img src={portfolioFiles} alt="files" />;
    }
    if (item?.type === "webLink")
      return (
        <img className="portfolio-file-image" src={webLinkImage} alt="files" />
      );
    const fileType = item?.key?.split(".").pop()?.toLowerCase();
    switch (fileType) {
      case "jpeg":
      case "jpg":
      case "png":
        return (
          <img
            src={imageIcon}
            style={{ borderRadius: "4px" }}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
      case "doc":
      case "docx":
        return (
          <img
            src={wordDocument}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
      case "xls":
      case "xlsx":
      case "csv":
        return (
          <img
            src={excelDocument}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
      case "pdf":
        return (
          <img
            src={pdfDocument}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
      case "ppt":
      case "pptx":
        return (
          <img
            src={pptDocument}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
      case "mp4":
      case "mkv":
      case "webm":
        return (
          <img
            src={videoFile}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
      case "stp":
      case "step":
      case "dxf":
      case "stl":
        return (
          <img src={cadIcon} className="portfolio-file-image" alt="files" />
        );
      default:
        return (
          <img
            src={unknownFile}
            className="edit-workbook-file-image"
            alt="files"
          />
        );
    }
  };

  const renderItemName = (item) => {
    return item?.name.substr(0, 16);
  };

  const renderTableImage = (item) => {
    if (item?.fileType === "webLink") return webLinkImage;
    const fileType = item?.fileKey?.split(".").pop()?.toLowerCase();
    switch (fileType) {
      case "jpeg":
      case "jpg":
      case "png":
        return imageIcon;
      case "doc":
      case "docx":
        return wordDocument;
      case "xls":
      case "xlsx":
      case "csv":
        return excelDocument;
      case "pdf":
        return pdfDocument;
      case "ppt":
      case "pptx":
        return pptDocument;
      case "mp4":
      case "mkv":
      case "webm":
        return videoFile;
      case "stp":
      case "step":
      case "dxf":
      case "stl":
        return cadIcon;
      default:
        return unknownFile;
    }
  };

  const handleItemClick = (e, item, index, array) => {
    // changing to Numberic value
    setSelectedOrgId(+item?.org_id);
    setSelectedFolder(item);
    if (
      !isMyOrganization(item?.org_id) &&
      item?.security === "locked" &&
      item?.subscriptionStatus === "false"
    ) {
      if (loggedInUserDetails?.portfolio_subscription !== "manager")
        return toast("You do not have rights to access this folder", {
          type: "info",
        });
      if (item?.requestStatus === "not-requested")
        return setShowSendRequestpopup(true);
      if (item?.requestStatus === "pending")
        return toast("Subscription request pending", { type: "info" });
      if (item?.rate?.length === 0)
        return toast("Content not purchasable yet", { type: "info" });
      else return setShowPurchasePopup(true);
    }
    if (item?.type === "folder") {
      if (
        initialBookmark === "All Portfolios" ||
        initialBookmark === "Searched Portfolios"
      ) {
        const section = document.getElementById(`orgId-${item?.org_id}`);
        if (section)
          section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      return getOrgPortfolioData(item?.org_id, item?.unique_id);
    } else if (array.length > 0) return handleCheckBoxChange(e, index, array);
  };

  const handleCheckBoxChange = (e, index, array) => {
    array.map((item, idx) => {
      if (idx === index) item.checked = !item.checked;
      return item;
    });
    let item = [...selectedItem];
    if (e.currentTarget.checked) {
      item.push(array[index]?.unique_id);
      setSelectedItem(item);
    } else {
      const dataIndex = selectedItem.indexOf(array[index]?.unique_id);
      item.splice(dataIndex, 1);
      setSelectedItem(item);
    }
  };

  const handleMoveUp = () => {
    let array = [...workbookData];
    const allId = workbookData.map((item) => {
      return item?.unique_id;
    });
    const index = allId.indexOf(selectedItem[0]);
    if (index === 0) return toast("Already at Top", { type: "info" });
    const data = workbookData[index];
    array.splice(index, 1);
    array.splice(index - 1, 0, data);
    setWorkbookData(array);
    const allIds = array.map((item) => {
      return item?.unique_id;
    });
    if (CompareTwoArray(workbookDataUniqueIds, allIds))
      setShowSaveButton(false);
    else setShowSaveButton(true);
  };

  const handleMoveDown = () => {
    let array = [...workbookData];
    const allId = workbookData.map((item) => {
      return item?.unique_id;
    });
    const index = allId.indexOf(selectedItem[0]);
    if (index === allId.length - 1)
      return toast("Already at Bottom", { type: "info" });
    const data = workbookData[index];
    array.splice(index, 1);
    array.splice(index + 1, 0, data);
    setWorkbookData(array);
    const allIds = array.map((item) => {
      return item?.unique_id;
    });
    if (CompareTwoArray(workbookDataUniqueIds, allIds))
      setShowSaveButton(false);
    else setShowSaveButton(true);
  };

  const validateMovingRows = (handleMove) => {
    if (selectedItem.length > 1)
      return toast("Cannot move multiple rows at a time", { type: "info" });
    else handleMove();
  };

  const handelChangeTab = (value, arr) => {
    setSelectedTab(value);
    setSelectedItem([]);
    setShowSaveButton(false);
    arr.map((item) => {
      item["checked"] = false;
      return item;
    });
    if (value === "fileSelection") setWorkbookData([...workbookOriginalData]);
  };

  const scrollTo = (ref) => {
    ref?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const searchData = (value) => {
    const noValue = value.trimStart().length === 0;
    setPortfolioLoading(true);
    setSearchedValue(value);
    const response = getSearchedPortfolioFolder(token, value);
    response.then((res) => {
      setPortfolioLoading(false);
      if (res?.data?.status) {
        setBookMarkData([]);
        scrollTo(portfolioRef.current);
        setInitialBookmark(noValue ? "All Portfolios" : "Searched Portfolios");
        setOrgPortfolioData(res?.data?.data);
        if (noValue) setSelectedOrgId("");
      } else {
        toast(res?.data?.message, { type: "error" });
      }
    });
  };

  return (
    <>
      <div className="edit-workbook-parent">
        <div className="edit-workbook-main-header">
          <Headers />
        </div>

        <div className="edit-workbook-navigation">
          <Navigation />
        </div>

        <div className="edit-workbook-main">
          <div className="edit-workbook-wrapper">
            <div className="edit-workbook-header">
              <span>
                You are editing :{" "}
                <button
                  onClick={() => navigate(-1)}
                  className="edit-workbook-header-name-button"
                >
                  {state?.data?.name}
                </button>
              </span>
              <div className="edit-workbook-header-actions">
                {selectedTab === "fileSelection" && (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#446fa2" }}
                    onClick={() => {
                      if (pageLoading || portfolioLoading) return;
                      setShowAddWebLink(!showAddWebLink);
                    }}
                  >
                    <img
                      src={webLink}
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "5px",
                      }}
                      alt="addWebLink"
                    />
                    Add Weblink
                  </Button>
                )}
                <Button
                  style={{
                    background:
                      selectedTab === "fileSelection"
                        ? "rgb(92, 161, 39)"
                        : "#446fa2",
                    width: `${matches ? "fit-content" : "289px"}`,
                  }}
                  onClick={() => handelChangeTab("fileSelection", workbookData)}
                  variant="contained"
                >
                  File Selection
                </Button>
                <Button
                  onClick={() =>
                    handelChangeTab("workBookEditing", orgPortfolioData)
                  }
                  variant="contained"
                  style={{
                    background:
                      selectedTab === "workBookEditing"
                        ? "rgb(92, 161, 39)"
                        : "#446fa2",
                  }}
                >
                  Workbook Editing
                </Button>
                {selectedTab === "fileSelection" && (
                  <Button
                    onClick={() => {
                      if (pageLoading || portfolioLoading) return;
                      addFilesToWorkBook();
                    }}
                    variant="contained"
                    disabled={selectedItem.length === 0}
                  >
                    + Add File
                  </Button>
                )}
              </div>
            </div>
            {selectedTab === "fileSelection" && (
              <>
                <div className="edit-workbook-content-left">
                  <div className="edit-workbook-search-filter">
                    <input
                      type="search"
                      placeholder="Search folders"
                      onChange={(e) => {
                        clearTimeout(typingTimer);
                        if (e.target.value || e.target.value === "") {
                          typingTimer = setTimeout(() => {
                            searchData(e.target.value);
                          }, doneTypingInterval);
                        }
                      }}
                    />
                    <SearchIcon style={{ cursor: "pointer" }} />
                  </div>
                  <div className="edit-workbook-divider-line" />
                  <div style={{ padding: "10px 4px" }}>
                    <div
                      className={`edit-workbook-org ${
                        selectedOrgId === general_info?.id && "selected"
                      }`}
                    >
                    
                      <img
                                src={
                                  general_info?.logo ||blank
                                }
                              className="edit-workbook-org-image"
                        alt="logo"
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent infinite loop
                                  e.target.src = blank; // Fallback to demo image
                                }}
                              />
                      <div className="edit-workbook-org-title">
                        <span>{general_info?.organization_name}</span>
                        <div className="edit-workbook-org-label">
                          <img
                            src={
                              generalFolderOpened ? folderOpen : portfolioFiles
                            }
                            alt="portfolioFiles"
                          />
                          <button
                            onClick={() => {
                              getOrgPortfolioData(
                                isOrgAmnius ? "-1" : general_info?.id,
                                0
                              );
                              setSelectedOrgId(
                                isOrgAmnius ? "-1" : general_info?.id
                              );
                              setGeneralFolderOpened(true);
                              setInitialBookmark("Internal");
                              isMediumView && scrollTo(portfolioRef.current);
                            }}
                          >
                            Internal
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="edit-workbook-divider-line" />
                  <div className="edit-workbook-org-folder-listings">
                    {!pageLoading ? (
                      connectedOrganisationData?.length > 0 ? (
                        connectedOrganisationData?.map((data, index) => (
                          <div
                            className={`edit-workbook-org-folder ${
                              selectedOrgId === data?.id && "selected"
                            }`}
                            key={index}
                            id={`orgId-${data?.id}`}
                          >
                            <img src={portfolioFiles} alt="portfolioFiles" />
                            <span
                              onClick={() => {
                                getOrgPortfolioData(data?.id, 0);
                                setSelectedOrgId(data?.id);
                                setGeneralFolderOpened(false);
                                setInitialBookmark(data?.organization_name);
                                isMediumView && scrollTo(portfolioRef.current);
                              }}
                            >
                              {data?.organization_name}
                            </span>
                            <button
                              className="edit-workbook-org-visit-btn"
                              onClick={() => {
                                navigate("/organization", {
                                  state: {
                                    data:
                                      data?.super_org_id === "-1"
                                        ? "-1"
                                        : data?.id,
                                  },
                                });
                                dispatch(renderOrganizationInfo({}));
                              }}
                            >
                              
                              <img
                                src={
                                  data?.logo ||blank
                                }
                                alt="fileLogo"
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent infinite loop
                                  e.target.src = blank; // Fallback to demo image
                                }}
                              />
                              visit page
                            </button>
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", fontSize: "18px" }}>
                          No Data Found
                        </div>
                      )
                    ) : (
                      pageLoadingArray?.map((item) => {
                        return (
                          <div
                            className="portfolio-content-item"
                            style={{ borderWidth: 0, marginBottom: "18px" }}
                          >
                            <div>
                              <div className="loading-content-image animation" />
                              <span
                                className="loading-span animation"
                                style={{ marginLeft: "10px" }}
                              ></span>
                            </div>
                            <div className="loading-content-visit-button animation" />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <div ref={portfolioRef} className="edit-workbook-content-right">
                  <h2>Portfolio Explorer</h2>
                  <div
                    className="edit-workbook-internal-folder-path"
                    id="label-container"
                  >
                    {!portfolioLoading ? (
                      <>
                        <button
                          onClick={() => {
                            if (bookMarkData?.length === 0) return;
                            if (
                              initialBookmark === "All Portfolios" ||
                              initialBookmark === "Searched Portfolios"
                            )
                              return searchData(searchedValue);
                            getOrgPortfolioData(selectedOrgId, 0);
                          }}
                        >
                          {initialBookmark}
                        </button>

                        {bookMarkData?.length > 0 &&
                          bookMarkData.map((item, index) => (
                            <>
                              {" > "}
                              <button
                                key={index}
                                onClick={() =>
                                  getOrgPortfolioData(
                                    selectedOrgId,
                                    item?.unique_id
                                  )
                                }
                              >
                                {renderItemName(item)}
                              </button>
                            </>
                          ))}
                      </>
                    ) : (
                      <div
                        className="animation"
                        style={{
                          height: "20px",
                          width: "50%",
                          maxWidth: "250px",
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="edit-workbook-folder-grid">
                    {!portfolioLoading
                      ? orgPortfolioData?.length > 0
                        ? orgPortfolioData?.map((item, index) => (
                            <button
                              key={index}
                              onClick={(e) =>
                                handleItemClick(e, item, index, [])
                              }
                              className={`${
                                item?.checked === true &&
                                "edit-workbook-checked"
                              } ${
                                !isMyOrganization(item?.org_id) &&
                                item?.security === "locked" &&
                                item?.subscriptionStatus === "false" &&
                                "disabled"
                              }`}
                            >
                              {item.type !== "folder" && (
                                <input
                                  type="checkbox"
                                  readOnly
                                  onChange={(e) =>
                                    handleItemClick(
                                      e,
                                      item,
                                      index,
                                      orgPortfolioData
                                    )
                                  }
                                  checked={item?.checked}
                                />
                              )}
                              {renderItemImage(item)}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                {renderItemName(item)}
                                {(initialBookmark === "All Portfolios" ||
                                  initialBookmark === "Searched Portfolios") &&
                                  bookMarkData.length === 0 && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "#707070",
                                      }}
                                    >
                                      {item?.organization_name}
                                    </span>
                                  )}
                              </div>
                            </button>
                          ))
                        : initialBookmark.length > 0 && (
                            <div className="edit-workbook-no-data">
                              No Data Found
                            </div>
                          )
                      : loadingArray?.map((item) => {
                          return (
                            <div
                              className="portfolio-content-item"
                              style={{ borderColor: "#ececec" }}
                            >
                              <div>
                                <div className="loading-content-image animation" />
                                <span
                                  className="loading-span animation"
                                  style={{ marginLeft: "10px" }}
                                ></span>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </>
            )}
          </div>
          {selectedTab === "workBookEditing" && (
            <>
              <div className="edit-workbook-table-wrapper">
                <div className="edit-workbook-table-all-active">
                  <div className="edit-workbook-table-top">
                    <p>{state?.data.name}</p>
                    {selectedItem.length > 0 && (
                      <div className="edit-workbook-table-top-action">
                        {showSaveButton && (
                          <Button
                            className="edit-workbook-table-top-action-button save"
                            onClick={() => handleSaveClick()}
                            variant="contained"
                            style={{ background: "rgb(92, 161, 39)" }}
                          >
                            Save
                          </Button>
                        )}
                        <button
                          className="edit-workbook-table-top-action-button"
                          onClick={() => validateMovingRows(handleMoveUp)}
                        >
                          <ArrowUpwardIcon style={{ fontSize: "14px" }} />
                          Move Up
                        </button>
                        <button
                          className="edit-workbook-table-top-action-button"
                          onClick={() => validateMovingRows(handleMoveDown)}
                        >
                          <ArrowDownwardIcon style={{ fontSize: "14px" }} />
                          Move Down
                        </button>
                        <button onClick={() => setShowConfirmPopup(true)}>
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="edit-workbook-table-row-wrapper">
                    <div className="edit-workbook-table-head-row">SELECT</div>
                    <div className="edit-workbook-table-head-row">
                      PULLED FROM
                    </div>
                    <div className="edit-workbook-table-head-row">
                      FILE NAME
                    </div>
                    <div className="edit-workbook-table-head-row">ADDED BY</div>
                    <div className="edit-workbook-table-head-row">
                      DATE OPENED
                    </div>
                  </div>
                  <div className="edit-workbook-table-bottom">
                    {workbookData?.length > 0 ? (
                      workbookData?.map((item, index) => {
                        return (
                          <div
                            className="edit-workbook-table-content-wrapper"
                            key={index}
                          >
                            <div className="edit-workbook-table-content-row">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckBoxChange(e, index, workbookData)
                                }
                                checked={item?.checked}
                              />
                            </div>
                            <div
                              className="edit-workbook-table-content-row"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <img
                                src={
                                  item?.pulledFromLogo||blank
                                }
                                alt="fileLogo"
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent infinite loop
                                  e.target.src = blank; // Fallback to demo image
                                }}
                              />
                          
                              {item?.pulledFrom}
                            </div>
                            <div
                              className="edit-workbook-table-content-row"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <img
                                src={renderTableImage(item)}
                                alt="fileLogo"
                                style={{ borderRadius: 0 }}
                              />
                              {item?.fileName}
                            </div>
                            <div className="edit-workbook-table-content-row">
                              {item?.addedBy}
                            </div>
                            <div className="edit-workbook-table-content-row">
                              {moment(item?.createdAt).format("MM/DD/YYYY")}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="edit-workbook-table-msg">
                        No Data Found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showAddWebLink && (
        <AddWebLink
          hide={setShowAddWebLink}
          classify="workbook"
          getPortfolio={getWorkBookFilesData}
          parent_unique_id={state?.data?.unique_id}
          folderKey={state?.data?.key}
        />
      )}
      {showPurchasePopup && (
        <PurchaseSubscription
          header="Unlock Content"
          description={`Subscribe to get access to all our locked content for ${selectedFolder?.rate} credits/month.`}
          hide={setShowPurchasePopup}
          subscribeService={subscribeOrganization}
          setLoading={setPurchaseLoading}
          loading={purchaseLoading}
        />
      )}
      {showConfirmPopup && (
        <RenderConfirm
          message={`delete ${
            selectedItem.length > 1 ? "these files" : "this file"
          } ?`}
          data=""
          status={deleteWorkbookFile}
          hide={setShowConfirmPopup}
        />
      )}
      {showsendRequestpopup && (
        <SendRequestpopup
          selectedOrgId={selectedOrgId}
          hide={setShowSendRequestpopup}
          getOrgPortfolioData={getOrgPortfolioData}
          token={token}
          setLoader={setLoader}
          resetLoader={resetLoader}
          initialBookmark={initialBookmark}
          searchData={searchData}
          searchedValue={searchedValue}
        />
      )}
      {loader}
    </>
  );
}
