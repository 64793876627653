import React, { useEffect, useState } from "react";

import MentionDisplayCard from "../MentionCard/MentionDisplayCard";
import blank from "Media/blank-profile-picture.png";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { getUserList } from "Config/API/get";

const NestedCardList = ({ data,key, chatUserDetails, customSendBy }) => {
  const token = localStorage.getItem("token");
  const myId = useSelector((state) => state.authenticationReducer?.id);
  const { sentBy, message, imageData, createdAt, username } = data;

  // chat date
  let date = moment(createdAt).tz("America/Chicago").format();
  let timeConverted = moment(date).format("hh:mm A");

  // chat user data
  const my_image = useSelector((state) => state.authenticationReducer?.image);


  // selecting other person data
const [otherimage,setOtherImage]=useState("")
  if (chatUserDetails) {
    // for (let i in chatUserDetails?.data) {
      // if (sentBy?.toString() === chatUserDetails?.data[i]?.id?.toString()) {
    
        const user_list_status = getUserList(token, [sentBy.toString()]);
        user_list_status
        .then((res) => {
          if (res?.status) {
            console.log(res?.data[0].image)
            
            setOtherImage(res?.data[0].image)
          }
        })
      // }
    // }
  }

  // sender
  if (customSendBy?.toString() === myId?.toString()) {
    return (
      <div className="right-side-chat">
        <div className="chat-avatar">
          {sentBy?.toString() === myId?.toString() ? (
            
            <img
            src={my_image ||blank}
            alt="Avatar" 
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
            
          ) : (
           <img
            src={otherimage}
            alt="Avatar" 
            className="other-image"
            // onError={(e) => {
            //   e.target.onerror = null; // Prevent infinite loop
            //   e.target.src = blank; // Fallback to demo image
            // }}
          />
          )}
        </div>

        {/* videolink, message and media conditions */}
        {message ? (
          <>
            <li>
              <b>{username ? username : "Unknown"}:</b>
              <MentionDisplayCard message={message} />
              <span className="timeconverted">
                {timeConverted ? timeConverted : null}
              </span>
            </li>
          </>
        ) : imageData ? (
          <li className="chat-media">
            <img src={imageData} alt="Media" />
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : null}
      </div>
    );
  }

  //receiver
  if (customSendBy?.toString() !== myId?.toString()) {
    return (
      <div className="left-side-chat">
        <div className="chat-avatar">
          {sentBy?.toString() !== myId?.toString() ? (
         <img
         src={otherimage ||blank}
         alt="Avatar" 
         onError={(e) => {
           e.target.onerror = null; // Prevent infinite loop
           e.target.src = blank; // Fallback to demo image
         }}
       />
          ) : (
            <img
            src={my_image ||blank}
            alt="Avatar" 
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = blank; // Fallback to demo image
            }}
          />
          )}
        </div>

        {/* message and media conditions */}
        {message ? (
          <li>
            <b>{username ? username : "Unknown"}:</b>
            <MentionDisplayCard message={message} />
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : imageData ? (
          <li className="chat-media">
            <img src={imageData} alt="Media" />
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : null}
      </div>
    );
  }

  return <></>;
};

export default NestedCardList;
