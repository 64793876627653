import "./WorkBookFiles.css";

import { completeWorkbookFile, reportAdmin } from "Config/API/update";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import FileViewerComponent from "Components/FileViewer/PureComponents/FileViewer";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import WorkBookFileAgreementPopup from "Components/Popup/WorkBookFileAgreementPopup/WorkBookFileAgreementPopup";
import blank from "Media/blank-profile-picture.png";
import excelDocument from "Media/icons/excelDocument.png";
import { getWorkBookFiles } from "Config/API/get";
import imageIcon from "Media/icons/simple-image.png";
import { isMobile } from "react-device-detect";
import pdfDocument from "Media/icons/pdfDocument.png";
import pptDocument from "Media/icons/pptx.png";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import useIsMyOrganization from "Hooks/useIsMyOrganization";
import { useSelector } from "react-redux";
import videoFile from "Media/icons/videoFile.png";
import webLinkImage from "Media/icons/webLinkImage.png";
import wordDocument from "Media/icons/wordDocument.png";
import zoomIn from "Media/icons/zoomIn.png";
import zoomOut from "Media/icons/zoomOut.png";

const WorkBookFiles = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const isMyOrganization = useIsMyOrganization();
  const [parentZoom, setParentZoom] = useState(1);
  const [parentZoomTranslate, setParentZoomTranslate] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lazyLoading, setlazyLoading] = useState(false);
  const [workbookFileIndex, setWorkbookFileIndex] = useState();
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileType, setSelectedFileType] = useState("");
  const [workbookFiles, setWorkbookFiles] = useState([]);
  const loggedInUserId = useSelector(
    (state) => state.authenticationReducer?.id
  );
  const inCompletedFilesNumber = workbookFiles?.filter(
    (item) => item?.isCompleted === false
  )?.length;
  const zoomFunctionalityFiles = ["pdf", "png", "jpg", "jpeg", "doc", "docx"];

  useEffect(() => {
    getWorkBookData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedFile(workbookFiles[workbookFileIndex]);
    setSelectedFileType(
      workbookFiles[workbookFileIndex]?.fileKey?.split(".").pop()?.toLowerCase()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workbookFileIndex]);

  const getWorkBookData = () => {
    setLoading(true);
    const response = getWorkBookFiles(token, state?.unique_id);
    response.then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        const array = res?.data?.data?.map((item) => {
          if (item?.isCompleted === "true") {
            item["isCompleted"] = true;
            item["disable"] = true;
          } else {
            item["isCompleted"] = false;
            item["disable"] = false;
          }
          return item;
        });
        let index = array?.findIndex((item) => {
          return item?.isCompleted === false;
        });
        index = index === -1 ? array.length - 1 : index;
        setWorkbookFiles(array);
        setWorkbookFileIndex(index);
      } else toast(res?.data?.message, { type: "error" });
    });
  };

  const fileCompletionHandler = (e) => {
    const array = [...workbookFiles];
    array[workbookFileIndex].isCompleted = e.target.checked;
    setWorkbookFiles(array);
  };

  const handleNextClick = (parent_unique_id, unique_id, from) => {
    if (from === "iAgree" && inCompletedFilesNumber > 0)
      return toast("One or More files are incomplete", { type: "info" });
    setlazyLoading(true);
    const isWorkbookCompleted = from === "iAgree" ? "true" : "false";
    const response = completeWorkbookFile(
      token,
      unique_id,
      parent_unique_id,
      loggedInUserId,
      "completed",
      isWorkbookCompleted
    );
    response.then((res) => {
      setlazyLoading(false);
      if (res?.status) {
        if (from === "finish") setShowPopup(true);
        else if (from === "iAgree") {
          navigate(-1);
          setShowPopup(false);
        } else {
          setWorkbookFileIndex((prev) => prev + 1);
          setParentZoom(1);
          setParentZoomTranslate(0);
        }
        const array = [...workbookFiles];
        array.map((item) => {
          if (unique_id === item?.unique_id) item["disable"] = true;
          return item;
        });
        setWorkbookFiles(array);
      } else toast(res?.message, { type: "error" });
    });
  };

  const renderFileImage = () => {
    if (selectedFile?.fileType === "webLink") return webLinkImage;
    switch (selectedFileType) {
      case "jpeg":
      case "jpg":
      case "png":
        return imageIcon;
      case "doc":
      case "docx":
        return wordDocument;
      case "xls":
      case "xlsx":
      case "csv":
        return excelDocument;
      case "pdf":
        return pdfDocument;
      case "ppt":
      case "pptx":
        return pptDocument;
      case "mp4":
      case "mkv":
      case "webm":
        return videoFile;
      default:
        return unknownFile;
    }
  };

  const showFile = () => {
    if (
      isMyOrganization(selectedFile?.conn_id) ||
      selectedFile?.subscriptionStatus === "true" ||
      selectedFile?.security !== "locked"
    )
      return true;
    else return false;
  };

  const reportToAdmin = () => {
    setlazyLoading(true);
    const response = reportAdmin(token, selectedFile?.pulledFrom);
    response.then((res) => {
      setlazyLoading(false);
      if (res?.status) {
        toast(res?.message, { type: "success" });
      } else toast("Failed! Try again later", { type: "error" });
    });
  };

  return (
    <>
      <div className="workbook-files-parent">
        <div className="workbook-files-main-header">
          <Headers />
        </div>

        <div className="workbook-files-navigation">
          <Navigation />
        </div>

        <div className="workbook-files-wrapper">
          <div className="workbook-files-content">
            <div className="workbook-files-content-name">
              <button onClick={() => navigate(-1)}>
                {state?.workbookName}
              </button>
            </div>
            <div className="workbook-files-slide">
              <div className="workbook-files-slide-header">
                <div className="workbook-files-slide-header-left">
                  {!loading && !lazyLoading ? (
                    <>
                      <label>
                        File {workbookFileIndex + 1}/{workbookFiles?.length}:
                      </label>
                      <button>
                        <img
                          src={selectedFile?.pulledFromLogo || blank}
                          alt="orgLogo"
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop
                            e.target.src = blank; // Fallback to demo image
                          }}
                        />
                        
                        <span>{selectedFile?.pulledFrom}</span>
                      </button>
                      <button>
                        <img src={renderFileImage()} alt="fileImage" />
                        <span>{selectedFile?.fileName}</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <label
                        className="animation"
                        style={{ height: "21px", width: "55px" }}
                      />
                      <button
                        className="animation"
                        style={{
                          height: "27px",
                          width: "140px",
                          borderWidth: 0,
                        }}
                      />
                      <button
                        className="animation"
                        style={{
                          height: "27px",
                          width: "140px",
                          borderWidth: 0,
                        }}
                      />
                    </>
                  )}
                </div>
                {!isMobile &&
                  zoomFunctionalityFiles.includes(selectedFileType) && (
                    <div className="workbook-files-slide-header-middle">
                      {!loading && !lazyLoading ? (
                        <>
                          <button
                            onClick={() => {
                              setParentZoom((prevZoom) => prevZoom + 0.2);
                              setParentZoomTranslate((prev) => prev + 5);
                            }}
                          >
                            <img src={zoomIn} alt="zoomIn" />
                          </button>
                          <button
                            onClick={() => {
                              setParentZoom((prevZoom) => prevZoom - 0.2);
                              setParentZoomTranslate((prev) => prev - 5);
                            }}
                            disabled={parentZoom < 1.2}
                          >
                            <img src={zoomOut} alt="zoomOut" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="animation" />
                          <button className="animation" />
                        </>
                      )}
                    </div>
                  )}
                {selectedFile?.fileType === "webLink" &&
                  (!loading && !lazyLoading ? (
                    <a
                      href={selectedFile?.filePath}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Open
                    </a>
                  ) : (
                    <button
                      className="animation"
                      style={{ width: "62px", height: "27px" }}
                    />
                  ))}
                <div className="workbook-files-slide-header-right">
                  {!loading && !lazyLoading ? (
                    <>
                      <label htmlFor="file-checked">
                        I have Completed This File
                        <input
                          checked={selectedFile?.isCompleted}
                          disabled={selectedFile?.disable || !showFile()}
                          onChange={(e) => fileCompletionHandler(e)}
                          type="checkbox"
                          id="file-checked"
                        />
                      </label>
                      <div>
                        <button
                          onClick={() => {
                            setWorkbookFileIndex((prev) => prev - 1);
                            setParentZoom(1);
                            setParentZoomTranslate(0);
                          }}
                          disabled={workbookFileIndex === 0}
                        >
                          Prev
                        </button>
                        {workbookFileIndex < workbookFiles?.length - 1 ? (
                          <button
                            onClick={() => {
                              if (selectedFile?.disable || !showFile()) {
                                setParentZoom(1);
                                setParentZoomTranslate(0);
                                return setWorkbookFileIndex((prev) => prev + 1);
                              }
                              handleNextClick(
                                selectedFile?.parent_unique_id,
                                selectedFile?.unique_id,
                                ""
                              );
                            }}
                            disabled={!selectedFile?.isCompleted && showFile()}
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              if (selectedFile?.disable)
                                return setShowPopup(true);
                              handleNextClick(
                                selectedFile?.parent_unique_id,
                                selectedFile?.unique_id,
                                "finish"
                              );
                            }}
                            disabled={!selectedFile?.isCompleted}
                          >
                            Finish
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <label
                        className="animation"
                        style={{
                          height: "23px",
                          width: "228px",
                          borderWidth: 0,
                        }}
                      />
                      <button
                        className="animation"
                        style={{ height: "31px", width: "70px" }}
                      />
                      <button
                        className="animation"
                        style={{ height: "31px", width: "70px" }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div
                className="workbook-files-slide-body"
                style={{
                  overflowX:
                    isMobile &&
                    selectedFileType?.toLowerCase() === "pdf" &&
                    "hidden",
                }}
              >
                {!loading ? (
                  <>
                    {showFile() ? (
                      <div
                        style={{
                          height: "100%",
                          width: "100",
                          transform: `scale(${parentZoom}) translate(${parentZoomTranslate}%, ${parentZoomTranslate}%)`,
                        }}
                      >
                        <FileViewerComponent
                          disableHeader={true}
                          path={selectedFile?.filePath}
                          type={selectedFileType}
                          folderType={selectedFile?.fileType}
                        />
                      </div>
                    ) : (
                      <div className="workbook-files-slide-report-section">
                        <label>
                          This is a locked content. Your subscription of{" "}
                          {selectedFile?.pulledFrom} has Expired. Please report
                          it to your Admin.
                        </label>
                        {!lazyLoading ? (
                          <button onClick={() => reportToAdmin()}>
                            Report
                          </button>
                        ) : (
                          <button
                            className="animation"
                            style={{ height: "37px", width: "90px" }}
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div style={{ width: "100%" }} className="animation"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <WorkBookFileAgreementPopup
          hide={setShowPopup}
          data={{
            label: "Great Job!",
            description:
              "You agree that you have read and completed all files presented before you in this workbook.",
          }}
          onAgreeCallback={() =>
            handleNextClick(
              selectedFile?.parent_unique_id,
              selectedFile?.unique_id,
              "iAgree"
            )
          }
        />
      )}
    </>
  );
};

export default WorkBookFiles;
