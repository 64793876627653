import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import React from "react";
import demo from "Media/blank-profile-picture.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { useNavigate } from "react-router-dom";

const CurrentOrganizationVisitButton = () => {
  let internalFolder = useSelector((state) => state.internalFolderDataReducer);
  const currentOrg = useSelector((state) => state.currentVisitOrgReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      {currentOrg?.location && internalFolder === "" ? (
        <div className="visit-icon">
          <img
            src={currentOrg?.profiledetail?.logo || demo}
            alt="Logo"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = demo; // Fallback to demo image
            }}
          />

          
          <Button
            variant="text"
            className="visit-page-cta"
            onClick={() => {
              navigate("/organization", {
                state: { data: currentOrg?.conn_id },
              });
              dispatch(renderOrganizationInfo({}));
            }}
          >
            VISIT PAGE
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default CurrentOrganizationVisitButton;
